const LABLES = {
    Login: 'Login',
    Email: 'Email',
    Name: 'Name',
    NewPassword: 'New Password',
    ConfirmPassword: 'Confirm password',
    PlantName: 'Plant Name',
    Designation: 'Designation',
    ForgotPassword: 'Forgot Password',
    RequestAccess: 'Request Access',
    CurrentPassword: 'Current Password',
    Dashboard: 'Dashboard',
    MyTask: 'My Task',
    DraftAudits: 'Draft Audits',
    InProgressAudits: 'In Progress Audits',
    InProgressAudit: 'In Progress Audit',
    CompletedAudits: 'Completed Audits',
    Apply: 'Apply',
    Facility: 'Facility (Plant)',
    CreateAudit: 'Create Audit',
    AuditBeginDate: 'Audit Begin Date',
    PlantDirector: 'Plant Director',
    AuditEndDate: 'Audit End Date',
    AuditName: 'Audit Name',
    AuditManagers: 'Audit Manager',
    LastAuditedDate: 'Last Audited Date',
    LastAuditUpdated: 'Last Audit Updated',
    /* audit details table heads */
    Filters: 'Filters',
    FilterByAM: 'Filter By AM',
    FilterByStatus: 'Filter By Status',
    FilterByPlant: 'Filter By Plant',
    FilterByDate: 'Filter By Date',
    Password: 'Password',
    Plant: 'Plant',
    Auditor: 'Auditor',
    Reviewer: 'Reviewer',
    AR_Reviewer: 'AR Reviewer',
    AssetType: 'Asset Type',
    Line: 'Line',
    Asset: 'Asset',
    Type: 'Type',
    Frequency: 'Frequency',
    PMScript: 'PM Script',
    StartDate: 'Start Date',
    WOStartDate: 'Work orders From Date',
    EndDate: 'End Date',
    WOEndDate: 'Work orders To Date',
    EstimateDate: 'Estimated End Date',
    Status: 'Status',
    Action: 'Action',
    PROGRESS: 'Progress',
    /* end audit details table heads */
    AddTask: 'Add Task',
    AddAuditArea: 'Add Audit Area',
    DeleteAudit: 'Delete Audit',
    GenerateReport: 'Generate Report',
    SaveasDraft: 'Save as Draft',
    Submit: 'Submit',
    ViewAudit: 'View Audit',
    ViewTask: 'View Task',
    DownloadTask: 'Download Task',
    Reassign: 'Reassign',
    CompletedTask: 'Completed',
    ChangePassword: 'Change Password',
    LogOut: 'Log Out',
    SortBy: 'Sort By',
    AuditType: 'Audit Type',
    Area: 'Area',
    Activity: 'Activity',
    Static_Utility: 'Asset General Activity',
    DESIREDCOMPLETION: 'Desired Completion %',
    Description: 'Description',
    Validation: 'Validation',
    Image: 'Image',
    Observation: 'Observation',
    Impact: 'Impact',
    Recommendation: 'Recommendation',
    Value: 'Value',
    Discrepancy: 'Discrepancy',
    ItemDiscrepancy: 'Item Number / Discrepancy',
    OrderNumber: 'Order Number',
    AssetNumber: 'Asset Number',
    AuditorsInputs: 'Auditor’s inputs',
    From: 'From',
    To: 'To',
    TaskBeginDate: 'Task Begin Date',
    TaskEndDate: 'Task End Date',
    DownloadFAQ: 'Download FAQ',
    QuestionNewPage: 'New Page (Scenarios):',
    QuestionEdit: 'Edit existing',
    QuestionAddAppend: 'Add / append',
    QuestionDelete: 'Delete',
    QuestionNotAccessed: 'NA for “Not Accessed”'
};

const BUTTONS = {
    Login: 'Login',
    Submit: 'Submit',
    OK: 'OK',
    Cancel: 'Cancel',
    Proceed: 'Proceed',
    Delete: 'Delete'
};

const STRINGS = {
    GoBackToLogin: 'Go back to login',
    ThankYou: 'Thank you',
    ProblemSigningIn: 'Problem Signing In?',
    FIELDREQUIRED: 'This field is required.',
    PleaseSelectRole: 'Please select your role.',
    SelectLine: 'Please select line',
    SelectAsset: 'Please select asset',
    SelectType: 'Please select type',
    CorrectEmail: 'Please enter correct email ID.',
    CorrectPassword: 'Please enter correct password.',
    CONFIRMATIONMESSAGE: `I affirm and certify that all the information and answers to questions herein are complete, true and correct to the best of my knowledge and belief.`,
    ReasonMessage: `Please provide a reason for assigning this task back to auditor.`
};

const REGEX = {
    EMAIL: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
};

const SELECT_DEFAULT_VALUE = {
    id: 'default',
    value: '',
    content: 'Please Select'
};
const TAB_TYPES = {
    Draft: 1,
    My_Task: 2,
    In_Progress: 3,
    Completed: 4
};
const AUDIT_STATUS = {
    IN_DRAFT: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2
};
const USER_ROLES = {
    'audit manager': 'audit manager',
    reviewer: 'reviewer',
    auditor: 'auditor'
};

const ASSETS_TYPES_LIST = {
    'Work Order Compliance': 1,
    'Parts Room  Inventory Compliance': 2,
    'Line Equipment and Injection': 3,
    Facilities: 4,
    Utilities: 5
};

export {
    SELECT_DEFAULT_VALUE,
    LABLES,
    BUTTONS,
    STRINGS,
    REGEX,
    TAB_TYPES,
    AUDIT_STATUS,
    USER_ROLES,
    ASSETS_TYPES_LIST
};
