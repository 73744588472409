/**
 * @author Rehan Rizvi
 * @file AddQues Modal
 * @flow
 */
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import AlertModal from './AlertModal';
import LANGUAGECONST from '../constants/language';

class AddQuesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quesObj: {
                op_seq: '',
                short_desc: '',
                long_desc: '',
                isQuant: '',
                min: '',
                max: ''
            }
        };
    }

    /**
     * @description This function set values in quesObj object according to variable names.
     */
    handleChange = (event) => {
        let { quesObj } = this.state;
        let { name, value } = event.target;
        this.setState(
            {
                quesObj: {
                    ...quesObj,
                    [name]: value
                }
            },
            () => {
                if (name === 'isQuant' && value === 'false') {
                    this.setState({
                        quesObj: {
                            ...quesObj,
                            min: '',
                            max: '',
                            [name]: value
                        }
                    });
                }
            }
        );
    };

    /**
     * @description This function restrict alphabets and special characters and only allows numbers upto three decimal places.
     */
    restrictToNumber = (event) => {
        let { value } = event.target;
        let numTest = /^-?\d*\.?\d{0,3}$/;
        if (numTest.test(value)) {
            this.handleChange(event);
        }
    };

    /**
     * @description This function submits data of manually added question in machine health.
     */
    handleProceed = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { quesObj } = this.state;
        if (quesObj['isQuant'] == 'true') {
            for (let key in quesObj) {
                if (!quesObj[key]) {
                    return AlertModal.showAlert(lang.STRINGS.ALLFIELDREQUIREDPROCEED);
                }
            }
            this.props.addQues(quesObj);
        } else if (
            !quesObj['isQuant'] ||
            !quesObj['short_desc'] ||
            !quesObj['long_desc'] ||
            !quesObj['op_seq']
        ) {
            return AlertModal.showAlert(lang.STRINGS.ALLFIELDREQUIREDPROCEED);
        } else {
            this.props.addQues(quesObj);
        }
    };

    render() {
        const { isModalOpen, onCancel } = this.props;
        let { quesObj } = this.state;
        let { op_seq, short_desc, long_desc, isQuant, min, max } = quesObj;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <div>
                <Modal open={isModalOpen} onClose={onCancel} center>
                    <div className="popup-cntnt popuptext">
                        <center>
                            <b>{lang.STRINGS.ADDQUESTIONMANDATORYFIELD}</b>
                        </center>
                        <br />
                        <div className="data-card-row">
                            <label>{lang.LABLES.OPSEQUENCE}</label>
                            <input
                                className="form-control"
                                type="number"
                                name="op_seq"
                                value={op_seq}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="data-card-row">
                            <label>{lang.LABLES.SHORTDESCRIPTION}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="short_desc"
                                value={short_desc}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="data-card-row">
                            <label>{lang.LABLES.LONGDESCRIPTION}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="long_desc"
                                value={long_desc}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="data-card-row radio-group">
                            <label>{lang.LABLES.DIMENSIONCHECK}</label>
                            <div className="data-upload-col">
                                <input
                                    id="isQuant"
                                    type="radio"
                                    name="isQuant"
                                    style={{ marginRight: '3px' }}
                                    value="true"
                                    checked={isQuant === 'true'}
                                    onChange={this.handleChange}
                                />
                                <span style={{ marginRight: '10px' }}>{lang.LABLES.TRUE}</span>
                                <input
                                    id="isQuant"
                                    type="radio"
                                    name="isQuant"
                                    style={{ marginRight: '3px' }}
                                    value="false"
                                    checked={isQuant === 'false'}
                                    onChange={this.handleChange}
                                />
                                {lang.LABLES.FALSE}
                            </div>
                        </div>
                        <div className="data-card-row">
                            <label>{lang.LABLES.MIN}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="min"
                                value={min}
                                onChange={this.restrictToNumber}
                                disabled={isQuant == 'false'}
                            />
                        </div>
                        <div className="data-card-row">
                            <label>{lang.LABLES.MAX}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="max"
                                value={max}
                                onChange={this.restrictToNumber}
                                disabled={isQuant == 'false'}
                            />
                        </div>
                    </div>
                    <div className="btn-container">
                        <button className="btn-prmry btn-rqst-accs" onClick={onCancel}>
                            {lang.BUTTONS.Cancel}
                        </button>
                        <button
                            className="btn-prmry btn-rqst-accs"
                            onClick={() => this.handleProceed()}>
                            {lang.BUTTONS.Proceed}
                        </button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default AddQuesModal;
