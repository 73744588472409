import { combineReducers } from 'redux';

import data from './reducer';
import common from './common_reducer';

/* to synchronize router state */
import { connectRouter } from 'connected-react-router';

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        data,
        common
    });

export default rootReducer;
