/**
 * @author Rehan Rizvi
 * @file ServerCall
 * @flow
 */
import Axios from 'axios';
import { spinnerService } from '../../shared/services/spinner.service';
import { logoutUnauthorizedUser } from '../utils/auth.util';
import AlertModal from '../../shared/modals/AlertModal';
import { getData } from '../utils/storage.util';
import CONFIG from '../../configuration';

/**
 * @description This function used to call the apis.
 * @param url Url of the calling api.
 * @param data Data that will be required to call the api
 * @param loderMsg Message which will be shown with loader.
 * @returns Either return the success response or show the error message.
 */
export const serverCalls = async (url, data, loaderMsg, obj, language = 'en') => {
    spinnerService.show('Oval', loaderMsg);
    try {
        const config = {
            headers: { authorization: `Bearer ${getData('token')}`, language: language }
        };
        let response = await Axios.post(url, data, config);
        spinnerService.hide('Oval');
        if (response && response.data && response.data.responseCode == 200) {
            if (url === CONFIG.deleteAudit) return response.data;
            return response.data.response;
        } else {
            if (response && response.data && response.data.responseCode == 401) {
                logoutUnauthorizedUser(obj, response.data.message);
            } else {
                AlertModal.showAlert(response.data.message);
                return null;
            }
        }
    } catch (error) {
        spinnerService.hide('Oval');
        AlertModal.showAlert(error.message);
        return null;
    }
};
