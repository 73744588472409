/**
 * @author Akshay Kumar Singh
 * @file IdleTimer Component
 * @flow
 */
import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';

class IdleTimerComponent extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.state = {
            timeout: 60000,
            remaining: null,
            isIdle: false,
            lastActive: null,
            elapsed: null
        };
    }

    /**
     * @description This function sets value to the state variable to false.
     * @returns Changes in state values i.e. isIdle
     */
    componentWillMount() {}
    _onActive = () => {
        this.setState({ isIdle: false });
    };

    /**
     * @description This function sets value to the state variable to true and call the timer to reset.
     * @returns Changes in state values i.e. isIdle
     */
    _onIdle = () => {
        this.setState({ isIdle: true });
        this.props.onSave();
        this._reset();
    };

    /**
     * @description This function sets value to the state variable on the basis of refrence value
     * @returns Changes in state values i.e. timeout
     */
    _changeTimeout = () => {
        this.setState({
            timeout: this.refs.timeoutInput.state.value()
        });
    };

    /**
     * @description This function used to reset the timer
     */
    _reset = () => {
        this.idleTimer.reset();
    };

    render() {
        return (
            <div>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    onActive={this._onActive}
                    onIdle={this._onIdle}
                    timeout={this.state.timeout}
                />
            </div>
        );
    }
}

export default IdleTimerComponent;
