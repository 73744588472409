const LANGUAGECONSTANT = {
    ENG: {
        LABLES: {
            Login: 'Login',
            Email: 'Email',
            Name: 'Name',
            NewPassword: 'New Password',
            ConfirmPassword: 'Confirm password',
            PlantName: 'Plant Name',
            Designation: 'Designation',
            ForgotPassword: 'Forgot Password',
            RequestAccess: 'Request Access',
            CurrentPassword: 'Current Password',
            Dashboard: 'Dashboard',
            MyTask: 'My Task',
            DraftAudits: 'Draft Audits',
            InProgressAudits: 'In Progress Audits',
            InProgressAudit: 'In Progress Audit',
            CompletedAudits: 'Completed Audits',
            Apply: 'Apply',
            Facility: 'Facility (Plant)',
            CreateAudit: 'Create Audit',
            AuditBeginDate: 'Audit Begin Date',
            PlantDirector: 'Plant Director',
            AuditEndDate: 'Audit End Date',
            AuditName: 'Audit Name',
            AuditManagers: 'Audit Manager',
            LastAuditedDate: 'Last Audited Date',
            LastAuditUpdated: 'Last Audit Updated',
            /* audit details table heads */
            Filters: 'Filters',
            FilterByAM: 'Filter By AM',
            FilterByStatus: 'Filter By Status',
            FilterByPlant: 'Filter By Plant',
            FilterByDate: 'Filter By Date',
            Password: 'Password',
            Plant: 'Plant',
            Auditor: 'Auditor',
            Reviewer: 'Reviewer',
            AR_Reviewer: 'AR Reviewer',
            AssetType: 'Asset Type',
            Line: 'Line',
            Asset: 'Asset',
            Type: 'Type',
            Frequency: 'Frequency',
            PMScript: 'PM Script',
            StartDate: 'Start Date',
            WOStartDate: 'Work orders From Date',
            EndDate: 'End Date',
            WOEndDate: 'Work orders To Date',
            EstimateDate: 'Estimated End Date',
            Status: 'Status',
            Action: 'Action',
            PROGRESS: 'Progress',
            /* end audit details table heads */
            AddTask: 'Add Task',
            AddAuditArea: 'Add Audit Area',
            DeleteAudit: 'Delete Audit',
            GenerateReport: 'Generate Report',
            GenerateIntermediateReport: 'Generate Intermediate Report',
            SaveasDraft: 'Save as Draft',
            Submit: 'Submit',
            ViewAudit: 'View Audit',
            ViewTask: 'View Task',
            DownloadTask: 'Download Task',
            Reassign: 'Reassign',
            CompletedTask: 'Completed',
            ChangePassword: 'Change Password',
            LogOut: 'Log Out',
            SortBy: 'Sort By',
            AuditType: 'Audit Type',
            Area: 'Area',
            Activity: 'Activity',
            Static_Utility: 'Asset General Activity',
            DESIREDCOMPLETION: 'Desired Completion %',
            Description: 'Description',
            Validation: 'Validation',
            Image: 'Image',
            Observation: 'Observation',
            Impact: 'Impact',
            Recommendation: 'Recommendation',
            Value: 'Value',
            Discrepancy: 'Discrepancy',
            ItemDiscrepancy: 'Item Number / Discrepancy',
            OrderNumber: 'Order Number',
            AssetNumber: 'Asset Number',
            AuditorsInputs: 'Auditor’s inputs',
            From: 'From',
            To: 'To',
            TaskBeginDate: 'Task Begin Date',
            TaskEndDate: 'Task End Date',
            DownloadFAQ: 'Download FAQ',
            Language: 'Language',
            Found: 'Found',
            WorkOrders: 'Work Orders',
            WorkOrderPM: 'Work Order-PM',
            WorkOrderRebuild: 'Work Order-Rebuild',
            WorkOrderRepair: 'Work Order-Repair',
            WorkOrderWarranty: 'Work Order-Warranty',
            UploadFile: 'Upload File',
            Validate: 'Validate',
            All: 'All',
            SelectDate: 'Select Date',
            UpdateTask: 'Update Task',
            NotApplicable: 'Not Applicable',
            NotInspected: 'Not Inspected',
            Pass: 'Pass',
            MajorNC: 'Major NC',
            MinorNC: 'Minor NC',
            UploadImage: 'Upload Image',
            Yes: 'Yes',
            No: 'No',
            SelectOption: 'Select Option',
            ReviewerComment: 'Reviewer Comment',
            TASKNO: 'Task No.',
            MIN: 'Min',
            MAX: 'Max',
            SCANITEM: 'Scan Item',
            ITEMNUMBER: 'Item Number',
            LOTNUMBER: 'Lot Number',
            LOCATOR: 'Locator',
            ITEMDESCRIPTION: 'Item Description',
            SEARCH: 'Search',
            AUDITED: 'Audited',
            PENDING: 'Pending',
            TOTAL: 'Total',
            ITEMNUMBERCORRECT: 'Item Number Correct',
            ITEMLOCATORCORRECT: 'Item Locator Correct',
            ITEMDESCRIPTIONCORRECT: 'Item Description Correct',
            BINLABELEDCORRECT: 'Bin Labeled Correct',
            ACTUALQUANTITY: 'Actual Quantity',
            ORACLEQUANTITY: 'Oracle Quantity',
            WONUMBER: 'Work Order Number',
            COMMENT: 'Comment',
            WORKORDERTYPE: 'Work Order Type',
            WODESCRIPTION: 'Work Order Description',
            SCHEDULEDSTARTDATE: 'Scheduled Start Date',
            FILTERWO: 'Filter Work Order',
            SHORTDESCRIPTION: 'Short Description',
            OPSEQUENCE: 'Op Sequence',
            DEPARTMENT: 'department',
            AREA: 'Area',
            LONGDESCRIPTION: 'Long Description',
            DIMENSIONCHECK: 'Dimension Check?',
            TRUE: 'True',
            FALSE: 'False',
            QUANTITYPARTS: 'Quantity Parts',
            COSTPARTS: 'Cost Parts',
            WODESCRIPTION: 'WO Description',
            SUBINVENTORY: 'Sub-inventory',
            ONHANDQUANTITY: 'On-hand Quantity',
            ORGANIZATION: 'Organization',
            UPLOADMEDIA: 'Upload Media',
            UPLOADVIDEO: 'Upload Video',
            SELECT: 'Select',
            SCENARIO: 'Scenario',
            CLOSEALERT: 'Please Select One Option',
            AuditCategory: 'Audit Category'
        },
        BUTTONS: {
            Login: 'Login',
            Submit: 'Submit',
            OK: 'OK',
            Cancel: 'Cancel',
            Proceed: 'Proceed',
            Delete: 'Delete',
            AssignBack: 'Assign Back',
            SavePreview: 'Save & Preview',
            SEARCH: 'Search',
            SCANWO: 'Scan WO',
            DOWNLOADEXCEL: 'Download Excel',
            DOWNLOAD_AUDIT_IMAGES: 'Download Images'
        },
        STRINGS: {
            GoBackToLogin: 'Go back to login',
            ThankYou: 'Thank you',
            ProblemSigningIn: 'Problem Signing In?',
            FIELDREQUIRED: 'This field is required.',
            PleaseSelectRole: 'Please select your role.',
            SelectLine: 'Please select line',
            SelectAsset: 'Please select asset',
            SelectType: 'Please select type',
            CorrectEmail: 'Please enter correct email ID.',
            CorrectPassword: 'Please enter correct password.',
            CONFIRMATIONMESSAGE: `I affirm and certify that all the information and answers to questions herein are complete, true and correct to the best of my knowledge and belief.`,
            ReasonMessage: `Please provide a reason for assigning this task back to auditor.`,
            DownloadingFAQ: 'Downloading FAQ',
            FileMissing: 'This file seems to be missing. Please try again later',
            FetchingData: 'Fetching Data',
            FetchingQuestion: 'Fetching Question',
            FetchUserData: 'Fetching Users Data',
            NoneMH: 'You can not select None as value while you deselect the MH checkbox',
            NoneGA: 'You can not select None as value while you selected the None in Asset General Activity',
            NoneActivity: 'You can not unselect the MH checkbox while you have None as an activity',
            FetchLine: 'Fetching Line',
            FetchAssets: 'Fetching Assets',
            MandatoryFields: 'Please fill all the mandatory fields.',
            CreatingTask: 'Creating Task',
            CreatingAudit: 'Creating Audit',
            UpdatingTask: 'Updating Task',
            AllFieldsRequired: 'All fields are required',
            ALLFIELDREQUIREDPROCEED: 'All fields are required to proceed.',
            FetchingEBSData: 'Fetching data from EBS',
            FileUploadRequired: 'File upload is required',
            DataRetrievedSuccess: 'Data retrieved successfully',
            RestructureDEBSData: 'Restructuring EBS data',
            SearchingWorkOrder: 'Searching Work Order',
            SDEDMandatory: 'Both start date and end date are mandatory.',
            PleaseUploadExcel: 'Please upload excel file only.',
            SelectARReviewer: 'Select AR Reviewer',
            PleaseSelectAuditType: 'Please select audit type',
            PleaseSelectArea: 'Please select area',
            PleaseSelectAGA: 'Please select Asset General Activity',
            PleaseSelectActivity: 'Please select activity',
            SelectMHQuestion: 'Select MH Question',
            SelectDVQuestion: 'Select DV Question',
            PleaseSelectPercentage: 'Please select compeletion percentage',
            FetchEBSData: 'Fetch EBS data',
            FetchingTasks: 'Fetching Tasks',
            UpdatingAudit: 'Updating Audit',
            DeletingTask: 'Deleting Task',
            TaskDeletedSuccess: 'Task deleted successfully.',
            FetchingTaskList: 'Fetching task list',
            FetchingAuditList: 'Fetching audit list',
            NoAuditsAvailable: 'No Audits Available.',
            NoTasksAvailable: 'No Tasks Available.',
            ConfirmAuditDelete: 'Are you sure you want to delete the audit?',
            ConfirmTaskDelete: 'Are you sure you want to delete the task?',
            SubmittingData: 'Submitting Data',
            DataSavedSuccess: 'Data Saved Successfully',
            AcceptDeclaration: 'Please accept declaration to proceed',
            UploadingImage: 'Uploading',
            UnsupportedFileType: 'This file type is not supported.',
            MaxImages: 'You can upload upto 4 images/videos',
            ClickToAnswer: 'Click To Answer',
            DeleteImage: 'Delete Image',
            SaveBeforeAssign: 'Please save before assigning task back to the auditor.',
            ConfirmReassign: 'Are you sure you want to assign this task back to auditor ?',
            AssigningBack: 'Assigning task back to auditor',

            AddMHAuditPoint: 'Add MH Audit Point',
            AddGeneralPoint: 'Add General Audit Point',
            AddPMPoint: 'Add PM Audit Point',

            AuditTasksQuestions: 'Audit Tasks / Questions',
            OBSERVATIONREQ: 'Please complete the Observation for',
            IMAGEREQ: 'Please upload the image for',
            QUESTIONNAIREREQ: 'Please fill out the Questionnaire for',
            IMPACTREQ: 'Please fill out the Impact for',
            RECOMMENDATIONREQ: 'Please fill out the Recommendation for',
            IMPACTRECOMMENDATIONREQ: 'Please fill out the Impact and recommendation for',
            VALUEREQ: 'Please fill out the value for',
            STARTENDDATEREQ: 'Please fill out the Start Date and End Date for',
            ITEMINLISTALREADY: 'You already have this item in the list',
            ITEMNUMBERSEARCH: 'Please enter the item number to search.',
            MANDATORYFIELDPR: 'is mandatory for item number',
            VALIDATIONREQ: 'Please fill out the Validation for',
            WORKORDERINLISTALREADY: 'You already have this work order in the list',
            WORKORDERSEARCH: 'Please enter work order number to search.',
            INSERTCARD: 'Insert Card',
            WOMADATORYFIELDS: 'Please provide inputs for the Work Order Number',
            SAVINGDATA: 'Saving Data',
            DELETINGIMAGE: 'Deleting Image/Video',
            ADDQUESTIONMANDATORYFIELD: 'Please provide details to add question.',
            SEARCHEDWO: 'Searched work order',
            ISNOTFOUND: 'is not found.',
            STILLWANTTOSUBMIT: 'If you still want to submit then,',
            PROVIDEREQUESTEDWODETAILS: 'Please provide details for the requested work order.',
            SELECTWOTYPE: 'Select WO type',
            ENTERHERE: 'Enter here',
            SEARCHITEM: 'Searched item',
            PROVIDEREQUESTEDITEMDETAILS: 'Please provide details for the requested item.',
            REASONMESSAGETOPROCEED: 'Please provide a reason before proceed',
            FETCHINGALLUSERS: 'Fetching All Users',
            ADDINGCARD: 'Adding Card',
            SEARCHNGPR: 'Searching Parts Room',
            GENERATINGREPORT: 'Generating Report',
            GENERATINGINTERMEDIATEREPORT: 'Generating Intermediate Report',
            VERFYINGTOGENERATEREPORT: 'Verifying audit to generate report',
            DOWNLOADINGTASK: 'Downloading Task',
            DOWNLOADINGREPORT: 'Downloading audit report',
            DOWNLOADINGWOEXCEL: 'Downloading WO excel',
            DOWNLOADINGPREXCEL: 'Downloading PR excel',
            DELETINGAUDIT: 'Deleting Audit',
            ADDINGQUESTION: 'Adding Question',
            DELETINGCARD: 'Deleting Card',
            FETCHINGPLANTDETAILS: 'Fetching plant details',
            CHANGINGPASSWORD: 'Changing Password',
            NEWOLDPASSWORDNOTMATCH: 'New password and confirm password fields do not match.',
            ENTERALLFIELDSCORRECTLY: 'Please enter all the fields correctly.',
            NODATAFOUND: 'No data found.',
            DOWNLOADING_IMAGES: 'Downloading audit images. This could take a while.',
            AuditIsDVType: 'You can only add DV tasks to a DV audit.',
            AuditIsNotDVType: 'You can not add DV tasks to a non DV audit.'
        }
    },

    ESP: {
        LABLES: {
            Login: 'Inicio de sesión',
            Email: 'Correo electrónico',
            Name: 'Nombre',
            NewPassword: 'Nueva contraseña',
            ConfirmPassword: 'confirmar Contraseña',
            PlantName: 'Nombre de planta',
            Designation: 'Designacion',
            ForgotPassword: 'contraseña olvidar',
            RequestAccess: 'Solicitar acceso',
            CurrentPassword: 'Contraseña actual',
            Dashboard: 'salpicadero',
            MyTask: 'Mi tarea',
            DraftAudits: 'Borradores de auditorías',
            InProgressAudits: 'Auditorías en curso',
            CompletedAudits: 'Auditorías completadas',
            Apply: 'Aplicar',
            Facility: 'Instalación (planta)',
            CreateAudit: 'Crear auditoría',
            AuditBeginDate: 'Fecha de inicio de la auditoría',
            PlantDirector: 'Directore planta',
            AuditEndDate: 'Fecha de finalización de la auditoría',
            AuditName: 'Nombre de la auditoría',
            AuditManagers: 'Gerente de Auditoría',
            LastAuditedDate: 'Fecha de la última auditoría',
            LastAuditUpdated: 'Última auditoría actualizada',
            /* audit details table heads */
            Filters: 'Filtros',
            FilterByAM: 'Filtrar por AM',
            FilterByStatus: 'Filtrar por estado',
            FilterByPlant: 'Filtrar por planta',
            FilterByDate: 'Filtrar por fecha',
            Password: 'Contraseña',
            Plant: 'Planta',
            Auditor: 'Auditora',
            Reviewer: 'Revisor',
            AR_Reviewer: 'Revisora ​​de AR',
            AssetType: 'Tipo los activos',
            Line: 'Línea',
            Asset: 'Activos',
            Type: 'Tipos',
            Frequency: 'Frecuencia',
            PMScript: 'PM Script',
            StartDate: 'Fecha de inicio',
            WOStartDate: 'Órdenes de trabajo desde la fecha',
            EndDate: 'Fecha final',
            WOEndDate: 'Órdenes de trabajo hasta la fecha',
            EstimateDate: 'Fecha estimada',
            Status: 'Estatus',
            Action: 'Acción',
            PROGRESS: 'Progreso',
            /* end audit details table heads */
            AddTask: 'Agregar tarea',
            AddAuditArea: 'Agregar área de auditoría',
            DeleteAudit: 'Eliminar auditoría',
            GenerateReport: 'Generar informe',
            SaveasDraft: 'Guardar como borrador',
            Submit: 'Someter',
            ViewAudit: 'Ver auditoría',
            ViewTask: 'Ver tarea',
            DowloadTask: 'Descargar tarea',
            Reassign: 'Reassignar',
            CompletedTask: 'Terminado',
            ChangePassword: 'Cambiar la contraseña',
            LogOut: 'Cerrar sesión',
            SortBy: 'Ordenar por',
            AuditType: 'Tipo de auditoria',
            Area: 'Área',
            Activity: 'Actividad',
            Static_Utility: 'Actividad general de activos',
            DESIREDCOMPLETION: '% De finalización deseado',
            Description: 'Descripción',
            Validation: 'Validación',
            Image: 'Imagen',
            Observation: 'Observación',
            Impact: 'Impacto',
            Recommendation: 'Recomendación',
            Value: 'Valor',
            Discrepancy: 'Discrepancia',
            ItemDiscrepancy: 'Número de artículo / discrepancia',
            OrderNumber: 'Número de orden',
            AssetNumber: 'Número de activo',
            AuditorsInputs: 'Aportes del auditor',
            From: 'Desde',
            To: 'A',
            TaskBeginDate: 'Fecha de inicio de la tarea',
            TaskEndDate: 'Fecha de finalización de la tarea',
            DownloadFAQ: 'Descargar FAQ',
            Language: 'El idioma',
            Found: 'Encontrada',
            WorkOrders: 'Órdenes de trabajo',
            WorkOrderPM: 'Orden de Trabajo-PM',
            WorkOrderRebuild: 'Orden de Trabajo-Reconstrucción',
            WorkOrderRepair: 'Orden de Trabajo-Reparación',
            WorkOrderWarranty: 'Orden de Trabajo-De Garantía',
            UploadFile: 'Subir Archivo',
            Validate: 'Validar',
            All: 'Lo todos',
            SelectDate: 'Sele Fecha',
            UpdateTask: 'Tarea de actualizar',
            NotApplicable: 'No Aplicable',
            NotInspected: 'No Inspeccionada',
            Pass: 'Pasr',
            MajorNC: 'Mayor NC',
            MinorNC: 'Menor NC',
            UploadImage: 'Cargar imagen',
            Yes: 'sí',
            No: 'No',
            SelectOption: 'Seleccionar Opción',
            ReviewerComment: 'Comentario del revisor',
            TASKNO: 'Número de Tarea',
            MIN: 'Mínima',
            MAX: 'Máxima',
            SCANITEM: 'Elemento Escaneo',
            ITEMNUMBER: 'Número Artículo',
            LOTNUMBER: 'Numero de Lote',
            LOCATOR: 'Locadora',
            ITEMDESCRIPTION: 'Descripcion Articulo',
            SEARCH: 'Cachear',
            AUDITED: 'Auditada',
            PENDING: 'Pendientes',
            TOTAL: 'Total',
            ITEMNUMBERCORRECT: 'Artículo Número Correcta',
            ITEMLOCATORCORRECT: 'Artículo Localizador Correcta',
            ITEMDESCRIPTIONCORRECT: 'Elemento Descripción Correcto',
            BINLABELEDCORRECT: 'Bin etiquetada correcta',
            ACTUALQUANTITY: 'Cantidad actual',
            ORACLEQUANTITY: 'Cantidad De Oracle',
            WONUMBER: 'Número de Orden de Trabajo',
            COMMENT: 'Comentario',
            WORKORDERTYPE: 'Tipo de Orden de Trabajo',
            WODESCRIPTION: 'Orden de Trabajo Descripción',
            SCHEDULEDSTARTDATE: 'Fecha de Inicio Programada',
            FILTERWO: 'Orden de Trabajo de Filtro',
            SHORTDESCRIPTION: 'Breve Descripción',
            LONGDESCRIPTION: 'Descripción larga',
            DIMENSIONCHECK: 'Comprobación de dimensiones?',
            TRUE: 'Cierta',
            FALSE: 'Falsa',
            QUANTITYPARTS: 'Cantidad Partes',
            COSTPARTS: 'Costo Partes',
            WODESCRIPTION: 'WO Descripción',
            SUBINVENTORY: 'Subinventario',
            ONHANDQUANTITY: 'En-mano Cantidad ',
            ORGANIZATION: 'Organización',
            UPLOADMEDIA: 'Subir Medios',
            UPLOADVIDEO: 'Subir Video',
            SELECT: 'Seleccione',
            AuditCategory: 'Categoría de Auditoría'
        },
        BUTTONS: {
            Login: 'Inicio de sesió',
            Submit: 'Someter',
            OK: 'OK',
            Cancel: 'Cancelar',
            Proceed: 'Proceder',
            Delete: 'Borrar',
            AssignBack: 'Asignar Atrás',
            SavePreview: 'Guardar y obtener una vista previa',
            SEARCH: 'Cachear',
            SCANWO: 'Escanear WO',
            DOWNLOADEXCEL: 'Descarga Excel',
            DOWNLOAD_AUDIT_IMAGES: 'Descargar Imágenes'
        },
        STRINGS: {
            GoBackToLogin: 'Regrese a la Inicio de sesió',
            ThankYou: 'Usted Gracias',
            ProblemSigningIn: '¿Problema al iniciar sesión?',
            FIELDREQUIRED: 'Este campo es requerido.',
            PleaseSelectRole: 'Por favor seleccione su función.',
            SelectLine: 'Por favor seleccione línea',
            SelectAsset: 'Por favor seleccione activo',
            SelectType: 'Por favor seleccione el tipo',
            CorrectEmail: 'Ingrese el ID de correo electrónico correcto.',
            CorrectPassword: 'Ingrese la contraseña correcta.',
            CONFIRMATIONMESSAGE: `Afirmo y certifico que toda la información y las respuestas a las preguntas aquí contenidas son completas, verdaderas y correctas a mi leal saber y entender.`,
            ReasonMessage: `Proporcione una razón para volver a asignar esta tarea al auditor.`,
            DownloadingFAQ: 'Descarga de preguntas frecuentes',
            FileMissing: 'Parece que falta este archivo. Por favor, inténtelo de nuevo más tarde',
            FetchingData: 'Obtención de datos',
            FetchingQuestion: 'Obtención preguntas',
            FetchUserData: 'Obtención de datos de usuarios',
            NoneMH: 'No puede seleccionar Ninguno como valor mientras anula la selección de la casilla de verificación MH',
            NoneGA: 'No puede seleccionar Ninguno como valor mientras selecciona Ninguno en la Actividad general de activos',
            NoneActivity:
                'No puede desmarcar la casilla de verificación MH mientras tenga Ninguno como actividad',
            FetchLine: 'Obtención de líne',
            FetchAssets: 'Obtención de activos',
            MandatoryFields: 'Por favor llenar to campos obligatorios.',
            CreatingTask: 'Creando Tarea',
            CreatingAudit: 'Creando de auditoría',
            UpdatingTask: 'Tarea de Actualizando',
            AllFieldsRequired: 'Todos los campos son obligatorios',
            ALLFIELDREQUIREDPROCEED: 'Todos los campos son obligatorios para proceder.',
            FetchingEBSData: 'Obteniendo datos de EBS',
            FileUploadRequired: 'Se requiere carga es de archivo',
            DataRetrievedSuccess: 'Datos recuper con éxito',
            RestructureDEBSData: 'Datos de EBS de Reestructuración',
            SearchingWorkOrder: 'Buscaen de orden de trabajo',
            SDEDMandatory:
                'Tanto la fecha de inicio como la fecha de finalización son obligatorias.',
            PleaseUploadExcel: 'Por Favor Cargue solo el archivo de Excel.',
            SelectARReviewer: 'Seleccionar revisor de AR',
            PleaseSelectAuditType: 'Por Favor seleccione el auditoría d tipo',
            PleaseSelectArea: 'Por favor seleccione área',
            PleaseSelectAGA: 'Por favor seleccione Actividad general de activos',
            PleaseSelectActivity: 'Por favor seleccione la actividad',
            SelectMHQuestion: 'Seleccione MH Pregunta',
            SelectDVQuestion: 'Seleccione DV Pregunta',
            PleaseSelectPercentage: 'Por favor seleccione el porcentaje de competencia',
            FetchEBSData: 'Obtener datos de EBS',
            FetchingTasks: 'Obteniendo Tareas',
            UpdatingAudit: 'Auditoría de Actualización',
            DeletingTask: 'Eliminar Tarea',
            TaskDeletedSuccess: 'Tarea eliminarda con éxito.',
            FetchingTaskList: 'Obteniendo List Tarea',
            FetchingAuditList: 'Obteniendo lista de auditoría',
            NoAuditsAvailable: 'No hay Auditorías Disponibles.',
            NoTasksAvailable: 'No Tareas Disponibles.',
            ConfirmAuditDelete: 'Está seguro de que desea eliminar la auditoría?',
            ConfirmTaskDelete: 'Está seguro de que desea eliminar la tarea?',
            SubmittingData: 'Presentadar de Datos',
            DataSavedSuccess: 'Datos guardados exitosamente',
            AcceptDeclaration: 'Acepte la declaración para continuar.',
            UploadingImage: 'Subiendo imagen',
            UnsupportedFileType: 'Este tipo de archivo no es compatible.',
            MaxImages: 'Puedes subir hasta 3 imágenes',
            ClickToAnswer: 'Haga Clic Para Responder',
            DeleteImage: 'Eliminar Imagen',
            SaveBeforeAssign: 'Guarde antes de volver a asignar la tarea al auditor.',
            ConfirmReassign: 'Está seguro de que desea volver a asignar esta tarea al auditor?',
            AssigningBack: 'Asignar tarea de nuevo a la auditor',
            AddMHAuditPoint: 'Agregar punto de auditoría de MH',
            AuditTasksQuestions: 'Tareas / Preguntas de auditoría',
            OBSERVATIONREQ: 'Por favor llenar la Observación para',
            IMAGEREQ: 'Por favor sube la imagen para',
            QUESTIONNAIREREQ: 'Por favor llenar el cuestionario para',
            IMPACTREQ: 'Por favor llenar el Impacto para',
            RECOMMENDATIONREQ: 'Por favor llenar la Recomendación para',
            IMPACTRECOMMENDATIONREQ: 'Por favor llenar el Impacto y la recomendación para',
            VALUEREQ: 'Por favor llenar fuera el valor',
            STARTENDDATEREQ:
                'Por favor rellenar fuera la fecha de inicio y la fecha de finalización para',
            ITEMINLISTALREADY: 'Ya tienes este artículo en la lista',
            ITEMNUMBERSEARCH: 'Por favor Ingrese el número de artículo para buscar.',
            MANDATORYFIELDPR: 'es obligatoria para el número de artículo',
            VALIDATIONREQ: 'Por favor llenar la Validación para',
            WORKORDERINLISTALREADY: 'Ya tienes esta orden de trabajo en la lista',
            WORKORDERSEARCH: 'Por favor ingrese el número de orden de trabajo para buscar.',
            INSERTCARD: 'Insertar Tarjeta',
            WOMADATORYFIELDS: 'Por favor proporcione entradas para el número de orden de trabajo',
            SAVINGDATA: 'Ahorro datos',
            DELETINGIMAGE: 'Eliminando imagen',
            ADDQUESTIONMANDATORYFIELD: 'Por favor proporcione detalles para agregar una pregunta.',
            SEARCHEDWO: 'Orden de trabajo buscar',
            ISNOTFOUND: 'no se encuentra.',
            STILLWANTTOSUBMIT: 'Si aún desea someter entonces,',
            PROVIDEREQUESTEDWODETAILS:
                'Por favor Proporcione detalles para la orden de trabajo solicitada.',
            SELECTWOTYPE: 'Seleccione WO tipo',
            ENTERHERE: 'Entre aquí',
            SEARCHITEM: 'Búsqueda artículo',
            PROVIDEREQUESTEDITEMDETAILS:
                'Por favor proporcione los detalles del artículo solicitado.',
            REASONMESSAGETOPROCEED: 'Proporcione un motivo antes de proceder',
            FETCHINGALLUSERS: 'Obteniendo todos los usuarios',
            ADDINGCARD: 'Añadiendo Tarjeta',
            SEARCHNGPR: 'Búsqueda de Sala de Piezas',
            GENERATINGREPORT: 'Generando informe',
            VERFYINGTOGENERATEREPORT: 'Verificacando de auditoría para generar informe',
            DOWNLOADINGTASK: 'Descargando Tarea',
            DOWNLOADINGREPORT: 'Descarga del informe de auditoría',
            DOWNLOADINGEXCEL: 'Descargando WO excel',
            ADDINGQUESTION: 'Añadiendo pregunta',
            DELETINGCARD: 'Eliminando Tarjeta',
            FETCHINGPLANTDETAILS: 'Obteniendo detalles de la planta',
            CHANGINGPASSWORD: 'Cambiando contraseña',
            NEWOLDPASSWORDNOTMATCH:
                'Los campos de contraseña nueva y contraseña de confirmación no coinciden.',
            ENTERALLFIELDSCORRECTLY: 'Por favor Ingrese todos los campos correctamente.',
            NODATAFOUND: 'Datos no encontrados.',
            DOWNLOADING_IMAGES: 'Descarga de imágenes de auditoría. Esto podría tomar un tiempo.',
            AuditIsDVType: 'Solo puedes agregar tareas DV a una auditoría DV.',
            AuditIsNotDVType: 'No puedes agregar tareas DV a una auditoría que no es DV.'
        }
    }
};

export default LANGUAGECONSTANT;
