/**
 * @author Akshay Kumar Singh
 * @file WOQuestionAns Component
 * @flow
 */
import React from 'react';
import WOAuditorAnsComponent from './WOAuditorAnsComp';
import WOReviewerAnsComp from './WOReviewerAnsComp';
import { getData } from '../../../../shared/utils/storage.util';

/**
 * @description This functional component pass task related data in its auditor and reviewer components
 */
const WOQuestionAnsComponent = (props) => {
    let { userData } = getData('loginData');
    let { taskInfo, QuesList } = props;
    let { task_status } = taskInfo ? taskInfo : {};
    return task_status == 1 ||
        userData.role_name === 'auditor' ||
        userData.role_name === 'plant auditor' ? (
        <WOAuditorAnsComponent
            taskInfo={taskInfo}
            QuesList={QuesList}
            langData={props.langData}
            history={props.history}
        />
    ) : (
        <WOReviewerAnsComp
            taskInfo={taskInfo}
            QuesList={QuesList}
            getQuestionData={props.getQuestionData}
            langData={props.langData}
            history={props.history}
        />
    );
};

export default WOQuestionAnsComponent;
