/**
 * @author Akshay Kumar Singh
 * @file Dashboard Component
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setData, getData, removeData } from '../../shared/utils/storage.util';
import { spinnerService } from '../../shared/services/spinner.service';
import { CallApi } from '../../shared/services/api.service';
import CONFIG from '../../configuration';
import AuditSummaryComponent from '../AuditSummaryComponent';
import LANGUAGECONST from '../../shared/constants/language';

class DashboardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            userId: getData('loginData')?.userData?.user_id,
            plantList: [],
            page: 1,
            plantId: '',
            sort: 0
        };
    }

    componentDidMount = () => {
        removeData('plantDetails');
        removeData('createdAuditObj');
        setData('createAuditFlag', false);
        this.getPlantList();
    };

    /**
     * @description This function fetch all plants data.
     */
    getPlantList = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        const plantListObj = {
            user_id: this.state.userId
        };
        spinnerService.show('Oval', lang.STRINGS.FETCHINGPLANTDETAILS);
        CallApi('POST', CONFIG.plantList, {}, plantListObj, '', 'plantList', this, language);
    };

    /**
     * @description plantList API callback function
     * @param response This is the response structure received from API
     */
    plantList = (response) => {
        if (response.success) {
            this.setState({
                plantList: response.response.plant
            });
            setData('plantListData', response.response.plant);
        }
    };

    render() {
        return (
            <main>
                {
                    <AuditSummaryComponent
                        tabType={getData('checkPage')}
                        history={this.props.history}
                        page={this.props.location?.state?.page || getData('checkPage') || 1}
                        location={this.props.location}
                        plantList={this.state.plantList}
                    />
                }
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    const data = state.data;
    return data;
};

export default connect(mapStateToProps)(DashboardComponent);
