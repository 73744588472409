/**
 * @author Akshay Kumar Singh
 * @file Alert Modal
 * @flow
 */
import React, { Component } from 'react';
import * as CONSTANT from '../../shared/constants/data';
import ROUTES from '../../shared/constants/routes';
import Modal from 'react-responsive-modal';
import { withRouter } from 'react-router';
import LANGUAGECONST from '../constants/language';

class AlertModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            modalMessage: '',
            data: '',
            path: '',
            objt: '',
            suggestionMessage: ''
        };
        AlertModal._singletonRef = this;
    }

    /**
     * @description This function called to show alert modal.
     * @param message It has the message to be shown on Alert modal.
     * @param path It has value which decides where to do on pressing OK button of modal.
     * @param data It has data to be passed from calling function.
     * @param obj It has Object ref of calling component.
     */
    static showAlert = (message, path, data, obj) => {
        AlertModal._singletonRef.setState({
            path: path,
            data: data,
            objt: obj
        });
        AlertModal._singletonRef.openAlertModal(message);
    };

    /**
     * @description This function hides the alert modal.
     */
    static hideAlert = () => {
        AlertModal._singletonRef.setState({
            open: false
        });
    };

    /**
     * @description This function open alert modal
     * @param message It has the alert message to be shown.
     */
    openAlertModal = (message) => {
        this.setState({
            open: true,
            modalMessage: message
        });
    };

    /**
     * @description This function hides the alert modal and perform specific operation on call.
     * @param It has boolean value which determine from which button this function is called.
     */
    onCloseModal = (action) => {
        this.setState({ open: false });
        if (this.state.path === ROUTES.LANDING && this.state.data) {
            this.props.history.push({
                pathname: ROUTES.LANDING,
                state: {
                    data: this.state.data
                }
            });
        } else if (this.state.path == 'delete') {
            window.location.reload();
        } else if (this.state.path == 'submit' || this.state.path == 'assignBack') {
            if (!action) {
                eval(`this.state.objt.${this.state.data}()`);
            }
        } else if (this.state.path == 'submitAudit') {
            if (!action) {
                eval(`this.state.objt.${this.state.data}(this.state.suggestionMessage)`);
            }
        }
    };

    /**
     * @description This function sets value of variables.
     */
    handleChange = (event) => {
        let { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    render() {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        const { open, path } = this.state;
        return (
            <div>
                <Modal open={open} onClose={this.onCloseModal} center>
                    <div className="rqst-accs-popup">
                        <div className="popup-cntnt popuptext">
                            {this.state.modalMessage && (
                                <>
                                    <p>{this.state.modalMessage}</p>
                                    <br />
                                </>
                            )}
                            {path === 'submitAudit' && (
                                <textarea
                                    name="suggestionMessage"
                                    rows="8"
                                    cols="60"
                                    style={{ resize: 'none' }}
                                    onChange={this.handleChange}
                                    placeholder="Enter your suggestion here..."></textarea>
                            )}
                        </div>
                        <div className="popup-multi-btn">
                            {(path === 'submit' ||
                                path === 'assignBack' ||
                                path === 'submitAudit') && (
                                <button
                                    className="btn-prmry btn-cancel btn-rqst-accs"
                                    onClick={() => this.onCloseModal(1)}>
                                    {CONSTANT.BUTTONS.Cancel}
                                </button>
                            )}
                            <button
                                className="btn-prmry btn-rqst-accs"
                                onClick={() => this.onCloseModal(0)}>
                                {CONSTANT.BUTTONS.OK}
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(AlertModal);
