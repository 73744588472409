/**
 * @author Akshay Kumar Singh
 * @file Auth Util
 * @flow
 */
import { getData, removeData } from './storage.util';
import ROUTES from '../constants/routes';
import AlertModal from '../modals/AlertModal';

/**
 * @description This is used to authenticate the user.
 */
const isAuthenticated = () => {
    if (!getData('isLogin') || !getData('token')) {
        return false;
    } else {
        return true;
    }
};

const logoutUnauthorizedUser = (obj, msg) => {
    localStorage.removeItem('userData');
    obj.props.history.push(ROUTES.LANDING);
    AlertModal.showAlert(msg);
};

export { isAuthenticated, logoutUnauthorizedUser };
