/**
 * @author Akshay Kumar Singh
 * @file WOAuditorAns Component
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { WO_QUES_DATA } from '../../../../redux/actions/common_action_types';
import {
    getPostDataWithType,
    getPostDataWithAction,
    downloadFile
} from '../../../../redux/actions/common_action';
import CONFIG from '../../../../configuration';
import AlertModal from '../../../../shared/modals/AlertModal';
import { spinnerService } from '../../../../shared/services/spinner.service';
import { CallApi } from '../../../../shared/services/api.service';
import { serverCalls } from '../../../../shared/services/serverCalls.service';
import ScannerModule from '../../../../shared/BarcodeScanner/ScannerModule';
import { STRINGS } from '../../../../shared/constants/data';
import DataCardModal from '../../../../shared/modals/DataCardModal';
import ImagePrevew from '../../../../shared/modals/ImagePrevew';
import PreviewModal from '../../../../shared/modals/WOPreviewModal';
import IdleTimerComponent from '../../../Common/IdleTimeCheck/IdleTimerComponent';
import { checkFileType, checkVideoType } from '../../../../shared/utils/helper.util';
import { getRoleBasedRoute } from '../../../../shared/utils/helper.util';
import { getData } from '../../../../shared/utils/storage.util';
import { logoutUnauthorizedUser } from '../../../../shared/utils/auth.util';
import ImageVideoSelectionModal from '../../../../shared/modals/ImageVideoSelectionModal';
import Thumbnail from '../../../Common/Thumbnail/Thumbnail';
import LANGUAGECONST from '../../../../shared/constants/language';

class WOAuditorAnsComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idsArr: [],
            confirmation: false,
            searchVal: '',
            showImageModel: false,
            showPreviewModel: false,
            quesIndex: null,
            imgObj: null,
            imgIndex: null,
            taskInfo: {},
            QuesList: [],
            scan: false,
            allQuesData: [],
            imageArr: [],
            woOptionsArr: [],
            checkDataChange: false,
            onBarcodeOpen: false,
            submitObj: {},
            isUploadFile: false,
            uploadObj: null,
            isModalOpen: false
        };
    }

    componentDidMount = () => {
        let { taskInfo } = this.props;
        this.setState({ taskInfo });
        this.getWOData(taskInfo);
        this.getWoOptions(taskInfo);
        this.interval = setInterval(() => {
            this.submitAns(false, true);
        }, 15000);
    };

    componentWillReceiveProps = (nextProps) => {
        let { QuesList, woQuesData } = nextProps;
        if (!!woQuesData && woQuesData.length > 0 && QuesList.length > 0) {
            this.setState({
                QuesList: QuesList || []
            });
        }
    };

    /**
     * @description This function fetch work order data of task.
     * @param taskInfo It has info regarding task.
     */
    getWOData = async (taskInfo) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { allQuesData, idsArr } = this.state;
        let { task_id } = taskInfo;
        this.setState({
            taskInfo: taskInfo || {}
        });
        let obj = { task_id, is_save: 1 };
        await this.props.getWoData(
            CONFIG.getWOQues,
            obj,
            WO_QUES_DATA,
            lang.STRINGS.FetchingData,
            this,
            language
        );
        if (!!this.props.woQuesData && this.props.woQuesData.length > 0) {
            this.props.woQuesData.map((data) => {
                idsArr.push(data.wo_order);
                allQuesData.push(data);
            });
            this.setState({ allQuesData });
        }
    };

    /**
     * @description This function fetch data for descripancy drop-down.
     * @param taskInfo It has info regarding task.
     */
    getWoOptions = (taskInfo) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { task_id } = taskInfo;
        this.setState({
            taskInfo: taskInfo || {}
        });
        let woOptionsObj = { task_id };
        spinnerService.show('Oval', lang.STRINGS.FetchingData);
        CallApi('POST', CONFIG.getWoOptions, {}, woOptionsObj, '', 'woOptions', this, language);
    };

    /**
     * @description getWoOptions API callback function
     * @param response This is the response structure received from API
     */
    woOptions = (response) => {
        if (response.success) {
            this.setState({
                woOptionsArr: response.response
            });
        }
    };

    /**
     * @description This function search work order number and fetch its data.
     */
    searchWorkOrder = async () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { idsArr, searchVal, taskInfo, allQuesData } = this.state;
        if (!!searchVal) {
            if (idsArr.includes(searchVal)) {
                return AlertModal.showAlert(lang.STRINGS.WORKORDERINLISTALREADY);
            }
            let { task_id } = taskInfo;
            let obj = { order: searchVal, task_id };
            await this.props.getWoData(
                CONFIG.getWOSearchQues,
                obj,
                WO_QUES_DATA,
                lang.STRINGS.SearchingWorkOrder,
                this,
                language
            );
            if (!!this.props.woQuesData && this.props.woQuesData.length) {
                idsArr.push(searchVal);
                this.props.woQuesData.map((data) => {
                    allQuesData.unshift(data);
                });
                this.setState({ idsArr, allQuesData, checkDataChange: true });
            } else {
                if (!!this.props.woQuesData && this.props.woQuesData.length == 0) {
                    this.showHideDataCardModal();
                }
            }
        } else {
            return AlertModal.showAlert(lang.STRINGS.WORKORDERSEARCH);
        }
    };

    /**
     * @description This function saves manually added work order in the list of wo data in database
     * @param woItemObj It has the required values to create work order data manually.
     */
    handleManualOrder = (woItemObj) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        this.showHideDataCardModal();
        spinnerService.show('Oval', lang.STRINGS.INSERTCARD);
        CallApi('POST', CONFIG.insertTabs, {}, woItemObj, '', 'woManualItem', this, language);
    };

    /**
     * @description insertTabs API callback function
     * @param response This is the response structure received from API
     */
    woManualItem = (response) => {
        let { idsArr, searchVal, taskInfo, allQuesData } = this.state;
        if (response.success) {
            if (response.response.length) {
                idsArr.push(searchVal);
                response.response.map((data) => {
                    allQuesData.unshift(data);
                });
                this.setState({ idsArr, allQuesData, checkDataChange: true });
            }
        }
    };

    /**
     * @description This function set value of searched work order number
     */
    handleSearch = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    };

    /**
     * @description This function set values of work order cards to data object.
     */
    handleChange = (event) => {
        let { allQuesData } = this.state;
        let { id, name, value } = event.target;
        allQuesData[id][name] = value;
        this.setState({ allQuesData, checkDataChange: true });
    };

    /**
     * @description This function set character limit on fields
     */
    handleChangeWithLength = (event) => {
        let { value } = event.target;
        if (value.length <= 400) {
            this.handleChange(event);
        }
    };

    /**
     * @description This function validates the values of data object on submit
     * @param dataArr It has data objects to be validated
     */
    validateData = (dataArr) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let error = null;
        let { checkDataChange } = this.state;
        // if (!checkDataChange) {
        dataArr.map((data, index) => {
            if (!(data.validation && data.validation == 'Pass')) {
                if (!data.validation) {
                    return (error = `${lang.STRINGS.WOMADATORYFIELDS} ${data.wo_order}`);
                }
            }
        });
        // } else {
        //   return error = 'Please save before submit.';
        // }
        return error;
    };

    /**
     * @description This function formats data
     * @param dataArr It has data objects to be formatted
     */
    formatData = (dataArr) => {
        let data = dataArr.map((data) => {
            let { order_id, status, validation, comments, long_heading, is_save } = data;
            is_save = 1;
            return { order_id, status, validation, comments, long_heading, is_save };
        });
        return data;
    };

    /**
     * @description This function calls on save and submit data. On save it show preview of
     * data and on submit that task is being assigned to the reviewer. It also get called after being
     * idle for 5 minutes and saves the data only without preview.
     * @param isSubmit It has boolean value to check if the function is called to save or submit the data
     * @param isIdle It has boolean value to check if function is call due to idle situation for 5 min or not
     */
    submitAns = async (isSubmit, isIdle = false) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo, allQuesData, confirmation } = this.state;
        let { task_id, task_status } = taskInfo;
        let error = isSubmit
            ? this.validateData(allQuesData)
            : this.setState({ checkDataChange: false });
        if (error) {
            return AlertModal.showAlert(error);
        }
        let data = {
            task_id,
            task_status: isSubmit ? task_status + 1 : task_status,
            ques: this.formatData(allQuesData),
            is_save: 1,
            isEmail: isSubmit ? true : false,
            isAuditor: true,
            isTaskCompleted: isSubmit ? 1 : 0
        };

        if (!isSubmit) {
            let route = isSubmit ? getRoleBasedRoute() : 'SAVE';
            await this.props.submitAns(
                CONFIG.addWOQues,
                data,
                !isIdle ? route : 'idle',
                lang.STRINGS.SubmittingData,
                this,
                language
            );
            if (!isIdle) {
                this.showHidePreviewModel();
            }
        } else {
            this.setState({ submitObj: data }, () => {
                if (!confirmation) {
                    AlertModal.showAlert(lang.STRINGS.AcceptDeclaration);
                } else {
                    AlertModal.showAlert('', 'submitAudit', 'onSubmit', this);
                }
            });
        }
    };

    /**
     * @description This function submits data with suggestion message and
     * the task is being assigned to the reviewer.
     * @param suggestion_msg It has value of sugestion message from auditor, it can be null.
     */
    onSubmit = async (suggestion_msg) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        const { submitObj } = this.state;
        submitObj.suggestion_msg = suggestion_msg;
        let route = getRoleBasedRoute();
        await this.props.submitAns(
            CONFIG.addWOQues,
            submitObj,
            route,
            lang.STRINGS.SubmittingData,
            this,
            language
        );
    };

    /**
     * @description This function uploads the image.
     * @param data It consists data of image file.
     */
    uploadImage = async (index, data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { allQuesData } = this.state;
        spinnerService.show('Oval', lang.STRINGS.UploadingImage);
        try {
            const config = {
                headers: { authorization: `Bearer ${getData('token')}`, language: language }
            };
            let response = await Axios.post(CONFIG.uploadQuesImage, data, config);
            spinnerService.hide('Oval');
            if (response && response.data && response.data.responseCode == 200) {
                allQuesData[index]['url'] = allQuesData[index]['url']
                    ? allQuesData[index]['url'].concat(response.data.response?.url)
                    : response.data.response?.url;
                this.setState({ allQuesData, checkDataChange: true });
            }
            if (response && response.data && response.data.responseCode == 401) {
                logoutUnauthorizedUser(this, response.data.message);
            } else {
                AlertModal.showAlert(response.data.message);
            }
        } catch (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        }
    };

    /**
     * @description This function handle images data.
     * @param data It consists data of image file.
     */
    handleFile = (event, data, type) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        this.handleUploadModalClose();
        let { id, name, files } = event.target;
        let { taskInfo } = this.state;
        let { task_id } = taskInfo;
        let { url } = data;
        if (
            files.length &&
            ((type === 'image' && !checkFileType(files[0].type)) ||
                (type === 'video' && !checkVideoType(files[0].type)))
        ) {
            return AlertModal.showAlert(lang.STRINGS.UnsupportedFileType);
        }
        if ((url && url.length + files.length <= 4) || (!url && files.length <= 4)) {
            let formData = new FormData();
            formData.append('task_id', task_id);
            formData.append('ques_id', event.target.getAttribute('data-id'));
            formData.append('reference_flag', 1);
            for (let file of files) {
                formData.append('file', file);
            }
            this.uploadImage(name, formData);
        } else {
            AlertModal.showAlert(lang.STRINGS.MaxImages);
        }
    };

    openUploadModal = (ev, data, index) => {
        ev.preventDefault();
        this.setState({
            isUploadFile: true,
            uploadObj: { data: data, index: index }
        });
    };

    handleUploadModalClose = () => {
        this.setState({
            isUploadFile: false
        });
    };

    /**
     * @description This function open or closes the preview modal.
     */
    showHidePreviewModel = () => {
        let { showPreviewModel } = this.state;
        this.setState({ showPreviewModel: !showPreviewModel });
    };

    /**
     * @description This function shows image preview.
     * @param quesIndex It consists index of work order whose against the image is uploaded.
     * @param imgObj It consists data of image file
     * @param imgIndex It consists index of image to identify which image is selected out of multiple images of same work order.
     */
    showHideImageModel = (quesIndex, imgObj, imgIndex) => {
        let { showImageModel } = this.state;
        this.setState({
            showImageModel: !showImageModel,
            quesIndex,
            imgObj,
            imgIndex
        });
    };

    /**
     * @description This function deletes the particular selected image of the work order.
     */
    deleteImage = async () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo, allQuesData, imgObj, quesIndex, imgIndex } = this.state;
        let { task_id } = taskInfo;
        const data = {
            task_id,
            img_id: imgObj.img_id
        };
        this.showHideImageModel();
        let response = await serverCalls(
            CONFIG.deleteTaskImages,
            data,
            lang.STRINGS.DELETINGIMAGE,
            this,
            language
        );
        if (response) {
            allQuesData[quesIndex]['url'].splice(imgIndex, 1);
            this.setState({ allQuesData });
        }
    };

    /**
     * @description This function deletes the work order.
     * @param data It has the data of the work order to be deleted
     */
    deleteCard = async (index, data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { allQuesData, idsArr } = this.state;
        let { order_id, is_save, wo_order } = data;
        let obj = {
            asset_type_id: 1,
            ques_id: order_id,
            is_save: is_save ? is_save : 0
        };
        let response = await serverCalls(
            CONFIG.deleteTabTask,
            obj,
            lang.STRINGS.DELETINGCARD,
            this,
            language
        );
        if (response) {
            idsArr.splice(idsArr.indexOf(wo_order), 1);
            allQuesData.splice(index, 1);
            this.setState({ idsArr, allQuesData });
        }
    };

    /**
     * @description This function sets the value of descrepancy drop-down according to its WO type.
     * @param data It has data value of descrepancies of all work order types.
     */
    getSelectBox = (index, data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { woOptionsArr, taskInfo } = this.state;
        let { task_status } = taskInfo ? taskInfo : {};
        let isDisabled = task_status == 1 ? false : true;
        let optionArr = woOptionsArr?.[data.long_heading];
        return (
            <select
                className="form-control"
                id={index}
                name="validation"
                disabled={isDisabled}
                value={data.validation ? data.validation : ''}
                onChange={this.handleChange}>
                <option disabled value="">
                    {lang.LABLES.SelectOption}
                </option>
                {optionArr?.map((quesObj, indexKey) => (
                    <option key={indexKey} value={quesObj}>
                        {quesObj}
                    </option>
                ))}
            </select>
        );
    };

    /**
     * @description This function downloads the excel of the work order task.
     */
    downloadExcel = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo } = this.state;
        let { task_id } = taskInfo;
        const startDate = new Date(Date.parse(taskInfo.task_start_date));
        const endDate = new Date(Date.parse(taskInfo.task_end_date));
        const finalStartDate =
            (startDate.getMonth() < 10
                ? '0' + (startDate.getMonth() + 1)
                : (startDate.getMonth() + 1).toString()) +
            (startDate.getDate() < 10
                ? '0' + startDate.getDate()
                : startDate.getDate().toString()) +
            startDate.getFullYear().toString();
        const finalEndDate =
            (endDate.getMonth() < 10
                ? '0' + (endDate.getMonth() + 1)
                : (endDate.getMonth() + 1).toString()) +
            (endDate.getDate() < 10 ? '0' + endDate.getDate() : endDate.getDate().toString()) +
            endDate.getFullYear().toString();
        const fileName = `${taskInfo.plant_code}-WO-${finalStartDate}-${finalEndDate}`;
        downloadFile(
            `${CONFIG.downloadWOExcel
                .replace('{task_id}', task_id)
                .replace('{fileName}', fileName)}`,
            `${fileName}.xlsx`,
            lang.STRINGS.DOWNLOADINGWOEXCEL,
            true,
            language
        );
    };

    componentWillUnmount = () => {
        this.props.removeWOData(WO_QUES_DATA);
        clearInterval(this.interval);
    };

    /**
     * @description This function set value of the scanned barcode in serachVal variable and calls searchWorkOrder funciton
     * @param result It has scanned result of barcode
     */
    startScanner = (result) => {
        this.setState({
            searchVal: result?.codeResult?.code
        });
        this.onBarcodeCancel();
        this.searchWorkOrder();
    };

    /**
     * @description This function opens the barcode scanner.
     */
    openScanner = (scan) => {
        this.setState({
            scan: scan,
            onBarcodeOpen: true
        });
    };

    /**
     * @description This function closes the barcode scanner.
     */
    onBarcodeCancel = () => {
        this.setState({
            onBarcodeOpen: false
        });
    };

    /**
     * @description This function handles open and close of modal to enter work order manually.
     */
    showHideDataCardModal = () => {
        const { isModalOpen } = this.state;
        this.setState({
            isModalOpen: !isModalOpen
        });
    };

    /**
     * @description This function handles the task's confirmation for declaration.
     */
    handleCheckBox = () => {
        let { confirmation } = this.state;
        this.setState({
            confirmation: !confirmation
        });
    };

    render() {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let {
            searchVal,
            scan,
            showImageModel,
            imgObj,
            allQuesData,
            taskInfo,
            onBarcodeOpen,
            showPreviewModel,
            isModalOpen,
            confirmation,
            isUploadFile,
            uploadObj
        } = this.state;
        let { task_status } = taskInfo ? taskInfo : {};
        let isDisabled = task_status == 1 ? false : true;
        return (
            <div className="work-order-wrap">
                {/* <IdleTimerComponent onSave={() => this.submitAns(false, true)} /> */}
                <div className="filter-container barcode-scanner">
                    <div className="barcode-excel">
                        {!isDisabled && (
                            <button
                                type="button"
                                className="btn-blue"
                                onClick={() => this.openScanner(true)}>
                                {lang.BUTTONS.SCANWO}
                            </button>
                        )}
                        <button className="btn-blue" onClick={this.downloadExcel}>
                            {lang.BUTTONS.DOWNLOADEXCEL}
                        </button>
                    </div>
                    {!isDisabled && (
                        <div className="form-group">
                            <label>{lang.LABLES.WONUMBER}</label>&nbsp;
                            <input
                                type="text"
                                className="form-control"
                                maxLength={20}
                                name="searchVal"
                                value={searchVal}
                                onChange={this.handleSearch}
                            />
                            <button
                                type="button"
                                className="btn-blue"
                                onClick={this.searchWorkOrder}>
                                {lang.BUTTONS.SEARCH}
                            </button>
                        </div>
                    )}
                </div>
                <div className="headiong-outer">
                    <h4>{lang.STRINGS.AuditTasksQuestions}</h4>
                </div>
                <div className="main-cntnt-wrap " id="existing-audits-summary-wrap">
                    <div className="container">
                        <ol className="breadcrumb"></ol>
                    </div>
                    <div className="existing-audits-summary-outer">
                        <div className="container">
                            <div className="form-row task-row">
                                {allQuesData.length > 0 &&
                                    allQuesData.map((data, index) => {
                                        return (
                                            <div className="" key={index}>
                                                {/* <div className={`summary-boxes ${(data.manually_tab == 1) ? 'completed-info' : 'completed-info'}`}> */}
                                                <div className="summary-rows">
                                                    <div style={{ width: '10%' }}>
                                                        {lang.LABLES.AssetNumber} :{' '}
                                                        <strong>{data.asset_no}</strong>
                                                    </div>
                                                    <div style={{ width: '10%' }}>
                                                        {lang.LABLES.OrderNumber} :{' '}
                                                        <strong>{data.wo_order}</strong>
                                                    </div>
                                                    <div style={{ width: '10%' }}>
                                                        {lang.LABLES.WORKORDERTYPE} :{' '}
                                                        <strong>{data.long_heading}</strong>
                                                    </div>
                                                    <div style={{ width: '10%' }}>
                                                        {lang.LABLES.WODESCRIPTION} :{' '}
                                                        <strong>{data.wo_desc}</strong>
                                                    </div>
                                                    <div style={{ width: '10%' }}>
                                                        {lang.LABLES.Status} :{' '}
                                                        <strong>
                                                            {data.status ? data.status : 'N/A'}
                                                        </strong>
                                                    </div>
                                                    <div style={{ width: '10%' }}>
                                                        {lang.LABLES.SCHEDULEDSTARTDATE} :{' '}
                                                        <strong>
                                                            {data.start_date
                                                                ? data.start_date
                                                                : 'N/A'}
                                                        </strong>
                                                    </div>
                                                    <div style={{ width: '10%' }}>
                                                        <span>{lang.LABLES.Discrepancy}</span>
                                                        {this.getSelectBox(index, data)}
                                                    </div>
                                                    <div className="data-upload-col">
                                                        <div className="upload-file-control">
                                                            <span>{lang.LABLES.UploadFile}</span>
                                                            <input
                                                                type="file"
                                                                id={data.order_id}
                                                                name={index}
                                                                multiple
                                                                disabled={isDisabled}
                                                                onChange={(event) =>
                                                                    this.openUploadModal(
                                                                        event,
                                                                        data,
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                htmlFor={data.order_id}
                                                                onClick={(event) =>
                                                                    this.openUploadModal(
                                                                        event,
                                                                        data,
                                                                        index
                                                                    )
                                                                }>
                                                                {lang.LABLES.UploadFile}
                                                            </label>
                                                        </div>
                                                        <div className="group-upload-images margin-bottom-10">
                                                            {data.url &&
                                                                data.url.map(
                                                                    (imgObj, imgIndex) =>
                                                                        imgObj.img_path &&
                                                                        (!imgObj.isVideo ? (
                                                                            <img
                                                                                key={imgIndex}
                                                                                src={
                                                                                    imgObj.img_path
                                                                                }
                                                                                alt="Images Icons"
                                                                                className="upload-images"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        !isDisabled
                                                                                    ) {
                                                                                        this.showHideImageModel(
                                                                                            index,
                                                                                            imgObj,
                                                                                            imgIndex
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <div
                                                                                className="upload-images"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        !isDisabled
                                                                                    ) {
                                                                                        this.showHideImageModel(
                                                                                            index,
                                                                                            imgObj,
                                                                                            imgIndex
                                                                                        );
                                                                                    }
                                                                                }}>
                                                                                <Thumbnail
                                                                                    key={imgIndex}
                                                                                    videoUrl={
                                                                                        imgObj.img_path
                                                                                    }
                                                                                    width={38}
                                                                                    height={38}
                                                                                />
                                                                            </div>
                                                                        ))
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '10%' }} className="">
                                                        <span>{lang.LABLES.COMMENT}</span>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id={index}
                                                            name="comments"
                                                            disabled={isDisabled}
                                                            value={
                                                                data.comments ? data.comments : ''
                                                            }
                                                            onChange={this.handleChangeWithLength}
                                                        />
                                                    </div>
                                                    <div className="summary-btn">
                                                        <button
                                                            className=""
                                                            onClick={() =>
                                                                this.deleteCard(index, data)
                                                            }>
                                                            <img
                                                                src={require('../../../../assets/images/dustbin-icon.png')}
                                                                alt="delete-icon"
                                                            />
                                                        </button>
                                                    </div>
                                                    {/* <button className="btn-prmry" onClick={() => this.deleteCard(index, data)}>{lang.BUTTONS.Delete}</button> */}
                                                    {/* <a href="!#" onClick={() => this.deleteCard(index, data)}><img src={require("../../../../assets/images/dustbin-icon.png")} alt="delete-icon" /></a> */}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
                {allQuesData.length > 0 && !isDisabled && (
                    <>
                        <div>
                            <input
                                type="checkbox"
                                name="confirmation"
                                value={confirmation}
                                onChange={this.handleCheckBox}
                            />
                            <label htmlFor="confirmation">
                                {' '}
                                {lang.STRINGS.CONFIRMATIONMESSAGE}
                            </label>
                        </div>
                        <div className="submition-outer">
                            <button
                                className="btn-prmry"
                                onClick={() => {
                                    this.submitAns(false);
                                }}>
                                {lang.BUTTONS.SavePreview}
                            </button>
                            <button className="btn-prmry" onClick={() => this.submitAns(true)}>
                                {lang.BUTTONS.Submit}
                            </button>
                        </div>
                    </>
                )}
                {showImageModel && (
                    <ImagePrevew
                        isModalOpen={showImageModel}
                        imgObj={imgObj}
                        showDeleteButton={true}
                        onCancel={this.showHideImageModel}
                        deleteImage={this.deleteImage}
                        langData={this.props?.langData}
                    />
                )}
                {scan && (
                    <ScannerModule
                        isModalOpen={onBarcodeOpen}
                        startScanner={this.startScanner}
                        onCancel={this.onBarcodeCancel}
                        langData={this.props?.langData}
                    />
                )}
                {showPreviewModel && (
                    <PreviewModal
                        isModalOpen={showPreviewModel}
                        data={allQuesData}
                        taskInfo={taskInfo}
                        onClose={this.showHidePreviewModel}
                        langData={this.props?.langData}
                    />
                )}
                {isModalOpen && (
                    <DataCardModal
                        isModalOpen={isModalOpen}
                        onCancel={this.showHideDataCardModal}
                        handleManualOrder={this.handleManualOrder}
                        assetTypeId={1}
                        taskInfo={taskInfo}
                        woOrder={searchVal}
                        langData={this.props?.langData}
                    />
                )}
                {isUploadFile && (
                    <ImageVideoSelectionModal
                        isModalOpen={isUploadFile}
                        onCancel={this.handleUploadModalClose}
                        handleFile={this.handleFile}
                        index={uploadObj.index}
                        data={uploadObj.data}
                        langData={this.props?.langData}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        woQuesData: state.common.get('woQuesData')
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getWoData: (url, data, type, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithType(url, data, type, loaderMsg, obj, language)),
        uploadImage: (url, data, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithAction(url, data, null, null, loaderMsg, obj, language)),
        submitAns: (url, data, path, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithAction(url, data, path, null, loaderMsg, obj, language)),
        removeWOData: (type) =>
            dispatch({
                type,
                payload: []
            })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WOAuditorAnsComp);
