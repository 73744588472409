/**
 * @author Akshay Kumar Singh
 * @file AuditSummary Component
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { LABLES, AUDIT_STATUS, USER_ROLES, TAB_TYPES } from '../../shared/constants/data';
import Summary from './Summary';
import ROUTES from '../../shared/constants/routes';
import { encryptData } from '../../shared/utils/crypto.util';
import { setData, getData, removeData } from '../../shared/utils/storage.util';
import { getMinMaxDate, sortArrObj, showFilterType } from '../../shared/utils/helper.util';
import HeaderComponent from '../Common/HeaderComponent';
import { spinnerService } from '../../shared/services/spinner.service';
import { CallApi } from '../../shared/services/api.service';
import { serverCalls } from '../../shared/services/serverCalls.service';
import CONFIG from '../../configuration';
import CreateAuditModal from '../../shared/modals/CreateAuditModal';
import { CommonModal } from '../../shared/modals/CommonModal';
import LANGUAGECONST from '../../shared/constants/language';
import { languageData } from '../../redux/actions/languageAction';

class AuditSummaryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            showDelAuditModel: false,
            plantName: '',
            plantId: '',
            currentAudit: null,
            plantList: [],
            data: [],
            auditList: [],
            userTaskList: [],
            reviewerTaskList: [],
            auditorTaskList: [],
            fromDate: null,
            toDate: null,
            minDate: null,
            maxDate: null,
            AM_name: '',
            AM_Data: [],
            filteredDateList: [],
            filterStatus: '',
            filterByStatusArr: [
                { name: 'In Progress', status: 1 },
                { name: 'In Review', status: 2 },
                { name: 'Completed', status: 3 }
            ],
            userRole: getData('loginData').userData.role_name,
            user_id: getData('loginData').userData.user_id
        };
    }

    /**
     * @description This function used to open the create audit modal.
     * @returns Changes in state values i.e. isModalOpen to true.
     */
    openCreateAuditModal = () => {
        this.setState({ isModalOpen: true });
    };

    /**
     * @description This function used to close the create audit modal.
     * @returns Changes in state values i.e. isModalOpen to false
     */
    closeCreateAuditModal = (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        this.setState({ isModalOpen: false });
    };

    componentWillMount = () => {
        let { userRole, filterStatus } = this.state;
        let currentTab = getData('checkPage') || 1;
        this.setState({
            filterStatus:
                currentTab == 2
                    ? 1
                    : userRole === 'auditor'
                    ? 1
                    : userRole === 'reviewer'
                    ? 2
                    : filterStatus
        });
    };

    componentDidMount() {
        removeData('plantDetails');
        removeData('createdAuditObj');
        spinnerService.show('Oval');
        this._mountDataByRole(this.state.role);
    }

    /**
     * @description This function call the role dependent functions.
     */
    _mountDataByRole() {
        const { userRole } = this.state;
        switch (userRole) {
            case USER_ROLES['auditor']:
                this._handleReviewerAndAuditor();
                break;
            case USER_ROLES['reviewer']:
                this._handleReviewerAndAuditor();
                break;
            default:
                this._handleAuditManager();
        }
    }

    /**
     * @description This function used to get either task list or audit list based on tab selected.
     * @param activeTab This is the tab selected by user.
     */
    _handleAuditManager(activeTab) {
        let language = this.props?.langData?.language || 'en';
        this.setState({
            filteredDateList: []
        });
        const tabType = activeTab || getData('checkPage') || 1;
        const { user_id } = this.state;
        const tab_id = 1;
        switch (tabType) {
            case TAB_TYPES['My_Task']:
                spinnerService.show(
                    'Oval',
                    LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchingTaskList
                );
                CallApi(
                    'POST',
                    CONFIG.userTaskList,
                    {},
                    { user_id },
                    '',
                    'taskList',
                    this,
                    language
                );
                break;
            default:
                spinnerService.show(
                    'Oval',
                    LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchingAuditList
                );
                CallApi(
                    'POST',
                    CONFIG.userAuditList,
                    {},
                    !(getData('checkPage') == 3 || getData('checkPage') == 4)
                        ? { user_id }
                        : { tab_id },
                    '',
                    'auditList',
                    this,
                    language
                );
        }
    }

    /**
     * @description This function handle the plant list and sets value to the state variable.
     * @param data Plant data.
     * @returns Changes in state values i.e. Audit manager data, plant list, min date and max date.
     */
    handlePlantList = (data) => {
        let plantList = [],
            plantNameArr = [],
            AM_Data = [];
        let { minDate, maxDate } = getMinMaxDate(data);
        data.map((obj) => {
            let { plant_name, created_by } = obj;
            if (!plantNameArr.includes(plant_name)) {
                if (!!plant_name) {
                    plantList.push(obj);
                    plantNameArr.push(plant_name);
                }
            }
            if (!AM_Data.includes(created_by)) {
                AM_Data.push(created_by);
            }
        });
        this.setState({
            AM_Data: AM_Data.sort(),
            plantList: sortArrObj(plantList, 'plant_name'),
            minDate,
            maxDate
        });
    };

    /**
     * @description This function handle the tab change and set the state variables.
     * @returns Changes in state values.
     */
    onTabChange = (tab) => {
        spinnerService.show('Oval');
        this.setState(
            {
                fromDate: null,
                toDate: null,
                plantName: '',
                AM_name: '',
                filterStatus: tab == 2 ? 1 : ''
            },
            () => {
                this._handleAuditManager(tab);
            }
        );
    };

    /**
     * @description This function used to call the api to fetch task list basis of user id.
     */
    _handleReviewerAndAuditor() {
        let language = this.props?.langData?.language || 'en';
        const { userRole, user_id } = this.state;
        let callbackFn = userRole === 'auditor' ? 'auditorTaskList' : 'reviewerTaskList';
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchingTaskList
        );
        CallApi('POST', CONFIG.useridTaskList, {}, { user_id }, '', callbackFn, this, language);
    }

    /**
     * @description This function handle the response of auditor task list api.
     * @param response Response structure received from api.
     */
    auditorTaskList = (response) => {
        if (response.success) {
            const taskList = response.response.result.filter(
                (data) => data.auditor_name === getData('loggedUser')
            );
            this.setState(
                {
                    auditorTaskList: taskList
                },
                () => {
                    this.applyFilter();
                    // this.handlePlantList(taskList);
                }
            );
        }
    };

    /**
     * @description This function handle the response of reviewer task list api.
     * @param response Response structure received from api.
     */
    reviewerTaskList = (response) => {
        if (response.success) {
            response.response.result.forEach((data, index) => {
                if (data.reviewer_name === getData('loggedUser')) {
                    this.state.reviewerTaskList.push(response.response.result[index]);
                }
            });
            this.applyFilter();
            // this.handlePlantList(this.state.reviewerTaskList);
        }
    };

    /**
     * @description This function handle the response of task list api and call the functions for apply filter
     * and handle plant list.
     * @param response Response structure received from api.
     */
    taskList = (response) => {
        if (response.success) {
            this.setState(
                {
                    userTaskList: response.response.usertask
                },
                () => {
                    this.applyFilter();
                    // this.handlePlantList(response.response.usertask);
                }
            );
        }
    };

    /**
     * @description This function handle the response of audit list api also set the state of variable.
     * @param response Response structure received from api.
     */
    auditList = (response) => {
        const { page } = this.props;
        const _auditList = [];
        if (response.success) {
            response.response.forEach((data, index) => {
                if (page === TAB_TYPES['Draft'] && data.status === AUDIT_STATUS['IN_DRAFT']) {
                    _auditList.push(data);
                } else {
                    if (
                        page === TAB_TYPES['In_Progress'] &&
                        data.status === AUDIT_STATUS['IN_PROGRESS']
                    ) {
                        _auditList.push(data);
                    } else {
                        if (
                            page === TAB_TYPES['Completed'] &&
                            data.status === AUDIT_STATUS['COMPLETED']
                        ) {
                            _auditList.push(data);
                        }
                    }
                }
            });
            this.setState(
                {
                    auditList: _auditList,
                    filteredDateList: _auditList
                },
                () => {
                    this.handlePlantList(_auditList);
                }
            );
        }
    };

    /**
     * @description This function used to redirect to audit details page.
     * @param selectedAuditData is the selected audit data.
     * @param page is the page selected by user.
     */
    redirectToDetails = (selectedAuditData, page) => {
        setData('auditName', selectedAuditData.audit_name);
        setData('plantName', selectedAuditData.plant_name);
        const plantName = selectedAuditData.plant_name;
        const auditId = selectedAuditData.audit_id;
        const plantDetails = {
            plant_id: selectedAuditData.plant_id,
            plant_director: selectedAuditData.plant_director,
            plant_code: selectedAuditData.plant_code,
            plant_name: selectedAuditData.plant_name,
            isVlm: selectedAuditData.vlm
        };
        setData('plantDetails', plantDetails);
        const route = ROUTES.AUDITDETAILS.replace(
            ':plantName/:auditId',
            encryptData(plantName) + '/' + auditId
        );
        if (!!route) {
            setData('auditData', selectedAuditData);
            this.props.history.push({
                pathname: route,
                state: {
                    page: page,
                    selectedAuditData: selectedAuditData
                }
            });
        }
    };

    /**
     * @description This function used to redirect to update task page.
     * @param selectedTaskData is the task data which need to be update.
     * @param page is the page selected by user.
     */
    redirectToTaskDetails = (selectedTaskData, page) => {
        setData('auditName', selectedTaskData.audit_name);
        setData('plantName', selectedTaskData.plant_name);
        if (page === TAB_TYPES['My_Task']) {
            const taskid = selectedTaskData.task_id;
            const route = ROUTES.MYTASK_UPDATETASK.replace(
                ':plantName/:taskId',
                encryptData(null) + '/' + taskid
            );
            if (!!route) {
                setData('taskData', selectedTaskData);
                this.props.history.push({
                    pathname: route,
                    state: {
                        page: page,
                        selectedTaskData: selectedTaskData
                    }
                });
            }
        } else {
            const plantName = this.props.location.state.plantName;
            const taskid = selectedTaskData.task_id;
            const route = ROUTES.MYTASK_UPDATETASK.replace(
                ':plantName/:taskId',
                encryptData(plantName) + '/' + taskid
            );
            if (!!route) {
                setData('taskData', selectedTaskData);
                this.props.history.push({
                    pathname: route,
                    state: {
                        page: page,
                        selectedTaskData: selectedTaskData,
                        plantDirector: this.props.location.state.plantDirector,
                        plantName: plantName
                    }
                });
            }
        }
    };

    /**
     * @description This function used to redirect to update task page in case of reviewer.
     * @param reviewerTaskList is the task data which need to be update.
     */
    reviewTask = (reviewerTaskList) => {
        setData('auditName', reviewerTaskList.audit_name);
        setData('plantName', reviewerTaskList.plant_name);
        const taskid = reviewerTaskList.task_id;
        const route =
            this.state.userRole === 'reviewer'
                ? ROUTES.REVIEWER_MYTASK_UPDATETASK.replace(':taskId', taskid)
                : ROUTES.AUDITOR_MYTASK_UPDATETASK.replace(':taskId', taskid);
        if (!!route) {
            this.props.history.push({
                pathname: route,
                state: {
                    page: 2,
                    selectedTaskData: reviewerTaskList
                }
            });
        }
    };

    /**
     * @description This function hande the end date and sets value to state.
     * @returns Changes in state values i.e. toDate
     */
    handleToDate = (event) => {
        this.setState({
            toDate: event
        });
    };

    /**
     * @description This function used to handle the asset and sets value to state and call the function to get the question.
     * @returns Changes in state values
     */
    handleAssetType = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value
            },
            () => {
                this.getQuestion();
            }
        );
    };

    /**
     * @description This function used to filter the data by audit manager.
     * @param name Name that will be use to filter
     * @param filterArr Filter array
     */
    filterDataBYAM = (name, filterArr) => {
        let plantList = [],
            plantNameArr = [];
        let filterData = filterArr.filter((data) => data.created_by === name);
        let data = name ? filterData : filterArr;
        let { minDate, maxDate } = getMinMaxDate(data);
        data.map((obj) => {
            let { plant_name, created_by } = obj;
            if (!plantNameArr.includes(plant_name)) {
                if (!!plant_name) {
                    plantList.push(obj);
                    plantNameArr.push(plant_name);
                }
            }
        });
        this.setState({
            plantList: sortArrObj(plantList, 'plant_name'),
            minDate,
            maxDate,
            plantName: '',
            filteredDateList: filterData
        });
        return name ? filterData : filterArr;
    };

    /**
     * @description This function used to filter the data on the basis of name.
     * @param name Name that will be use to filter
     * @param filterArr Filter array
     */
    filterDataByName = (name, filterArr) => {
        let filterData = filterArr.filter((data) => data.plant_name === name);
        return name ? filterData : filterArr;
    };

    /**
     * @description This function used to filter the data on the basis of status.
     * @param status Status that will be use to filter.
     */
    filterDataByStatus = (status) => {
        const { userRole, userTaskList, auditorTaskList, reviewerTaskList, auditList } = this.state;
        if (status == '') {
            return userRole == 'audit manager' && getData('checkPage') == 2
                ? userTaskList
                : userRole == 'audit manager'
                ? auditList
                : userRole == 'auditor'
                ? auditorTaskList
                : reviewerTaskList;
        }
        if (userRole == 'audit manager') {
            let dataArr = getData('checkPage') == 2 ? userTaskList : auditList;
            return dataArr.filter(
                (data) =>
                    (status == 1 && data.task_status == 1) ||
                    (status == 2 && data.task_status == 2) ||
                    (status == 3 && data.task_status == 3)
            );
        }
        if (userRole == 'auditor') {
            return auditorTaskList.filter(
                (data) =>
                    (status == 3 && data.task_status == 3) || (status != 3 && data.task_status != 3)
            );
        }
        if (userRole == 'reviewer') {
            return reviewerTaskList.filter(
                (data) =>
                    (status == 3 && data.task_status == 3) || (status != 3 && data.task_status != 3)
            );
        }
    };

    /**
     * @description This function handle the input change and sets the state basis of name.
     * @returns Changes in state values.
     */
    handleChange = (event) => {
        let { name, value } = event.target;
        let { AM_name, filterStatus, plantName } = this.state;
        let filteredDateList = this.filterDataByStatus(
            name === 'filterStatus' ? value : filterStatus
        );
        filteredDateList = this.filterDataBYAM(
            name === 'AM_name' ? value : AM_name,
            filteredDateList
        );
        if (name !== 'AM_name' || value == '') {
            filteredDateList = this.filterDataByName(
                name === 'plantName' ? value : plantName,
                filteredDateList
            );
        }
        this.setState({
            filteredDateList,
            [name]: value
        });
    };

    /**
     * @description This function used to apply filter on data audit manager name and plant name and sets the state.
     */
    applyFilter = () => {
        let { plantName, AM_name, filterStatus, fromDate, toDate } = this.state;
        let filteredDateList = this.filterDataByStatus(filterStatus);
        filteredDateList = this.filterDataBYAM(AM_name, filteredDateList);
        filteredDateList = this.filterDataByName(plantName, filteredDateList);
        if (fromDate) {
            filteredDateList = filteredDateList.filter(
                (record) => new Date(record.start_date) >= new Date(fromDate)
            );
        }
        if (toDate) {
            filteredDateList = filteredDateList.filter(
                (record) =>
                    new Date(record.start_date) <=
                    new Date(new Date(toDate).setDate(new Date(toDate).getDate() + 1))
            );
        }
        this.setState({ filteredDateList });
    };

    /**
     * @description This function used to handle the start date and sets to state.
     * @param event event is start date.
     * @returns Changes in state values i.e. fromDate
     */
    handleFromDate = (event) => {
        this.setState(
            {
                fromDate: event
            },
            () => {
                if (this.state.toDate < this.state.fromDate) {
                    this.setState({
                        toDate: null
                    });
                }
            }
        );
    };

    /**
     * @description This function used to handle the end date and sets to state.
     * @param event Event is end date.
     * @returns Changes in state values i.e. toDate
     */
    handleToDate = (event) => {
        this.setState({
            toDate: event
        });
    };

    /**
     * @description This function handle the delete audit model.
     * @param currentAudit Current audit which need to be deleted.
     * @returns Changes in state values i.e. current audit.
     */
    showHideDelAuditModel = (currentAudit) => {
        let { showDelAuditModel } = this.state;
        this.setState({
            currentAudit,
            showDelAuditModel: !showDelAuditModel
        });
    };

    /**
     * @description This function used to delete the audit.
     */
    deleteAudit = async () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { currentAudit } = this.state;
        let { audit_id, status } = currentAudit;
        let obj = { audit_id, status };
        this.showHideDelAuditModel(null);
        let response = await serverCalls(
            CONFIG.deleteAudit,
            obj,
            lang.STRINGS.DELETINGAUDIT,
            null,
            language
        );
        if (response) {
            this.componentDidMount();
        }
    };

    render() {
        const redirect = { redirectToDetails: this.redirectToDetails };
        const redirecttoTask = { redirectToViewTask: this.redirectToTaskDetails };
        const redirectToReview = { redirectToReviewTask: this.reviewTask };
        const page = this.props.location?.state?.page || getData('checkPage') || 1;
        const {
            filteredDateList,
            userRole,
            plantName,
            AM_name,
            AM_Data,
            filterByStatusArr,
            filterStatus,
            plantList,
            fromDate,
            toDate,
            minDate,
            maxDate,
            showDelAuditModel
        } = this.state;
        const mystyle = {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'absolute',
            fontWeight: 'bold'
        };
        return (
            <main>
                <HeaderComponent
                    history={this.props.history}
                    page={page}
                    onTabChange={this.onTabChange}
                    language={this.props.langData}
                />
                <div className="main-cntnt-wrap " id="existing-audits-summary-wrap">
                    <div className="container">
                        <ol className="breadcrumb"></ol>
                    </div>
                    <div className="filter-container">
                        <div className="form-group">
                            {(page == 3 || page == 4) && (
                                <>
                                    <strong>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.FilterByAM
                                        }
                                    </strong>
                                    <select
                                        name="AM_name"
                                        className={'form-control valid'}
                                        value={AM_name}
                                        onChange={this.handleChange}>
                                        <option value="">
                                            {
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].LABLES.All
                                            }
                                        </option>
                                        {AM_Data.map((data, index) => {
                                            return (
                                                <option key={index} value={data}>
                                                    {data}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </>
                            )}
                            {((userRole == 'audit manager' && page == 2) ||
                                userRole != 'audit manager') && (
                                <>
                                    <strong>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.FilterByStatus
                                        }
                                    </strong>
                                    <select
                                        name="filterStatus"
                                        className={'form-control valid'}
                                        value={filterStatus}
                                        onChange={this.handleChange}>
                                        <option value="">
                                            {
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].LABLES.All
                                            }
                                        </option>
                                        {filterByStatusArr.map((data, index) => {
                                            if (showFilterType(data.name, userRole)) {
                                                return (
                                                    <option key={index} value={data.status}>
                                                        {data.name}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </select>
                                </>
                            )}
                            <strong>
                                {
                                    LANGUAGECONST[this.props?.langData?.langType || 'ENG'].LABLES
                                        .FilterByPlant
                                }
                                :
                            </strong>
                            {plantList ? (
                                <select
                                    name="plantName"
                                    className={'form-control valid'}
                                    value={plantName}
                                    onChange={this.handleChange}
                                    disabled={!filteredDateList.length}>
                                    <option value="">
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.All
                                        }
                                    </option>
                                    {plantList.map((obj, index) => {
                                        return (
                                            <option key={index} value={obj.plant_name}>
                                                {obj.plant_name}
                                            </option>
                                        );
                                    })}
                                </select>
                            ) : null}
                            <strong>
                                {
                                    LANGUAGECONST[this.props?.langData?.langType || 'ENG'].LABLES
                                        .FilterByDate
                                }
                                :
                            </strong>
                            <div className="date-range-group">
                                <DatePicker
                                    name="fromDate"
                                    placeholderText={
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.SelectDate
                                    }
                                    autoComplete="Off"
                                    value={fromDate}
                                    className="form-control"
                                    dateFormat="MM/dd/yyyy"
                                    selected={fromDate}
                                    minDate={new Date(minDate)}
                                    maxDate={new Date(maxDate)}
                                    onChange={(event) => this.handleFromDate(event)}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    disabled={!filteredDateList.length}
                                    locale={this.props?.langData?.language || 'en'}
                                />
                                <DatePicker
                                    name="toDate"
                                    placeholderText={
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.SelectDate
                                    }
                                    autoComplete="Off"
                                    value={toDate}
                                    className="form-control"
                                    dateFormat="MM/dd/yyyy"
                                    selected={toDate}
                                    minDate={fromDate}
                                    maxDate={new Date(maxDate)}
                                    onChange={(event) => this.handleToDate(event)}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    disabled={!filteredDateList.length || !fromDate}
                                    locale={this.props?.langData?.language || 'en'}
                                />
                            </div>
                            <button
                                type="button"
                                onClick={() => this.applyFilter()}
                                disabled={!filteredDateList.length}
                                className="btn-blue">
                                {
                                    LANGUAGECONST[this.props?.langData?.langType || 'ENG'].LABLES
                                        .Apply
                                }
                            </button>
                            {userRole === USER_ROLES['audit manager'] &&
                            page === TAB_TYPES['Draft'] ? (
                                <button
                                    className="btn-prmry"
                                    type="button"
                                    onClick={this.openCreateAuditModal}>
                                    {' '}
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.CreateAudit
                                    }
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="existing-audits-summary-outer">
                        <div className="container">
                            <div className="form-row task-row">
                                {/* iterate summary */}
                                {userRole === USER_ROLES['audit manager'] ? (
                                    page !== TAB_TYPES['My_Task'] ? (
                                        filteredDateList.length ? (
                                            filteredDateList.map((data, index) => (
                                                <Summary
                                                    auditListData={data}
                                                    page={page}
                                                    deleteAudit={this.showHideDelAuditModel}
                                                    actions={redirect}
                                                    key={index}
                                                    prevData={this.props.location.state}
                                                    langData={this.props?.langData}
                                                />
                                            ))
                                        ) : (
                                            <div style={mystyle}>
                                                <ul>
                                                    <li>
                                                        {
                                                            LANGUAGECONST[
                                                                this.props?.langData?.langType ||
                                                                    'ENG'
                                                            ].LABLES.NoAuditsAvailable
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    ) : filteredDateList.length ? (
                                        filteredDateList.map((data, index) => {
                                            return (
                                                <Summary
                                                    userListData={data}
                                                    page={page}
                                                    actions={redirecttoTask}
                                                    key={index}
                                                    langData={this.props?.langData}
                                                />
                                            );
                                        })
                                    ) : (
                                        <div style={mystyle}>
                                            <ul>
                                                <li>
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.NoTasksAvailable
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                ) : userRole === 'reviewer' ? (
                                    filteredDateList.length ? (
                                        filteredDateList.map((data, index) => (
                                            <Summary
                                                reviewerTaskList={data}
                                                actions={redirectToReview}
                                                key={index}
                                                langData={this.props?.langData}
                                            />
                                        ))
                                    ) : (
                                        <div style={mystyle}>
                                            <ul>
                                                <li>
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.NoTasksAvailable
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                ) : filteredDateList.length ? (
                                    filteredDateList.map((data, index) => (
                                        <Summary
                                            auditorTaskList={data}
                                            actions={redirectToReview}
                                            key={index}
                                            langData={this.props?.langData}
                                        />
                                    ))
                                ) : (
                                    <div style={mystyle}>
                                        <ul>
                                            <li>
                                                {
                                                    LANGUAGECONST[
                                                        this.props?.langData?.langType || 'ENG'
                                                    ].LABLES.NoTasksAvailable
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                {/* end iterate summary */}
                            </div>
                        </div>
                    </div>
                </div>
                <CommonModal
                    showModel={showDelAuditModel}
                    showCancel={true}
                    cancelAction={() => this.showHideDelAuditModel(null)}
                    showOk={true}
                    okAction={this.deleteAudit}
                    msg={
                        LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS
                            .ConfirmAuditDelete
                    }
                    langData={this.props?.langData}
                />
                {this.state.isModalOpen ? (
                    <CreateAuditModal
                        isModalOpen={this.state.isModalOpen}
                        actions={{
                            closeCreateAuditModal: this.closeCreateAuditModal
                        }}
                        history={this.props.history}
                        plantList={this.props.plantList}
                        langData={this.props?.langData}
                    />
                ) : (
                    ''
                )}
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    const data = state.data;
    return data;
};

export default connect(mapStateToProps)(AuditSummaryComponent);
