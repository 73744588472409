/**
 * @author Rehan Rizvi
 * @file MyTaskCompForPlant
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import HeaderComponent from '../Common/HeaderComponent';
import Summary from '../AuditSummaryComponent/Summary';
import { setData, getData } from '../../shared/utils/storage.util';
import { getMinMaxDate, showFilterType } from '../../shared/utils/helper.util';
import { LABLES } from '../../shared/constants/data';
import { TASK_DATA_ARR } from '../../redux/actions/common_action_types';
import { getPostDataWithType } from '../../redux/actions/common_action';
import ROUTES from '../../shared/constants/routes';
import CONFIG from '../../configuration';
import LANGUAGECONST from '../../shared/constants/language';
import { languageData } from '../../redux/actions/languageAction';

class MyTaskCompForPlant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskDataArr: [],
            filterData: [],
            plantList: [],
            plantName: '',
            fromDate: null,
            toDate: null,
            filterStatus: getData('loginData')?.userData?.role_name == 'plant auditor' ? 1 : 2,
            minDate: null,
            maxDate: null,
            filterByStatusArr: [
                { name: 'In Progress', status: 1 },
                { name: 'In Review', status: 2 },
                { name: 'Completed', status: 3 }
            ],
            userData: getData('loginData').userData
        };
    }

    /**
     * @description This function will call once the the component load to fetch the tasks.
     */
    componentDidMount = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { userData } = this.state;
        let { user_id, plant_id } = userData;
        const data = { user_id, plant_id };
        this.props.getTaskData(
            CONFIG.useridTaskList,
            data,
            TASK_DATA_ARR,
            lang.STRINGS.FetchingTasks,
            language
        );
    };

    /**
     * @description This function handle the updated data and call the functions.
     * @param nextProps This is updated props.
     */
    componentWillReceiveProps = (nextProps) => {
        let { taskDataArr } = nextProps;
        this.setState(
            {
                taskDataArr: taskDataArr || []
            },
            () => {
                this.applyFilter();
                this.handlePlantList(taskDataArr || []);
            }
        );
    };

    /**
     * @description This function used to handle the plant list.
     * @param data Plant data
     * @returns Changes in state values i.e. plant list, min date and max date.
     */
    handlePlantList = (data) => {
        let plantList = [],
            plantNameArr = [];
        let { minDate, maxDate } = getMinMaxDate(data);
        data.map((obj) => {
            let { plant_name } = obj;
            if (!plantNameArr.includes(plant_name)) {
                plantList.push(obj);
                plantNameArr.push(plant_name);
            }
        });
        this.setState({ plantList, minDate, maxDate });
    };

    /**
     * @description This is used to handle the tab change and set the values in state.
     */
    onTabChange = () => {
        this.setState({
            fromDate: null,
            toDate: null,
            plantName: ''
        });
    };

    /**
     * @description This function used to filter the task by plant name.
     * @param plant_name Plant name used to filter.
     */
    _getTaskByPlantName(plant_name) {
        const { userTaskList, auditList } = this.state;
        if (getData('checkPage') == 2) {
            if (plant_name === '') {
                return userTaskList;
            }
            return userTaskList.filter((taskList) => taskList.plant_name === plant_name);
        } else {
            if (plant_name === '') {
                return auditList;
            }
            return auditList.filter((item) => item.plant_name === plant_name);
        }
    }

    /**
     * @description This function used to filter the data bassed on date.
     */
    fromDate = () => {
        let { plantName } = this.state;
        let filteredDateList = plantName
            ? this._getTaskByPlantName(plantName)
            : getData('checkPage') !== 2
            ? this.state.auditList
            : this.state.userTaskList;
        if (this.state.fromDate) {
            filteredDateList = filteredDateList.filter(
                (record) => record.start_date > this.state.fromDate
            );
        }
        if (this.state.toDate) {
            filteredDateList = filteredDateList.filter(
                (record) => record.start_date < this.state.toDate
            );
        }
        this.setState({ filteredDateList });
    };

    /**
     * @description This function handle the input change and set the state.
     */
    handleChange = (event) => {
        let { name, value } = event.target;
        let { filterStatus, plantName } = this.state;
        let filterData = this.filterDataByStatus(name === 'filterStatus' ? value : filterStatus);
        filterData = this.filterDataByName(name === 'plantName' ? value : plantName, filterData);
        this.setState({
            filterData,
            [name]: value
        });
    };

    /**
     * @description This is used to handle the date.
     * @param name Name of the state variable in which value is to be set
     * @param date Date value to set
     */
    handleDate = (name, date) => {
        this.setState({ [name]: date });
        if (name === 'fromDate') {
            if (this.state.toDate < this.state.fromDate) {
                this.setState({
                    toDate: null
                });
            }
        }
    };

    /**
     * @description This function used to filter data by task status.
     * @param status Status of the task.
     */
    filterDataByStatus = (status) => {
        let { taskDataArr } = this.state;
        let filterData = taskDataArr.filter(
            (data) =>
                (status == 1 && data.task_status == 1) ||
                (status == 2 && data.task_status == 2) ||
                (status == 3 && data.task_status == 3)
        );
        return status ? filterData : taskDataArr;
    };

    /**
     * @description This function used to filter data based on name.
     * @param name Name that will use to filter data.
     * @param filterArr Filter data array
     */
    filterDataByName = (name, filterArr) => {
        let filterData = filterArr.filter((data) => data.plant_name === name);
        return name ? filterData : filterArr;
    };

    /**
     * @description This function apply the filter on task data array.
     */
    applyFilter = () => {
        let { plantName, filterStatus, fromDate, toDate } = this.state;
        let filterData = this.filterDataByStatus(filterStatus);
        filterData = this.filterDataByName(plantName, filterData);
        if (fromDate) {
            filterData = filterData.filter(
                (record) => new Date(record.start_date) >= new Date(fromDate)
            );
        }
        if (this.state.toDate) {
            filterData = filterData.filter(
                (record) =>
                    new Date(record.start_date) <=
                    new Date(new Date(toDate).setDate(new Date(toDate).getDate() + 1))
            );
        }
        this.setState({ filterData });
    };

    /**
     * @description This is used to redirect the route to view task.
     * @param taskData Task data that will be shown on view task screen
     */
    goToViewTask = (taskData) => {
        setData('auditName', taskData.audit_name);
        setData('plantName', taskData.plant_name);
        const { task_id } = taskData;
        let route = ROUTES.PLA_MYTASK_UPDATETASK.replace(':taskId', task_id);
        this.props.history.push(route, { selectedTaskData: taskData });
    };

    render() {
        const redirectToReview = { redirectToReviewTask: this.goToViewTask };
        const {
            filterData,
            plantList,
            plantName,
            filterByStatusArr,
            filterStatus,
            fromDate,
            toDate,
            minDate,
            maxDate,
            userData
        } = this.state;
        let userRole = userData?.role_name;
        const mystyle = {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'absolute',
            fontWeight: 'bold'
        };
        return (
            <div>
                <HeaderComponent
                    history={this.props.history}
                    page={2}
                    onTabChange={this.onTabChange}
                    language={this.props?.data?.langData}
                />
                <div className="main-cntnt-wrap " id="existing-audits-summary-wrap">
                    <div className="container">
                        <ol className="breadcrumb"></ol>
                    </div>
                    <div className="filter-container">
                        <div className="form-group">
                            <strong>
                                {
                                    LANGUAGECONST[this.props?.data?.langData?.langType || 'ENG']
                                        .LABLES.FilterByStatus
                                }
                                :
                            </strong>
                            <select
                                name="filterStatus"
                                className={'form-control valid'}
                                value={filterStatus}
                                onChange={this.handleChange}>
                                <option value="">
                                    {
                                        LANGUAGECONST[this.props?.data?.langData?.langType || 'ENG']
                                            .LABLES.All
                                    }
                                </option>
                                {filterByStatusArr.map((data, index) => {
                                    if (showFilterType(data.name, userRole)) {
                                        return (
                                            <option key={index} value={data.status}>
                                                {data.name}
                                            </option>
                                        );
                                    }
                                })}
                            </select>
                            <strong>
                                {
                                    LANGUAGECONST[this.props?.data?.langData?.langType || 'ENG']
                                        .LABLES.FilterByPlant
                                }
                                :
                            </strong>
                            {plantList ? (
                                <select
                                    name="plantName"
                                    className="form-control valid"
                                    value={plantName}
                                    onChange={this.handleChange}
                                    disabled={!filterData.length}>
                                    {/* <option disabled>Select Plant Name</option> */}
                                    <option value="">
                                        {
                                            LANGUAGECONST[
                                                this.props?.data?.langData?.langType || 'ENG'
                                            ].LABLES.All
                                        }
                                    </option>
                                    {plantList.map((obj, index) => {
                                        return (
                                            <option key={index} value={obj.plant_name}>
                                                {obj.plant_name}
                                            </option>
                                        );
                                    })}
                                </select>
                            ) : null}
                            <strong>{LABLES.Filters}:</strong>
                            <div className="date-range-group">
                                <DatePicker
                                    name="fromDate"
                                    placeholderText={
                                        LANGUAGECONST[this.props?.data?.langData?.langType || 'ENG']
                                            .LABLES.SelectDate
                                    }
                                    selected={fromDate}
                                    className="form-control"
                                    dateFormat="MM/dd/yyyy"
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    onChange={(date) => this.handleDate('fromDate', date)}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    disabled={!filterData.length}
                                    locale={this.props?.langData?.language || 'en'}
                                />
                                <DatePicker
                                    name="toDate"
                                    placeholderText={
                                        LANGUAGECONST[this.props?.data?.langData?.langType || 'ENG']
                                            .LABLES.SelectDate
                                    }
                                    selected={toDate}
                                    className="form-control"
                                    dateFormat="MM/dd/yyyy"
                                    minDate={fromDate}
                                    maxDate={maxDate}
                                    onChange={(date) => this.handleDate('toDate', date)}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    disabled={!filterData.length || !fromDate}
                                    locale={this.props?.langData?.language || 'en'}
                                />
                            </div>
                            <button
                                type="button"
                                onClick={this.applyFilter}
                                disabled={!filterData.length}
                                className="btn-blue">
                                {
                                    LANGUAGECONST[this.props?.data?.langData?.langType || 'ENG']
                                        .LABLES.Apply
                                }
                            </button>
                        </div>
                    </div>
                    <div className="existing-audits-summary-outer">
                        <div className="container">
                            <div className="form-row task-row">
                                {filterData && filterData.length ? (
                                    filterData.map((data, index) => (
                                        <Summary
                                            reviewerTaskList={data}
                                            showReAssignButton={false}
                                            actions={redirectToReview}
                                            key={index}
                                            langData={this.props?.data?.langData}
                                        />
                                    ))
                                ) : (
                                    <div style={mystyle}>
                                        <ul>
                                            <li>
                                                {
                                                    LANGUAGECONST[
                                                        this.props?.data?.langData?.langType ||
                                                            'ENG'
                                                    ].LABLES.NoTasksAvailable
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        taskDataArr: state.common.get('taskDataArr'),
        data: state.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTaskData: (url, data, type, loaderMsg, language = 'en') =>
            dispatch(getPostDataWithType(url, data, type, loaderMsg, null, language))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTaskCompForPlant);
