/**
 * @author Akshay Kumar Singh
 * @file Question Modal
 * @flow
 */
import React from 'react';
import Modal from 'react-responsive-modal';
import AlertModal from './AlertModal';
import ValidationHandler from '../utils/decisionMaker.util';
import LANGUAGECONST from '../constants/language';
import AuditWriteBackModal from './AuditWriteBackModal';
import CONFIG from '../../configuration';
import { getData } from '../utils/storage.util';
import Axios from 'axios';
import { logoutUnauthorizedUser } from '../utils/auth.util';
import { spinnerService } from '../services/spinner.service';
import AuditWriteBack from '../../components/AuditDetailsComponent/AuditWriteBack';

const optionMap = {
    1: [1, 2],
    2: [3, 4],
    3: [5, 6],
    4: [7, 8],
    5: [9, 'a']
};

class SubQuestionModal extends React.Component {
    constructor(props) {
        super(props);
        //  console.log("123",this.props.questionList.defaultSubQues)
        this.state = {
            validation: '',
            questionList: this.props.questionList.subQues,
            quesData: this.props.questionList,
            isInspected: 0,
            isApplicable: 0,
            applicable: 'EDIT',
            applicableData: [],
            isEditModal: false,
            isNotAccessed: null,
            applicableData: [],
            fourthQuestionStatus:
                typeof this.props.questionList.defaultSubQues === 'undefined'
                    ? null
                    : this.props.questionList.defaultSubQues
                    ? this.props.questionList.subQues[7].answer
                    : this.props.questionList.subQues[3].answer
        };
    }

    componentDidMount() {
        let { isApplicable } = this.state;
        if (!!this.props.questionList.isApplicable) {
            this.setState(
                {
                    isApplicable: isApplicable == 0 ? 1 : 0
                },
                () => {
                    let element = document.getElementById('btn-1').classList;
                    element.toggle('active');
                }
            );
        }
        this.getWbQuesApi();
        // if (!!this.props.questionList.isInspected) {
        //   this.setState({
        //     isInspected: (isInspected == 0) ? 1 : 0
        //   }, () => {
        //     let element = document.getElementById("btn-2").classList;
        //     element.toggle('active');
        //   });
        // }
    }

    getWbQuesApi = async () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = lang?.langData?.language || 'en';
        const config = {
            headers: {
                authorization: `Bearer ${getData('token')}`,
                language: language
            }
        };
        let data = {
            audit_id: this.props.taskInfo?.audit_id,
            task_id: this.props.taskInfo?.task_id,
            ques_id: this.props.questionList?.ques_id
        };
        spinnerService.show('Oval', lang.STRINGS.UploadingImage);
        let response = await Axios.post(CONFIG.getWBQues, data, config);
        spinnerService.hide('Oval');
        if (response && response.data && response.data.responseCode == 200) {
            this.setState({ applicableData: response?.data?.response });
        } else if (response && response.data && response.data.responseCode == 401) {
            logoutUnauthorizedUser(this, response.data.message);
        } else {
        }
    };

    /**
     * @description This function set value of the questions of sub-questions.
     * @param parentId It has the id of its parent id from which we determine which sub-question is its parent question
     * @param childValue It has value of the child question of sb question.
     * @param childAns It has boolean value which determines wheather sub-questions has child question or not
     */
    handleChange = (event, parentId, childValue, childAns = true) => {
        let { id, name, value } = event.target;
        let { questionList } = this.state;
        const { tabName } = this.props;

        const questionFour = this.props.questionList.defaultSubQues ? '7' : '3';
        if (parentId) {
            if (childAns) {
                count[childValue] = 0;
                const index = questionList.findIndex((data) => data.sno === parentId);
                const valueToSet = childValue === 'No' ? '2' : '1';
                questionList[index][name] = childValue;
                this.setState({
                    finalValue: valueToSet
                });
            } else {
                questionList[id][name] = childValue;
            }
        } else {
            if (id == 0 && value == 'Yes' && tabName == 'Preventative Maintenance') {
                questionList[id][name] = value;
                questionList[3][name] = value;
                questionList[4][name] = value;
                this.setState({});
            } else if (id == questionFour && tabName == 'Machine Health') {
                this.setState({ fourthQuestionStatus: value });
                questionList[id][name] = value;
            } else {
                questionList[id][name] = value;
            }
        }
        this.setState({
            questionList
        });
    };

    /**
     * @description This function saves value of all the sub questions and deduce the consolidated result of all sub questions of that question.
     */
    onSave = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        const { questionList } = this.state;
        const { tabName, parentQuestionId, onCancel } = this.props;

        let finalValue = '';

        if (tabName == 'Machine Health' && this.props.questionList.defaultSubQues) {
            finalValue += questionList[1]['answer'] === 'Yes' ? '1' : '2';
            finalValue += questionList[5]['answer'] === 'Yes' ? '3' : '4';
            finalValue += questionList[6]['answer'] === 'Yes' ? '5' : '6';
            finalValue += questionList[7]['answer'] === 'Yes' ? '7' : '8';
            finalValue += questionList[8]['answer'] === 'Yes' ? '9' : 'a';
        } else if (tabName == 'Machine Health' && !this.props.questionList.defaultSubQues) {
            finalValue += questionList[0]['answer'] === 'Yes' ? '1' : '2';
            finalValue += questionList[1]['answer'] === 'Yes' ? '3' : '4';
            finalValue += questionList[2]['answer'] === 'Yes' ? '5' : '6';
            finalValue += questionList[3]['answer'] === 'Yes' ? '7' : '8';
            finalValue += questionList[4]['answer'] === 'Yes' ? '9' : 'a';
        }
        //  console.log("testting",finalValue);
        const validation = ValidationHandler.getValidation(
            tabName,
            questionList,
            finalValue,
            this.props.questionList.defaultSubQues
        );
        //  console.log("testin1",validation)
        if (!!validation || validation == 'false' || !!this.props.questionList.isApplicable) {
            this.setState({ validation }, () => {
                onCancel(null, this.state.validation, parentQuestionId);
            });
        } else {
            AlertModal.showAlert(lang.STRINGS.AllFieldsRequired);
        }
    };

    /**
     * @description This function shows child question of sub question.
     */
    showSubQuestion = (parentId) => {
        const { tabName } = this.props;
        switch (tabName) {
            case 'Preventative Maintenance':
                let show = false;
                const { questionList } = this.state;
                questionList.forEach((data) => {
                    if (
                        data.sno === parentId &&
                        (data.answer === 'No' || data.answer === 'Partial')
                    ) {
                        show = true;
                    }
                });
                return !parentId || show;
            default:
                return true;
        }
    };

    toggleBtn = (id, index) => {
        let { taskInfo } = this.props;
        let { isApplicable, isInspected } = this.state;
        if (taskInfo.task_status != 3) {
            if (id === 'btn-1') {
                this.setState(
                    {
                        isApplicable: isApplicable == 0 ? 1 : 0
                    },
                    () => {
                        this.setIsApplicable(id, index);
                    }
                );
            } else {
                this.setState(
                    {
                        isInspected: isInspected == 0 ? 1 : 0
                    },
                    () => {
                        this.setIsInspected(id, index);
                    }
                );
            }
        }
    };

    setIsInspected = (id, index) => {
        let { isInspected } = this.state;
        let element = document.getElementById(id).classList;
        element.toggle('active');
        this.props.isInspected(index, isInspected);
    };
    setIsApplicable = (id, index) => {
        let { isApplicable } = this.state;
        let element = document.getElementById(id).classList;
        element.toggle('active');
        this.props.isApplicable(index, isApplicable);
    };
    setIsModalShow = (value) => {
        this.setState({
            isEditModal: value
        });
    };

    setApplicable = (value) => {
        this.setState({
            applicable: value
        });
    };

    getwbQuesIsApprovedApi = async () => {
        let data;
        let response = await Axios.post(CONFIG.wbQuesIsApproved, data);
        // spinnerService.hide("Oval");
        if (response && response.data && response.data.responseCode == 200) {
            AlertModal.showAlert(response.data.message);
        } else if (response && response.data && response.data.responseCode == 401) {
            logoutUnauthorizedUser(this, response.data.message);
        } else {
            AlertModal.showAlert(response.data.message);
        }
    };

    setApplicableData = (data) => {
        this.setState({
            applicableData: data
        });
    };

    render() {
        const { index, tabName, isModalOpen, onCancel, taskInfo } = this.props;
        const {
            questionList,
            quesData,
            applicableData,
            isApplicable,
            isEditModal,
            applicable,
            isNotAccessed
        } = this.state;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];

        return (
            <Modal
                open={isModalOpen}
                onClose={(evt) => {
                    onCancel(evt);
                }}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                center>
                <div className="pop-up-bg" id="create-audit-popup">
                    <div className="rqst-accs-popup">
                        <div className="create-audit-outer">
                            {/* <div className="top-cntnt">
                 <a href="!#" onClick={(evt) => { onCancel(evt) }}>
                   <img src={require("../../assets/images/cross-icon.png")} alt="cross-icon" />
                 </a>
               </div> */}
                        </div>
                        <div className="toggle-block">
                            <div className="toggle-block-1">
                                <span className="toggle-lable">
                                    {/* {lang.LABLES.NotApplicable} */}
                                </span>
                                <div className="toggle-btn-container-1">
                                    <div
                                        className="toggle-btn"
                                        id="btn-1"
                                        disabled={taskInfo.task_status == 3}
                                        onClick={() => this.toggleBtn('btn-1', index)}>
                                        <div className="inner-circle"></div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="toggle-block-2">
                 <span className="toggle-lable">Not Inspected</span>
                 <div className="toggle-btn-container-2">
                   <div className="toggle-btn" id="btn-2" disabled={taskInfo.task_status == 3} onClick={() => this.toggleBtn("btn-2", index)}>
                     <div className="inner-circle"></div>
                   </div>
                 </div>
               </div> */}
                        </div>

                        {/* <AuditWriteBackModal
              showForm={isEditModal}
              setShowForm={this.setIsModalShow}
              setApplicable={this.setApplicable}
              applicable={applicable}
              lang={lang}
              quesData={quesData[0]}
              taskInfo={taskInfo}
              getWbQuesApi={this.getWbQuesApi}
            /> */}

                        <div className="table-outer tabcontent active popup-outer">
                            {isApplicable ? (
                                <>
                                    <AuditWriteBack
                                        applicableData={applicableData}
                                        setApplicableData={this.setApplicableData}
                                        quesData={quesData}
                                        lang={lang}
                                        taskInfo={taskInfo}
                                        isNotAccessed={isNotAccessed}
                                        onClose={onCancel}
                                        taskType={1}
                                    />
                                </>
                            ) : (
                                <div className="question-form-container">
                                    {questionList?.map((obj, index) => {
                                        if (this.showSubQuestion(obj.parent)) {
                                            return (
                                                <SelectComponent
                                                    key={obj.sno}
                                                    questionFour={this.state.fourthQuestionStatus}
                                                    subQuesType={
                                                        this.props.questionList.defaultSubQues
                                                    }
                                                    tabName={tabName}
                                                    data={obj}
                                                    quesData={questionList}
                                                    questionList={this.props.questionList}
                                                    index={index}
                                                    action={this.handleChange}
                                                    taskInfo={taskInfo}
                                                    langData={this.props?.langData}
                                                />
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                        {!isApplicable && (
                            <>
                                <div className="crt-odi-btn-otr">
                                    <button
                                        className="btn-prmry"
                                        type="button"
                                        onClick={this.onSave}>
                                        {lang.BUTTONS.OK}
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        );
    }
}

const count = {
    Yes: 0,
    No: 0
};

/**
 * @description This function shows sub-questions with their option values.
 * @param props It has values regarding question.
 */
const SelectComponent = (props) => {
    const {
        data,
        tabName,
        action,
        index,
        quesData,
        questionList,
        taskInfo,
        langData,
        questionFour,
        subQuesType
    } = props;

    let lang = LANGUAGECONST[props?.langData?.langType || 'ENG'];
    const __getLabelByValue = (value) => {
        if (Number.isInteger(value)) {
            return value - (2 * data.sno - 1);
        } else {
            return 1;
        }
    };
    /**
     * This function shows options value according to the sub-question data.
     */
    const _renderOptionBySno = () => {
        if (!data.parent) {
            const options = optionMap[data.sno];
            return options?.map((val, indexKey) => (
                <option key={indexKey} value={val}>
                    {' '}
                    {data.option[__getLabelByValue(val)]}
                </option>
            ));
        }
    };

    /**
     * @description This function handles the value of the sub-questions of machine health.
     * @param data It has value of the child of sub questions which helps to determine final value of parent sub-question.
     */
    const handleSubQuestion = (evt, data) => {
        let { name, value } = evt.target;
        const { action, questionList } = props;
        const parentIdValue = questionList.defaultSubQues ? 1 : 5;

        count['Yes'] = 0;
        count['No'] = 0;
        if (value === 'Yes') {
            data[name] = 'Yes';
            action(evt, parentIdValue, 'Yes', false);
        } else if (value === 'No') {
            data[name] = 'No';
            action(evt, parentIdValue, 'No', false);
        }
        quesData.map((data) => {
            if (data.parent == 1) {
                if (data.answer === 'Yes') {
                    count['Yes'] += 1;
                } else if (data.answer === 'No') {
                    count['No'] += 1;
                }
            }
        });

        if (count['Yes'] > 1) {
            action(evt, parentIdValue, 'Yes');
        } else if (count['No'] > 2) {
            action(evt, parentIdValue, 'No');
        }
    };

    switch (tabName) {
        case 'Machine Health':
            return (
                <React.Fragment>
                    {!subQuesType ? (
                        data.sno !== 5 &&
                        data.sno !== 5.1 &&
                        data.sno !== 5.2 &&
                        data.sno !== 5.3 &&
                        data.sno !== 5.4 ? (
                            <React.Fragment>
                                {/* {console.log("questionFourNo",questionFour)} */}
                                <div className="form-group-question">
                                    <label>
                                        {data.sno}. {data.ques}
                                    </label>
                                    <select
                                        className="form-control"
                                        id={index}
                                        value={data.answer ? data.answer : ''}
                                        name="answer"
                                        onChange={action}
                                        disabled={
                                            !!questionList.isApplicable || taskInfo.task_status == 3
                                        }>
                                        <option hidden value="">
                                            {lang.LABLES.SelectOption}
                                        </option>
                                        {
                                            // _renderOptionBySno()
                                            data.option?.map((val, indexKey) => (
                                                <option key={indexKey} value={val}>
                                                    {val}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </React.Fragment>
                        ) : questionFour == 'No' && data.sno === 5 ? (
                            <React.Fragment>
                                {/* {console.log("questionFourYes",questionFour)} */}
                                <label>
                                    {data.sno}. {data.ques} <span>&nbsp;</span>
                                    {data.answer}
                                </label>
                            </React.Fragment>
                        ) : questionFour == 'No' && data.sno !== 5 ? (
                            <React.Fragment>
                                {/* {console.log("questionFourTest",questionFour)}  */}
                                <div className="form-group-question">
                                    <div className="sub-ques">
                                        <label>
                                            {data.sno}. {data.ques}
                                        </label>
                                        <select
                                            className="form-control"
                                            id={index}
                                            value={data.answer ? data.answer : ''}
                                            name="answer"
                                            onChange={(e) => handleSubQuestion(e, data)}
                                            disabled={
                                                !!questionList.isApplicable ||
                                                taskInfo.task_status == 3
                                            }>
                                            <option hidden value="">
                                                {lang.LABLES.SELECT}
                                            </option>
                                            <option value="Yes">{lang.LABLES.Yes}</option>
                                            <option value="No">{lang.LABLES.No}</option>
                                        </select>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : null
                    ) : data.sno === 1 || data.parent === 1 ? (
                        !data.parent ? (
                            <label>
                                {data.sno}. {data.ques} <span>&nbsp;</span>
                                {data.answer}
                            </label>
                        ) : (
                            <React.Fragment>
                                <div className="form-group-question">
                                    <div className="sub-ques">
                                        <label>
                                            {data.sno}. {data.ques}
                                        </label>
                                        <select
                                            className="form-control"
                                            id={index}
                                            value={data.answer ? data.answer : ''}
                                            name="answer"
                                            onChange={(e) => handleSubQuestion(e, data)}
                                            disabled={
                                                !!questionList.isApplicable ||
                                                taskInfo.task_status == 3
                                            }>
                                            <option hidden value="">
                                                {lang.LABLES.SELECT}
                                            </option>
                                            <option value="Yes">{lang.LABLES.Yes}</option>
                                            <option value="No">{lang.LABLES.No}</option>
                                        </select>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    ) : questionFour == 'Yes' && data.sno !== 5 ? (
                        <React.Fragment>
                            <div className="form-group-question">
                                <label>
                                    {data.sno}. {data.ques}
                                </label>
                                <select
                                    className="form-control"
                                    id={index}
                                    value={data.answer ? data.answer : ''}
                                    name="answer"
                                    onChange={action}
                                    disabled={
                                        !!questionList.isApplicable || taskInfo.task_status == 3
                                    }>
                                    <option hidden value="">
                                        {lang.LABLES.SelectOption}
                                    </option>
                                    {
                                        // _renderOptionBySno()
                                        data.option?.map((val, indexKey) => (
                                            <option key={indexKey} value={val}>
                                                {val}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </React.Fragment>
                    ) : questionFour == 'No' ? (
                        <React.Fragment>
                            <div className="form-group-question">
                                <label>
                                    {data.sno}. {data.ques}
                                </label>
                                <select
                                    className="form-control"
                                    id={index}
                                    value={data.answer ? data.answer : ''}
                                    name="answer"
                                    onChange={action}
                                    disabled={
                                        !!questionList.isApplicable || taskInfo.task_status == 3
                                    }>
                                    <option hidden value="">
                                        {lang.LABLES.SelectOption}
                                    </option>
                                    {
                                        // _renderOptionBySno()
                                        data.option?.map((val, indexKey) => (
                                            <option key={indexKey} value={val}>
                                                {val}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            );
        case 'Preventative Maintenance':
            return (
                <React.Fragment>
                    {data.sno == 1 && (
                        <div className="form-group-question">
                            <label>
                                {quesData[0].sno}. {quesData[0].ques}
                            </label>
                            <select
                                className="form-control"
                                id={index}
                                value={data.answer ? data.answer : ''}
                                name="answer"
                                onChange={action}
                                disabled={!!questionList.isApplicable || taskInfo.task_status == 3}>
                                <option value="">{lang.LABLES.SelectOption}</option>
                                {quesData[0]?.option?.map((val, indexKey) => (
                                    <option key={indexKey} value={val}>
                                        {val}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {data.sno != 1 && quesData[0].answer == 'Yes' && (
                        <div className="form-group-question">
                            <label>
                                {data.sno}. {data.ques}
                            </label>
                            <select
                                className="form-control"
                                id={index}
                                value={data.answer ? data.answer : ''}
                                name="answer"
                                defaultValue="Yes"
                                onChange={action}
                                disabled={!!questionList.isApplicable || taskInfo.task_status == 3}>
                                <option value="">{lang.LABLES.SelectOption}</option>
                                {data.option?.map((val, indexKey) => (
                                    <option key={indexKey} value={val}>
                                        {val}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {data.sno != 1 && quesData[0].answer == 'Partial' && (
                        <div className="form-group-question">
                            <label>
                                {data.sno}. {data.ques}
                            </label>
                            <select
                                className="form-control"
                                id={index}
                                value={data.answer ? data.answer : ''}
                                name="answer"
                                onChange={action}
                                disabled={!!questionList.isApplicable || taskInfo.task_status == 3}>
                                <option value="">{lang.LABLES.SelectOption}</option>
                                {data.option?.map((val, indexKey) => (
                                    <option key={indexKey} value={val}>
                                        {val}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {data.sno != 1 &&
                        data.sno != 2 &&
                        data.sno != 3 &&
                        quesData[0].answer == 'No' && (
                            <div className="form-group-question">
                                <label>
                                    {data.sno}. {data.ques}
                                </label>
                                <select
                                    className="form-control"
                                    id={index}
                                    value={data.answer ? data.answer : ''}
                                    name="answer"
                                    onChange={action}
                                    disabled={
                                        !!questionList.isApplicable || taskInfo.task_status == 3
                                    }>
                                    <option value="">{lang.LABLES.SelectOption}</option>
                                    {data.option?.map((val, indexKey) => (
                                        <option key={indexKey} value={val}>
                                            {val}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                </React.Fragment>
            );
        default:
            return (
                <React.Fragment>
                    <div className="form-group-question">
                        <label>
                            {data.sno}. {data.ques}
                        </label>
                        <select
                            className="form-control"
                            id={index}
                            value={data.answer ? data.answer : ''}
                            name="answer"
                            onChange={action}
                            disabled={!!questionList.isApplicable || taskInfo.task_status == 3}>
                            <option value="">{lang.LABLES.SelectOption}</option>
                            {data.option?.map((val, indexKey) => (
                                <option key={indexKey} value={val}>
                                    {val}
                                </option>
                            ))}
                        </select>
                    </div>
                </React.Fragment>
            );
    }
};

export default SubQuestionModal;
