/**
 * @author Rehan Rizvi
 * @file PRPreview Modal
 * @flow
 */
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import LANGUAGECONST from '../constants/language';
/**
 * @description This component shows preview for auditor and reviewer of the Partsroom.
 */
class PreviewModal extends Component {
    render() {
        const { isModalOpen, data, quesData, taskInfo, onClose } = this.props;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <Modal open={isModalOpen} onClose={onClose} center>
                <div className="table-outer tabcontent active preview">
                    <div className="table-responsive">
                        <table className="gen-audt-mch-sts">
                            <thead>
                                <tr>
                                    <th width="15%">{lang.LABLES.ItemDiscrepancy}</th>
                                    {taskInfo.task_status == 2 && (
                                        <>
                                            <th width="10%">{lang.LABLES.Value}</th>
                                            <th width="15%">{lang.LABLES.Validation}</th>
                                        </>
                                    )}
                                    <th width="10%">{lang.LABLES.Image}</th>
                                    <th width="15%">{lang.LABLES.Observation}</th>
                                    <th width="17%">{lang.LABLES.Impact}</th>
                                    <th width="18%">{lang.LABLES.Recommendation}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {item.item_number
                                                    ? item.item_number
                                                    : item.long_ques}
                                            </td>
                                            {taskInfo.task_status == 2 && (
                                                <>
                                                    <td>{item.value ? item.value : ''}</td>
                                                    <td>
                                                        {item.validation ? item.validation : ''}
                                                    </td>
                                                </>
                                            )}
                                            <td>
                                                {item.url
                                                    ? item.url.map((imgData, imgIndex) =>
                                                          !!item.isVideo ? (
                                                              <video
                                                                  width="420"
                                                                  height="440"
                                                                  controls
                                                                  autoPlay>
                                                                  <source
                                                                      src={imgData.img_path}
                                                                      type="video/mp4"
                                                                  />
                                                              </video>
                                                          ) : (
                                                              <img
                                                                  src={imgData.img_path}
                                                                  alt="Images Icons"
                                                                  key={imgIndex}
                                                                  className="upload-images"
                                                              />
                                                          )
                                                      )
                                                    : null}
                                            </td>
                                            <td
                                                style={{
                                                    wordWrap: 'break-word',
                                                    maxWidth: '50px'
                                                }}>
                                                {item.long_heading ===
                                                'PARTS ROOM SAFETY & SECURITY'
                                                    ? item.observation
                                                        ? item.observation
                                                        : ''
                                                    : ''}
                                            </td>
                                            <td
                                                style={{
                                                    wordWrap: 'break-word',
                                                    maxWidth: '50px'
                                                }}>
                                                {item.auditor_comments ? item.auditor_comments : ''}
                                            </td>
                                            <td
                                                style={{
                                                    wordWrap: 'break-word',
                                                    maxWidth: '50px'
                                                }}>
                                                {item.reviewer_comments
                                                    ? item.reviewer_comments
                                                    : ''}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <br />
                <button className="btn-prmry btn-rqst-accs" onClick={onClose}>
                    {lang.BUTTONS.OK}
                </button>
            </Modal>
        );
    }
}

export default PreviewModal;
