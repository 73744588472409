/**
 * @author Rehan Rizvi
 * @file EditAuditTask Component
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import HeaderComponent from './HeaderComponent';
import { USERS_LIST } from '../../redux/actions/common_action_types';
import { getPostDataWithAction, getPostDataWithType } from '../../redux/actions/common_action';
import { getData } from '../../shared/utils/storage.util';
import * as CONSTANT from '../../shared/constants/data';
import CONFIG from '../../configuration';
import ROUTES from '../../shared/constants/routes';
import { getRoleBasedRoute } from '../../shared/utils/helper.util';
import LANGUAGECONST from '../../shared/constants/language';

class EditAuditTaskComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskData: {},
            usersList: [],
            auditorList: [],
            reviewerList: [],
            auditor_id: '',
            reviewer_id: '',
            quesList: []
        };
    }

    /**
     * @description This function sets value to the state variable on the basis of selected task data and call the function to fetch users list.
     * @returns Changes in state values i.e. taskData
     */
    componentDidMount = () => {
        if (this.props.location.state) {
            let { selectedTaskData } = this.props.location.state;
            this.setState({
                taskData: selectedTaskData
            });
            this.getUserListing();
        }
    };

    /**
     * @description This function used to fetch the users list based on role.
     */
    getUserListing = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { userData } = getData('loginData');
        let { role_name, plant_id } = userData;
        let data = userData.role_name === 'audit manager' ? { plant_id, role_name } : { plant_id };
        this.props.getAllUsers(
            CONFIG.userListing,
            data,
            USERS_LIST,
            lang.STRINGS.FETCHINGALLUSERS,
            this,
            language
        );
    };

    /**
     * @description This function sets value to the state variable on the basis of name prop
     * @param nextProps NextProps has the value of updated props.
     */
    componentWillReceiveProps = (nextProps) => {
        let { usersList } = nextProps;
        this.setAuditorReviewerData(usersList || []);
    };

    /**
     * @description This function used to filter out auditor and reviewer from all users and sets the value to state.
     * @param userList List of users
     * @returns Changes in state values i.e. user list, Auditor list and Reviewer list.
     */
    setAuditorReviewerData = (usersList) => {
        let auditorList = usersList.filter(
            (data) => data.role_name == 'plant manager' || data.role_name == 'plant auditor'
        );
        let reviewerList = usersList.filter(
            (data) => data.role_name == 'plant manager' || data.role_name == 'plant reviewer'
        );
        this.setState({ usersList, auditorList, reviewerList });
    };

    /**
     * @description This function sets value to the state variable on the basis of change the input field
     * @returns Changes in state values based on name and value
     */
    handleChange = (event) => {
        let { auditor_id, reviewer_id, taskData } = this.state;
        let { selectedIndex, name, value } = event.target;
        this.setState({
            taskData: {
                ...taskData,
                [name]: value
            },
            auditor_id: name == 'auditor_name' ? event.target[selectedIndex].id : auditor_id,
            reviewer_id: name == 'reviewer_name' ? event.target[selectedIndex].id : reviewer_id
        });
    };

    /**
     * @description This function used to calls the api to update the task details.
     */
    updateTask = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskData, auditor_id, reviewer_id } = this.state;
        let {
            task_id,
            task_name,
            auditor_name,
            reviewer_name,
            line,
            asset,
            ar_reviewer_id,
            ar_reviewer_name,
            plant_manager_id,
            plant_manager_name,
            end_date
        } = taskData;
        let dataObj = {
            task_id,
            task_name,
            auditor_name,
            reviewer_name,
            line,
            asset,
            reviewer_flag: 1,
            plant_manager_id,
            plant_manager_name,
            ar_reviewer_id,
            ar_reviewer_name,
            estimated_audit_date: end_date
        };
        if (auditor_id) {
            dataObj.auditor_id = parseInt(auditor_id);
        }
        if (reviewer_id) {
            dataObj.reviewer_id = parseInt(reviewer_id);
        }
        this.props.updateAuditTask(
            CONFIG.updateTask,
            dataObj,
            ROUTES.PLM_MYTASK,
            lang.STRINGS.UpdatingTask,
            this,
            language
        );
    };

    /**
     * @description This function used to change the route based on tab.
     * @param page Page defined the page number when user click on tab.
     */
    tabChange = (page) => {
        this.props.history.push(getRoleBasedRoute());
    };

    render() {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { taskData, auditorList, reviewerList } = this.state;
        return (
            <div className="main-cntnt-wrap">
                <HeaderComponent
                    history={this.props.history}
                    page={2}
                    onTabChange={this.tabChange}
                    language={this.props.langData}
                />
                <div className="container">
                    <ol className="breadcrumb">
                        <li>
                            <span>
                                <b>
                                    {getData('plantName')} {'\u27f6'}
                                </b>
                            </span>{' '}
                            <b>{getData('auditName')}</b>
                        </li>
                    </ol>
                    <div className="add-task-outer" style={this.state.containerStyle}>
                        <div className="form-cntnt">
                            <div className="form-row">
                                <div className="column-three">
                                    <div className="form-group ">
                                        <label>{lang.LABLES.Auditor}</label>
                                        <select
                                            name="auditor_name"
                                            className="form-control valid"
                                            value={taskData.auditor_name}
                                            onChange={this.handleChange}>
                                            {auditorList.map((data, index) => (
                                                <option
                                                    key={index}
                                                    value={`${data.first_name} ${data.last_name}`}
                                                    id={data.user_id}>
                                                    {`${data.first_name} ${data.last_name}`}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="column-three">
                                    <div className="form-group">
                                        <label>{lang.LABLES.Reviewer}</label>
                                        <select
                                            name="reviewer_name"
                                            className="form-control"
                                            value={taskData.reviewer_name}
                                            onChange={this.handleChange}>
                                            {reviewerList.map((data, index) => (
                                                <option
                                                    key={index}
                                                    value={`${data.first_name} ${data.last_name}`}
                                                    id={data.user_id}>
                                                    {`${data.first_name} ${data.last_name}`}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="column-three">
                                    <div className="form-group">
                                        <label>{lang.LABLES.AuditType}</label>
                                        <select
                                            name="task_name"
                                            className="form-control"
                                            value={taskData.task_name}
                                            disabled
                                            onChange={this.handleChange}>
                                            <option defaultValue hidden>
                                                {taskData.task_name}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="column-three">
                                    <div className="form-group">
                                        <label>{lang.LABLES.Area}</label>
                                        <select
                                            name="line"
                                            className="form-control"
                                            value={taskData.line}
                                            disabled
                                            onChange={this.handleChange}>
                                            <option defaultValue hidden>
                                                {taskData.line}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="column-three">
                                    <div className="form-group">
                                        <label>{lang.LABLES.Asset}</label>
                                        <select
                                            name="asset"
                                            className="form-control"
                                            value={taskData.asset}
                                            disabled
                                            onChange={this.handleChange}>
                                            <option defaultValue hidden>
                                                {taskData.asset}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="column-three">
                                    <div className="form-group">
                                        <label>{lang.LABLES.Activity}</label>
                                        <select
                                            name="type"
                                            className="form-control"
                                            value={taskData.type}
                                            disabled
                                            onChange={this.handleChange}>
                                            <option defaultValue hidden>
                                                {taskData.type}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="column-three">
                                    <div className="form-group ">
                                        <label>{lang.LABLES.EstimateDate}</label>
                                        <DatePicker
                                            name="estimatedDate"
                                            className="form-control"
                                            dateFormat="MM/dd/yyyy"
                                            selected={taskData.end_date}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            disabled
                                            locale={this.props?.langData?.language || 'en'}
                                        />
                                    </div>
                                </div>
                                <div className="column-three"></div>
                            </div>
                            <div className="submition-outer">
                                <button className="btn-prmry" onClick={this.updateTask}>
                                    {lang.LABLES.UpdateTask}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        usersList: state.common.get('usersList'),
        langData: state.data.langData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllUsers: (url, data, type, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithType(url, data, type, loaderMsg, obj, language)),
        updateAuditTask: (url, data, path, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithAction(url, data, path, null, loaderMsg, obj, language))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAuditTaskComp);
