/*
 *
 * define action types
 * */
const actionTypes = {
    LOGINDATA: 'loginData',
    PLANTS_LIST: 'PLANTS_LIST',
    TASKDATA: 'TASKDATA',
    LANGUAGEDATA: 'languageData'
};

export default actionTypes;
