/**
 * @author Akshay Kumar Singh
 * @file DataCard Modal
 * @flow
 */
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import DatePicker from 'react-datepicker';
import { formatDate } from '../utils/helper.util';
import AlertModal from './AlertModal';
import LANGUAGECONST from '../constants/language';

class DataCardModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // WO required fields
            assetNo: '',
            woOrder: '',
            woDesc: '',
            woType: '',
            woTypeArr: [
                { id: 1, value: 'WORK ORDERS - PM' },
                { id: 2, value: 'WORK ORDERS - REPAIR' },
                { id: 3, value: 'WORK ORDERS - REBUILD' },
                { id: 4, value: 'WORK ORDERS - WARRANTY' }
            ],
            startDate: '',
            status: '',
            quantityParts: '',
            costParts: '',
            // PR required fields
            PRObject: {
                item: props.searchVal ? props.searchVal : '',
                item_description: '',
                sub: '',
                on_hand: '',
                org: '',
                lot_number: '',
                locator: ''
            }
        };
    }

    /**
     * @description This function set values of variables for Work order manually added card.
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    /**
     * @description This function sets startDate.
     */
    handleDate = (event) => {
        this.setState({
            startDate: event
        });
    };

    /**
     * @description This function submit work order manually created card details.
     */
    handleProceed = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        const { assetNo, woDesc, woType, startDate, status, quantityParts, costParts } = this.state;
        const { assetTypeId, taskInfo, woOrder } = this.props;
        const { task_id } = taskInfo;
        if (assetTypeId === 1) {
            const WoObj = {
                asset_type_id: assetTypeId,
                task_id: task_id,
                asset_no: assetNo,
                wo_order: woOrder,
                wo_desc: woDesc,
                wo_type: woType,
                start_date: formatDate(startDate),
                status: status,
                quantity_parts: quantityParts,
                cost_parts: costParts
            };
            if (assetNo && woDesc && woType && startDate && status && quantityParts && costParts) {
                this.props.handleManualOrder(WoObj);
            } else {
                AlertModal.showAlert(lang.STRINGS.ALLFIELDREQUIREDPROCEED);
            }
        }
    };

    /**
     * @description This function restrict alphabets and special characters and only allows numbers upto two decimal places.
     */
    restrictToNumber = (event) => {
        let { value } = event.target;
        let numTest = /^\d*\.?\d{0,2}$/;
        if (this.props.assetTypeId == 2) {
            if (numTest.test(value)) {
                this.handlePRChange(event);
            }
        } else {
            if (this.props.assetTypeId == 1) {
                if (numTest.test(value)) {
                    this.handleChange(event);
                }
            }
        }
    };

    /**
     * @description This function set values of variables for partsroom manually added card.
     */
    handlePRChange = (event) => {
        let { PRObject } = this.state;
        let { name, value } = event.target;
        this.setState({
            PRObject: {
                ...PRObject,
                [name]: value
            }
        });
    };

    /**
     * @description This function submit partsroom manually created card details.
     */
    handlePRProceed = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { PRObject } = this.state;
        for (let key in PRObject) {
            if (!PRObject[key]) {
                return AlertModal.showAlert(lang.STRINGS.ALLFIELDREQUIREDPROCEED);
            }
        }
        this.props.addCard(PRObject);
    };

    render() {
        const { isModalOpen, onCancel, assetTypeId, woOrder, taskInfo, searchVal } = this.props;
        const { task_start_date, task_end_date } = taskInfo;
        let { startDate, PRObject, costParts, quantityParts } = this.state;
        let { item_description, sub, org, on_hand, lot_number, locator } = PRObject;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <div>
                {assetTypeId === 1 ? (
                    // Manual item modal for work order
                    <Modal open={isModalOpen} onClose={onCancel} center>
                        <div className="popup-cntnt popuptext">
                            <center>
                                <h3>
                                    {lang.STRINGS.SEARCHEDWO} {woOrder} {lang.STRINGS.ISNOTFOUND}
                                </h3>
                                <br />
                                <b>{lang.STRINGS.STILLWANTTOSUBMIT}</b>
                                <br />
                                <b>{lang.STRINGS.PROVIDEREQUESTEDWODETAILS}</b>
                            </center>
                            <br />
                            <div className="data-card-row">
                                <label>{lang.LABLES.OrderNumber}</label>
                                <div className="from-control-text">{woOrder}</div>
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.AssetNumber}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="assetNo"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.WORKORDERTYPE}</label>
                                <select
                                    className="form-control"
                                    type="text"
                                    name="woType"
                                    defaultValue=""
                                    onChange={this.handleChange}>
                                    <option value="" hidden>
                                        {lang.STRINGS.SELECTWOTYPE}
                                    </option>
                                    {this.state.woTypeArr.map((data, index) => {
                                        return (
                                            <option key={data.id} value={data.value}>
                                                {data.value}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.StartDate}</label>
                                <div className="date-control">
                                    <DatePicker
                                        name="startDate"
                                        className="form-control"
                                        value={startDate}
                                        selected={startDate}
                                        dateFormat="MM/dd/yyyy"
                                        maxDate={Date.parse(task_end_date)}
                                        minDate={Date.parse(task_start_date)}
                                        onChange={(event) => this.handleDate(event)}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="scroll"
                                        locale={this.props?.langData?.language || 'en'}
                                    />
                                </div>
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.Status}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="status"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.QUANTITYPARTS}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="quantityParts"
                                    value={quantityParts}
                                    onChange={this.restrictToNumber}
                                />
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.COSTPARTS}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="costParts"
                                    value={costParts}
                                    onChange={this.restrictToNumber}
                                />
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.WODESCRIPTION}</label>
                                <textarea
                                    className="form-control"
                                    name="woDesc"
                                    onChange={this.handleChange}
                                    placeholder={`${lang.STRINGS.ENTERHERE}...`}></textarea>
                            </div>
                        </div>
                        <div className="btn-container">
                            <button className="btn-prmry btn-rqst-accs" onClick={onCancel}>
                                {lang.BUTTONS.Cancel}
                            </button>
                            <button
                                className="btn-prmry btn-rqst-accs"
                                onClick={() => this.handleProceed()}>
                                {lang.BUTTONS.Proceed}
                            </button>
                        </div>
                    </Modal>
                ) : (
                    // Manual item modal for parts room
                    <Modal open={isModalOpen} onClose={onCancel} center>
                        <div className="popup-cntnt popuptext">
                            <center>
                                <h3>
                                    {lang.STRINGS.SEARCHITEM} {searchVal} {lang.STRINGS.ISNOTFOUND}
                                </h3>
                                <b>{lang.STRINGS.STILLWANTTOSUBMIT}</b>
                                <br />
                                <b>{lang.STRINGS.PROVIDEREQUESTEDITEMDETAILS}</b>
                            </center>
                            <br />
                            <div className="data-card-row">
                                <label>{lang.LABLES.ITEMNUMBER}</label>
                                <div className="from-control-text">{searchVal}</div>
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.SUBINVENTORY}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="sub"
                                    value={sub}
                                    onChange={this.handlePRChange}
                                />
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.ONHANDQUANTITY}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="on_hand"
                                    value={on_hand}
                                    onChange={this.restrictToNumber}
                                />
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.ORGANIZATION}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="org"
                                    value={org}
                                    onChange={this.handlePRChange}
                                />
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.LOTNUMBER}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="lot_number"
                                    value={lot_number}
                                    onChange={this.handlePRChange}
                                />
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.LOCATOR}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="locator"
                                    value={locator}
                                    onChange={this.handlePRChange}
                                />
                            </div>
                        </div>
                        <div className="data-card-row">
                            <label>{lang.LABLES.ITEMDESCRIPTION}</label>
                            <textarea
                                className="form-control"
                                name="item_description"
                                value={item_description}
                                placeholder={`${lang.STRINGS.ENTERHERE}...`}
                                onChange={this.handlePRChange}></textarea>
                        </div>
                        <div className="btn-container">
                            <button className="btn-prmry btn-rqst-accs" onClick={onCancel}>
                                {lang.BUTTONS.Cancel}
                            </button>
                            <button
                                className="btn-prmry btn-rqst-accs"
                                onClick={() => this.handlePRProceed()}>
                                {lang.BUTTONS.Proceed}
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default DataCardModal;
