/**
 * @author Rehan Rizvi
 * @file UpdateTask Component
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderComponent from '../Common/HeaderComponent';
import WOQuestionAnsComponent from './UpdateQuesAns/WorkOrder/WOQuestionAnsComponent';
import PRQuesAnsComponent from './UpdateQuesAns/PartsRoom/PRQuesAnsComponent';
import QuestionAnsBasedComponent from './UpdateQuesAns/QuesAnsBasedOnActivity';
import { formatDate } from '../../shared/utils/helper.util';
import { spinnerService } from '../../shared/services/spinner.service';
import { CallApi } from '../../shared/services/api.service';
import CONFIG from '../../configuration';
import { getAuditId } from '../../shared/utils/helper.util';
import * as CONSTANT from '../../shared/constants/data';
import { getData, setData } from '../../shared/utils/storage.util';
import ROUTES from '../../shared/constants/routes';
import PreviewModal from '../../shared/modals/PreviewModal';
import { getRoleBasedRoute } from '../../shared/utils/helper.util';
import AlertModal from '../../shared/modals/AlertModal';
import LANGUAGECONST from '../../shared/constants/language';

class UpdateTaskComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskInfo: null,
            userAsReviewer: getData('reviewer') ? true : false,
            QuesList: [],
            AdditionalQuesList: [],
            isModalOpen: false,
            previewData: [],
            check: false,
            countObj: null,
            containerStyle: { minHeight: '110px' },
            fieldColor: { color: '#005daa' },
            submitType: ''
        };
    }

    componentWillMount = () => {
        if (this.props.location.state) {
            let { selectedTaskData } = this.props.location.state;
            this.setState(
                {
                    taskInfo: selectedTaskData ? selectedTaskData : null
                },
                () => {
                    this.getQuestionData();
                }
            );
        }
    };

    /**
     * @description This function sets values of questions according to their audit type and pass on the required functions.
     * @returns List of questions in particular task.
     */
    getQuestionComp = () => {
        let { taskInfo, QuesList, AdditionalQuesList, countObj } = this.state;
        let { task_name } = taskInfo ? taskInfo : {};
        return task_name === 'Line Equipment and Injection' ||
            task_name === 'Facilities' ||
            task_name === 'Utilities' ? (
            <QuestionAnsBasedComponent
                taskInfo={taskInfo}
                QuesList={QuesList}
                AdditionalQuesList={AdditionalQuesList}
                submitAns={this.submitAns}
                getQuestionData={this.getQuestionData}
                history={this.props.history}
                langData={this.props?.langData}
                task_name={task_name}
            />
        ) : task_name === 'Work Order Compliance' ? (
            <WOQuestionAnsComponent
                taskInfo={taskInfo}
                QuesList={QuesList}
                getQuestionData={this.getQuestionData}
                history={this.props.history}
                langData={this.props?.langData}
            />
        ) : (
            <PRQuesAnsComponent
                taskInfo={taskInfo}
                QuesList={QuesList}
                countObj={countObj}
                getQuestionData={this.getQuestionData}
                history={this.props.history}
                langData={this.props?.langData}
            />
        );
    };

    /**
     * @description This function retrieve questions of task according to their audit type.
     */
    getQuestionData = async () => {
        if (this.state.submitType !== 'idle') {
            let language = this.props?.langData?.language || 'en';
            let { taskInfo } = this.state;
            const quesObj = {
                asset_type_id: getAuditId(taskInfo.task_name),
                task_id: taskInfo.task_id
            };
            spinnerService.show(
                'Oval',
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchingQuestion
            );
            await CallApi(
                'POST',
                CONFIG.previewList,
                {},
                quesObj,
                '',
                'questionList',
                this,
                language
            );
        }
    };

    /**
     * @description previewList API callback function
     * @param response This is the response structure received from API
     */
    questionList = (response) => {
        let { taskInfo } = this.state;
        let { task_name } = taskInfo ? taskInfo : {};
        if (response.success) {
            if (task_name === 'Parts Room  Inventory Compliance') {
                this.setState({
                    QuesList: response.response.previewData,
                    countObj: response.response.countObj
                });
            } else {
                this.setState({
                    QuesList: response.response
                });
            }
        }
    };

    /**
     * @description This function save and submit data in database
     * @param endpoint Receives endpoint of API to be called
     * @param data Object to be send with the API
     * @param fileBoolean if data has file format
     * @param check to verify which route to be selected
     * @param type to identify its type if it is save & preview or just saved after 5 min being idle or submitted by user
     * @returns Changes in state values i.e. email & password
     */
    submitAns = (endpoint, data, fileBoolean, check, type) => {
        let language = this.props?.langData?.language || 'en';
        this.setState({ check, submitType: type });
        if (type === 'save') {
            spinnerService.show(
                'Oval',
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.SubmittingData
            );
            CallApi('POST', endpoint, {}, data, '', '_displayPreview', this, language);
        } else if (type == 'idle') {
            CallApi('POST', endpoint, {}, data, '', '_displayPreview', this, language);
        } else {
            spinnerService.show(
                'Oval',
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.SubmittingData
            );
            CallApi('POST', endpoint, {}, data, '', 'updatetaskResponse', this, language);
        }
    };

    /**
     * @description This open preview modal in case value of submit type is not idle and
     * show success message in case submit type is idle.
     */
    _displayPreview = async () => {
        await this.getQuestionData();
        if (this.state.submitType !== 'idle') {
            this.setState({ isModalOpen: true, previewData: this.state.QuesList });
        }
        // else {
        //   AlertModal.showAlert(LANGUAGECONST[this.props?.langData?.langType || "ENG"].STRINGS.DataSavedSuccess);
        // }
    };

    /**
     * @description This function closes the preview modal.
     */
    _oncloseModal = () => {
        this.setState({ isModalOpen: false });
    };

    /**
     * @description Update task API callback function
     * @param response This is the response structure received from API
     */
    updatetaskResponse = async (response) => {
        let { check } = this.state;
        if (check) {
            let route = getData('reviewer')
                ? ROUTES.REVIEWER_MYTASK
                : getData('auditor')
                ? ROUTES.AUDITOR_MYTASK
                : getData('plant manager')
                ? ROUTES.PLM_MYTASK
                : getData('plant auditor')
                ? ROUTES.PLA_MYTASK
                : getData('plant reviewer')
                ? ROUTES.PLR_MYTASK
                : ROUTES.DASHBOARD;
            this.props.history.push({
                pathname: route
            });
        }
    };
    /**
     * @description Changes route of page according to its role
     * @param page Identifies currently active tab
     */
    tabChange = (page) => {
        setData('checkPage', page);
        this.props.history.push(getRoleBasedRoute());
    };

    render() {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { taskInfo, isModalOpen, previewData } = this.state;
        let { task_name } = taskInfo ? taskInfo : {};
        return (
            <div className="main-cntnt-wrap">
                <HeaderComponent
                    history={this.props.history}
                    page={this.props.location?.state?.page || 1}
                    onTabChange={this.tabChange}
                    language={this.props?.langData}
                />
                <div className="container">
                    <ol className="breadcrumb"></ol>
                    <div className="add-task-outer" style={this.state.containerStyle}>
                        <form autoComplete="off" onSubmit={this.handleSubmit}>
                            <div className="form-cntnt">
                                <div className="form-row">
                                    <div className="column-three">
                                        <div className="form-group " style={this.state.fieldColor}>
                                            {lang.LABLES.Auditor}:
                                            <label style={{ display: 'unset', marginLeft: '5px' }}>
                                                {taskInfo ? taskInfo.auditor_name : ''}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="column-three">
                                        <div className="form-group " style={this.state.fieldColor}>
                                            {lang.LABLES.Reviewer}:
                                            <label style={{ display: 'unset', marginLeft: '5px' }}>
                                                {taskInfo ? taskInfo.reviewer_name : ''}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="column-three">
                                        <div className="form-group " style={this.state.fieldColor}>
                                            {lang.LABLES.AuditType}:
                                            <label style={{ display: 'unset', marginLeft: '5px' }}>
                                                {taskInfo ? taskInfo.task_name : ''}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="column-three">
                                        <div className="form-group " style={this.state.fieldColor}>
                                            {lang.LABLES.Area}:
                                            <label style={{ display: 'unset', marginLeft: '5px' }}>
                                                {taskInfo ? taskInfo.line : ''}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="column-three">
                                        <div className="form-group " style={this.state.fieldColor}>
                                            {lang.LABLES.Asset}:
                                            <label style={{ display: 'unset', marginLeft: '5px' }}>
                                                {taskInfo ? taskInfo.asset : ''}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="column-three">
                                        <div className="form-group " style={this.state.fieldColor}>
                                            {lang.LABLES.Activity}:
                                            <label style={{ display: 'unset', marginLeft: '5px' }}>
                                                {taskInfo ? taskInfo.type : ''}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="column-three">
                                        <div className="form-group " style={this.state.fieldColor}>
                                            {lang.LABLES.EstimateDate}:
                                            <label style={{ display: 'unset', marginLeft: '5px' }}>
                                                {formatDate(
                                                    taskInfo ? taskInfo.task_estimate_date : ''
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                    {task_name == 'Work Order Compliance' && (
                                        <div className="column-three">
                                            <div
                                                className="form-group "
                                                style={this.state.fieldColor}>
                                                {lang.LABLES.StartDate}:
                                                <label
                                                    style={{ display: 'unset', marginLeft: '5px' }}>
                                                    {taskInfo ? taskInfo.task_start_date : ''}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                    {task_name == 'Work Order Compliance' && (
                                        <div className="column-three">
                                            <div
                                                className="form-group "
                                                style={this.state.fieldColor}>
                                                {lang.LABLES.EndDate}:
                                                <label
                                                    style={{ display: 'unset', marginLeft: '5px' }}>
                                                    {taskInfo ? taskInfo.task_end_date : ''}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    {this.getQuestionComp()}
                </div>
                {isModalOpen ? (
                    <PreviewModal
                        isModalOpen={isModalOpen}
                        previewData={previewData}
                        onCancel={this._oncloseModal}
                        taskInfo={this.state.taskInfo}
                        displayPreview={this._displayPreview}
                        langData={this.props?.langData}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const langData = state.data;
    return langData;
};

export default connect(mapStateToProps, {})(UpdateTaskComp);
