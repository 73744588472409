/**
 * @author Rehan Rizvi
 * @file WOPreview Modal
 * @flow
 */
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import LANGUAGECONST from '../constants/language';
/**
 * @description This component shows preview for auditor and reviewer of the Work order.
 */
class PreviewModal extends Component {
    render() {
        const { isModalOpen, data, quesData, taskInfo, onClose } = this.props;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <Modal open={isModalOpen} onClose={onClose} center>
                <div className="table-outer tabcontent active preview">
                    <div className="table-responsive">
                        <table className="gen-audt-mch-sts">
                            <thead>
                                <tr>
                                    <th width="15%">{lang.LABLES.OrderNumber}</th>
                                    <th width="15%">{lang.LABLES.AssetNumber}</th>
                                    <th width="20%">{lang.LABLES.Discrepancy}</th>
                                    <th width="10%">{lang.LABLES.Image}</th>
                                    <th width="20%">{lang.LABLES.Impact}</th>
                                    {taskInfo.task_status == 2 && (
                                        <th width="20%">{lang.LABLES.Recommendation}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {taskInfo.task_status == 1
                                                    ? item.wo_order
                                                    : item.long_ques}
                                            </td>
                                            <td>{item.asset_no}</td>
                                            <td>{item.validation}</td>
                                            <td>
                                                {item.url
                                                    ? item.url.map((imgData, imgIndex) => (
                                                          <img
                                                              src={imgData.img_path}
                                                              alt="Images Icons"
                                                              key={imgIndex}
                                                              className="upload-images"
                                                          />
                                                      ))
                                                    : null}
                                            </td>
                                            <td
                                                style={{
                                                    wordWrap: 'break-word',
                                                    maxWidth: '50px'
                                                }}>
                                                {item.comments}
                                            </td>
                                            {taskInfo.task_status == 2 && (
                                                <td
                                                    style={{
                                                        wordWrap: 'break-word',
                                                        maxWidth: '50px'
                                                    }}>
                                                    {item.reviewer_comments}
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {quesData && (
                        <div className="table-responsive">
                            <table className="gen-audt-mch-sts">
                                <thead>
                                    <tr>
                                        <th width="20%">{lang.LABLES.Discrepancy}</th>
                                        <th width="10%">{lang.LABLES.Value}</th>
                                        <th width="20%">{lang.LABLES.Validation}</th>
                                        <th width="20%">{lang.LABLES.Impact}</th>
                                        {taskInfo.task_status == 2 && (
                                            <th width="30%">{lang.LABLES.Recommendation}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {quesData.map((item, index) => {
                                        return (
                                            item.long_heading !== 'WORK ORDERS - REFERENCE' && (
                                                <tr key={index}>
                                                    <td>{item.long_ques}</td>
                                                    <td>{item.value}</td>
                                                    <td>{item.validation}</td>
                                                    <td
                                                        style={{
                                                            wordWrap: 'break-word',
                                                            maxWidth: '50px'
                                                        }}>
                                                        {item.auditor_comments}
                                                    </td>
                                                    {taskInfo.task_status == 2 && (
                                                        <td
                                                            style={{
                                                                wordWrap: 'break-word',
                                                                maxWidth: '50px'
                                                            }}>
                                                            {item.reviewer_comments}
                                                        </td>
                                                    )}
                                                </tr>
                                            )
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <br />
                <button className="btn-prmry btn-rqst-accs" onClick={onClose}>
                    {lang.BUTTONS.OK}
                </button>
            </Modal>
        );
    }
}

export default PreviewModal;
