/**
 * @author Rehan Rizvi
 * @file Common Modal
 * @flow
 */
import React from 'react';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import LANGUAGECONST from '../constants/language';

/**
 * This functional component opens the confirmational modal.
 */
export const CommonModal = ({
    showModel,
    msg,
    showCancel,
    cancelAction,
    showOk,
    okAction,
    langData
}) => {
    let lang = LANGUAGECONST[langData?.langType || 'ENG'];
    return (
        <Modal open={showModel} onClose={cancelAction} center>
            <div className="rqst-accs-popup">
                <div className="popup-cntnt popuptext">
                    <p>{msg}</p>
                    <br />
                </div>
                <div className="popup-multi-btn">
                    {showCancel && (
                        <button
                            className="btn-prmry btn-cancel btn-rqst-accs"
                            onClick={cancelAction}>
                            {lang.BUTTONS.Cancel}
                        </button>
                    )}
                    {showOk && (
                        <button className="btn-prmry btn-rqst-accs" onClick={okAction}>
                            {lang.BUTTONS.OK}
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};
