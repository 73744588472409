/**
 * @author Akshay Kumar Singh
 * @file Header Component
 * @flow
 */
import React, { Component } from 'react';
import ROUTES from '../../../shared/constants/routes';
import { LABLES } from '../../../shared/constants/data';
import { getData, setData } from '../../../shared/utils/storage.util';
import { getRoleBasedRoute } from '../../../shared/utils/helper.util';
import { downloadFile } from '../../../redux/actions/common_action';
import { spinnerService } from '../../../shared/services/spinner.service';
import { CallApi } from '../../../shared/services/api.service';
import CONFIG from '../../../configuration';
import AlertModal from '../../../shared/modals/AlertModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LANGUAGECONST from '../../../shared/constants/language';
import * as languageAction from '../../../redux/actions/languageAction';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
registerLocale('es', es);
registerLocale('en', en);
setDefaultLocale('en');

class HeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: 1,
            userName: '',
            userRole: '',
            open: false,
            isShow: false,
            language: 'ENG'
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    /**
     * @description This function sets value to the state variables on the basis of user information and also set these values to localstorage.
     * @returns Set state and localstorage values i.e. username & user role.
     */
    componentDidMount() {
        this.setState(
            {
                isShow: false,
                check: this.props.page,
                userName:
                    getData('loginData').userData.first_name +
                    ' ' +
                    getData('loginData').userData.last_name,
                userRole: getData('loginData').userData.role_name
            },
            () => {
                setData('loggedUser', this.state.userName);
                setData('loggedUserRole', this.state.userRole);
            }
        );
        this.setState(
            {
                language:
                    this.props?.language?.langType || getData('languageData')?.langType || 'ENG'
            },
            () => {
                this.setLanguage();
            }
        );
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * @description This function handle mouse down event.
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * @description This function set the refrence.
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * @description This function sets value to the state variable on the basis of click.
     * @returns Changes in state values i.e. open
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                open: false
            });
        }
    }

    /**
     * @description This function sets value to the state variable on the basis of name prop
     * @param e Event that is used to default prevent.
     * @param componentId It is the value of tabs.
     * @returns Changes in state values i.e. check & isShow
     */
    checkComponent = (e, componentId) => {
        e.preventDefault();
        if (this.props.onTabChange) {
            this.props.onTabChange(componentId);
        }
        this.setState(
            {
                check: componentId,
                isShow: false
            },
            () => {
                this.showComponent();
            }
        );
    };

    /**
     * @description This function use to logout the user.
     */
    logout = (evt) => {
        evt.preventDefault();
        localStorage.removeItem('userData');
        this.props.history.push(ROUTES.LANDING);
    };

    /**
     * @description This function is used to check the component which will be shown to user based on role.
     */
    showComponent = () => {
        if (this.state.userRole == 'audit manager') {
            setData('checkPage', this.state.check);
            switch (this.state.check) {
                case 1:
                    this.props.history.push({
                        pathname: ROUTES.DASHBOARD,
                        state: {
                            page: 1
                        }
                    });
                    break;
                case 2:
                    this.props.history.push({
                        pathname: ROUTES.DASHBOARD,
                        state: {
                            page: 2
                        }
                    });
                    break;
                case 3:
                    this.props.history.push({
                        pathname: ROUTES.DASHBOARD,
                        state: {
                            page: 3
                        }
                    });
                    break;
                case 4:
                    this.props.history.push({
                        pathname: ROUTES.DASHBOARD,
                        state: {
                            page: 4
                        }
                    });
                    break;
                default:
            }
        } else {
            if (this.state.userRole == 'reviewer') {
                setData('checkPage', 2);
                this.props.history.push({
                    pathname: ROUTES.REVIEWER_MYTASK,
                    state: {
                        page: 2
                    }
                });
            }
        }
    };

    /**
     * @description This function used to go to change password page
     */
    changePassword = (e) => {
        e.preventDefault();
        this.props.history.push(ROUTES.CHANGEPASSWORD);
    };

    /**
     * @description This function used to go to website home page based on role.
     */
    goToHome = () => {
        this.props.history.push(getRoleBasedRoute());
    };

    /**
     * @description This function download the FAQ PDF according to user's role.
     */
    downloadFAQ = (event) => {
        let language = this.props?.langData?.language || 'en';
        event.preventDefault();
        const dataObj = {
            roleName: this.state.userRole
        };
        const loaderMsg = LANGUAGECONST[this.state.language].STRINGS.DownloadFAQ;
        spinnerService.show('Oval', loaderMsg);
        CallApi('POST', CONFIG.getFaq, {}, dataObj, '', 'handleFAQResponse', this, language);
    };

    handleFAQResponse = (response) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        if (response.success) {
            const url = response.response.url[0].url;
            const fileName = `${this.state.userRole}_FAQ.pdf`;
            const loaderMsg = lang.STRINGS.DownloadFAQ;
            if (!!url) {
                downloadFile(url, fileName, loaderMsg, false, language);
            } else {
                AlertModal.showAlert(lang.STRINGS.FileMissing);
            }
        }
    };

    handleLanguage = (event) => {
        const { value } = event.target;
        this.setState(
            {
                language: value
            },
            () => {
                this.setLanguage();
            }
        );
    };

    setLanguage = () => {
        const languageObj = {
            language: this.state.language === 'ESP' ? 'es' : 'en',
            langType: this.state.language || 'ENG'
        };
        setData('languageData', languageObj);
        this.props.updateLanguage.languageData(languageObj);
    };

    render() {
        let { check } = this.state;
        const { language } = this.props;
        const lang = language?.langType || this.state.language;
        return (
            <header>
                <div className="login-header">
                    <a href="!#" className="brand-logo" onClick={this.goToHome}>
                        <img
                            src={require('../../../assets/images/niagara-logo-color-2-x.png')}
                            alt=""
                        />
                    </a>
                    <div
                        className="user-info"
                        onClick={() => this.setState({ open: !this.state.open })}
                        open={this.state.open}
                        ref={this.setWrapperRef}>
                        <div>
                            {this.state.userName}
                            <br />
                            <span>{this.state.userRole}</span>
                        </div>
                        {this.state.open ? (
                            <ul>
                                <li>
                                    <a href="" onClick={(ev) => this.downloadFAQ(ev)}>
                                        {LANGUAGECONST[lang].LABLES.DownloadFAQ}
                                    </a>
                                </li>
                                <li>
                                    <a href="!#" onClick={(ev) => this.changePassword(ev)}>
                                        {LANGUAGECONST[lang].LABLES.ChangePassword}
                                    </a>
                                </li>
                                <li onClick={(event) => event.stopPropagation()}>
                                    <span>
                                        {LANGUAGECONST[lang].LABLES.Language}:&nbsp;
                                        <input
                                            type="radio"
                                            value="ENG"
                                            name="language"
                                            onChange={this.handleLanguage}
                                            checked={lang === 'ENG'}
                                        />{' '}
                                        ENG&nbsp;
                                        <input
                                            type="radio"
                                            value="ESP"
                                            name="language"
                                            onChange={this.handleLanguage}
                                            checked={lang === 'ESP'}
                                        />{' '}
                                        ESP
                                    </span>
                                </li>
                                <li>
                                    <a href="!#" onClick={(evt) => this.logout(evt)}>
                                        {LANGUAGECONST[lang].LABLES.LogOut}
                                    </a>
                                </li>
                            </ul>
                        ) : null}
                    </div>
                </div>
                <nav className="menu">
                    <div
                        className="mobile-menu"
                        onClick={() => this.setState({ isShow: !this.state.isShow })}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    {this.state.userRole == 'audit manager' ? (
                        <ul className={this.state.isShow ? 'show-mobile-menu' : ''}>
                            <li className={check === 1 ? 'active' : ''}>
                                <a href="!#" onClick={(e) => this.checkComponent(e, 1)}>
                                    {LANGUAGECONST[lang].LABLES.DraftAudits}
                                </a>
                            </li>
                            <li className={check === 2 ? 'active' : ''}>
                                <a href="!#" onClick={(e) => this.checkComponent(e, 2)}>
                                    {LANGUAGECONST[lang].LABLES.MyTask}
                                </a>
                            </li>
                            <li className={check === 3 ? 'active' : ''}>
                                <a href="!#" onClick={(e) => this.checkComponent(e, 3)}>
                                    {LANGUAGECONST[lang].LABLES.InProgressAudits}
                                </a>
                            </li>
                            <li className={check === 4 ? 'active' : ''}>
                                <a href="!#" onClick={(e) => this.checkComponent(e, 4)}>
                                    {LANGUAGECONST[lang].LABLES.CompletedAudits}
                                </a>
                            </li>
                        </ul>
                    ) : (
                        <ul className={this.state.isShow ? 'show-mobile-menu' : ''}>
                            <li className={'active'}>
                                <a href="!#" onClick={(e) => this.checkComponent(e, 2)}>
                                    {LANGUAGECONST[lang].LABLES.MyTask}
                                </a>
                            </li>
                        </ul>
                    )}
                </nav>
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.data.langData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateLanguage: bindActionCreators(languageAction, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
