/**
 * @author Rehan Rizvi
 * @file QuesList Component
 * @flow
 */
import React, { Component } from 'react';

class QuesListComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            QuesList: [],
            currentTabName: 0,
            heading: [],
            dataBassedOnTab: {},
            PM_headingArr: ['PM Quality Assessment', 'Preventative Maintenance'],
            MH_headingArr: ['General Audit', 'Machine Health'],
            NO_PM_MH_heading: ['General Audit', 'PM Quality Assessment'],
            DV_heading: ['Diagnostic Visit'],
            INJECT_headingArr: ['Preventative Maintenance'],
            ALL_tabs: [
                'General Audit',
                'PM Quality Assessment',
                'Preventative Maintenance',
                'Machine Health'
            ]
        };
    }

    /**
     * @description This function call once the component load at first time and call the function to handle the heading.
     */
    componentDidMount = () => {
        this.getHeading(this.props);
    };

    /**
     * @description This function call when the props update.
     * @param nextProps NextProps is the updated props
     */
    componentWillReceiveProps = (nextProps) => {
        this.getHeading(nextProps);
    };

    /**
     * @description This function used to get the heading sets value to the state variable on the basis of activity and tab.
     * @param updatedProps This is updated props.
     */
    getHeading = (updatedProps) => {
        let { QuesList, assetsType, activity, selectMH, selectDV, lineType, updatedQuesFlag } =
            updatedProps;
        let {
            PM_headingArr,
            MH_headingArr,
            DV_heading,
            NO_PM_MH_heading,
            INJECT_headingArr,
            ALL_tabs
        } = this.state;
        let heading = [],
            currentHeading = [],
            dataBassedOnTab = {};
        QuesList.map((data, index) => {
            if (
                data.activity &&
                !heading.includes(data.long_heading) &&
                data.activity === activity
            ) {
                heading.push(data.long_heading);
            }
            if (!data.activity && !heading.includes(data.long_heading)) {
                heading.push(data.long_heading);
            }
            if (dataBassedOnTab[data.long_heading]) {
                dataBassedOnTab[data.long_heading].push(data);
            } else {
                dataBassedOnTab[data.long_heading] = [data];
            }
        });

        currentHeading =
            assetsType == '3'
                ? activity.includes(' PM') && selectMH
                    ? ALL_tabs
                    : activity.includes(' PM') && !selectMH
                    ? PM_headingArr
                    : activity == 'None' && selectMH
                    ? MH_headingArr
                    : activity == 'None' && selectDV
                    ? DV_heading
                    : !activity.includes(' PM') && !selectMH
                    ? NO_PM_MH_heading
                    : heading
                : heading;
        this.setState(
            {
                heading: currentHeading,
                dataBassedOnTab,
                QuesList,
                currentTabName: currentHeading[0]
            },
            () => {
                if (!updatedQuesFlag) {
                    this.upadateQues();
                }
            }
        );
    };

    /**
     * @description This function used to get updated questions array based on tab.
     */
    upadateQues = () => {
        let { assetsType, activity, selectMH } = this.props;
        let { heading, dataBassedOnTab } = this.state;
        let checkRepeat =
            (assetsType == '3' || assetsType == '4' || assetsType == '5') &&
            (activity.includes(' PM') || selectMH)
                ? true
                : false;
        let updateQuesArr = [];
        for (let key in dataBassedOnTab) {
            if (heading.indexOf(key) > -1) {
                let quesArr =
                    checkRepeat && key === 'Preventative Maintenance'
                        ? this.getUniqueQuesArr(dataBassedOnTab[key])
                        : dataBassedOnTab[key];
                updateQuesArr = updateQuesArr.concat(quesArr);
            }
        }
        if (updateQuesArr.length > 0) {
            this.props.updateQuestionArr(updateQuesArr);
        }
    };

    /**
     * @description This function used to get the unique bassde on activity.
     * @param quesArr This is question array.
     */
    getUniqueQuesArr = (quesArr) => {
        let { activity } = this.props;
        let arr = quesArr.filter((data) => data.activity === activity);
        return arr;
    };

    /**
     * @description This function used to hande the tab change.
     * @param currentTabName Selected tab name.
     */
    tabChange = (currentTabName) => {
        this.setState({ currentTabName });
    };

    /**
     * @description This function updates the current tab value.
     * @param event It has selected event value.
     */
    tabChangeName = (event) => {
        this.setState({ currentTabName: event.target.value });
    };

    /**
     * @description This function used to show the long questions on the basis of heading.
     */
    getLongQues = () => {
        let { currentTabName, heading, dataBassedOnTab } = this.state;
        return (
            <div className="work-order-tabs-outer">
                <div className="work-order-tabs">
                    {heading.map((tab, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn-wrk-ordr  tablinks
                ${currentTabName == tab ? 'active' : ''}`}
                            onClick={() => this.tabChange(tab)}>
                            {tab !== 'WORK ORDERS - PM' ? tab.toLowerCase() : 'Work Orders - PM'}
                        </button>
                    ))}
                </div>
                <select className="tab-select" onClick={(e) => this.tabChangeName(e)}>
                    {heading.map((data, index) => (
                        <option key={index} value={data}>
                            {data !== 'WORK ORDERS - PM' ? data.toLowerCase() : 'Work Orders - PM'}
                        </option>
                    ))}
                </select>
                <div className="table-outer tabcontent active">
                    <ul className="rpair-slctn">
                        {dataBassedOnTab &&
                            dataBassedOnTab[currentTabName] &&
                            dataBassedOnTab[currentTabName].map((data, index) => (
                                <li key={index}>{data.long_ques}</li>
                            ))}
                    </ul>
                </div>
            </div>
        );
    };

    /**
     * @description This function used to show the short and long questions on the basis of heading.
     */
    getShortAndLongQues = () => {
        let { currentTabName, heading, dataBassedOnTab } = this.state;
        let { activity, selectMH } = this.props;
        let checkRepeat = activity.includes(' PM') || selectMH ? true : false;
        return (
            <div className="work-order-tabs-outer blower-tabs-outer">
                <div className="blower-tabs ">
                    {heading.map((tab, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn-blowers gnrl-adt  tablinks
                ${currentTabName == tab ? 'active' : ''}`}
                            onClick={() => this.tabChange(tab)}>
                            {tab}
                        </button>
                    ))}
                </div>
                <select className="tab-select" onClick={(e) => this.tabChangeName(e)}>
                    {heading.map((data, index) => (
                        <option key={index} value={data}>
                            {data}
                        </option>
                    ))}
                </select>
                <div className="table-outer tabcontent active">
                    <div className="table-responsive">
                        <table className="gen-audt-mch-sts">
                            <tbody>
                                <tr>
                                    <th style={{ width: '40%' }}>Location/Summary/Component</th>
                                    <th style={{ width: '60%' }}>Evaluation Criteria</th>
                                </tr>
                                {dataBassedOnTab &&
                                    dataBassedOnTab[currentTabName] &&
                                    dataBassedOnTab[currentTabName].map((data, index) => {
                                        if (
                                            (checkRepeat &&
                                                ((currentTabName === 'Preventative Maintenance' &&
                                                    data.activity == activity) ||
                                                    (currentTabName === 'Machine Health' &&
                                                        selectMH))) ||
                                            !checkRepeat ||
                                            (currentTabName != 'Preventative Maintenance' &&
                                                currentTabName != 'Machine Health')
                                        ) {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.short_ques}</td>
                                                    <td>{data.long_ques}</td>
                                                </tr>
                                            );
                                        }
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        let { assetsType, utility_static_ques } = this.props;
        let { currentTabName } = this.state;
        let showShortAndLongQues = assetsType == '1' || assetsType == '2' ? false : true;
        return (
            <form action="" id="">
                <div className="work-order-wrap">
                    <div className="headiong-outer">
                        <h4>Audit Tasks / Questions</h4>
                    </div>
                    {currentTabName === 'General Utilities' ||
                    (currentTabName === 'General Facilities' &&
                        (utility_static_ques === 'Electrical' ||
                            utility_static_ques === 'Carbon Towers' ||
                            utility_static_ques === 'RO' ||
                            utility_static_ques === 'Boiler' ||
                            utility_static_ques === 'Mineral Skid' ||
                            utility_static_ques === 'Ozone Skid'))
                        ? this.getLongQues()
                        : showShortAndLongQues
                        ? this.getShortAndLongQues()
                        : this.getLongQues()}
                </div>
            </form>
        );
    }
}

export default QuesListComp;
