/**
 * @author Akshay Kumar Singh
 * @file DecisionMaker Util
 * @flow
 */
const MHAnswerMap = {
    '13579': 'Major NC',
    '13589': 'Major NC',
    '13679': 'Major NC',
    '13689': 'Major NC',
    '14579': 'Major NC',
    '14589': 'Minor NC',
    '14679': 'Major NC',
    '14689': 'Major NC',
    '23579': 'Major NC',
    '23589': 'Minor NC',
    '23679': 'Major NC',
    '23689': 'Major NC',
    '24579': 'Minor NC',
    '24589': 'Minor NC',
    '24679': 'Major NC',
    '24689': 'Minor NC',
    '1357a': 'Major NC',
    '1358a': 'Minor NC',
    '1367a': 'Major NC',
    '1368a': 'Major NC',
    '1457a': 'Major NC',
    '1458a': 'Minor NC',
    '1467a': 'Major NC',
    '1468a': 'Minor NC',
    '2357a': 'Minor NC',
    '2358a': 'Minor NC',
    '2367a': 'Minor NC',
    '2368a': 'Minor NC',
    '2457a': 'Minor NC',
    '2458a': 'Pass',
    '2467a': 'Minor NC',
    '2468a': 'Minor NC'
};

const MHNewAnswerMap = {
    '24679': 'Pass',
    '2467a': 'Pass',
    '24689': 'Major NC',
    '2468a': 'Minor NC',
    '1358a': 'Major NC',
    '13579': 'Major NC',
    '13589': 'Major NC',
    '13679': 'Major NC',
    '13689': 'Major NC',
    '14579': 'Major NC',
    '14589': 'Major NC',
    '14679': 'Major NC',
    '14689': 'Major NC',
    '23579': 'Major NC',
    '23589': 'Major NC',
    '23679': 'Major NC',
    '23689': 'Major NC',
    '24579': 'Major NC',
    '24589': 'Major NC',
    '1357a': 'Major NC',
    '1367a': 'Major NC',
    '1368a': 'Major NC',
    '1457a': 'Major NC',
    '1458a': 'Major NC',
    '1467a': 'Major NC',
    '1468a': 'Major NC',
    '2357a': 'Major NC',
    '2358a': 'Major NC',
    '2367a': 'Major NC',
    '2368a': 'Major NC',
    '2457a': 'Major NC',
    '2458a': 'Major NC'
};

/**
 * @description This function will call automatically to get the validation.
 */
const Module = (function () {
    const factory = {};
    const RuleObj = {
        lowest: 'Minor NC',
        middle: 'Major NC',
        highest: 'Pass'
    };

    factory.getValidation = (tabName, questionList, finalMHValue, defaultSubQues) => {
        let electedValidation;
        const validations = _handleValidationByTabName(
            tabName,
            questionList,
            finalMHValue,
            defaultSubQues
        );
        if (validations && Array.isArray(validations)) {
            electedValidation = !!validations.length
                ? validations.indexOf('Major NC') > -1
                    ? RuleObj['middle']
                    : validations.indexOf('Minor NC') > -1
                    ? RuleObj['lowest']
                    : RuleObj['highest']
                : '';
        } else {
            electedValidation = validations;
        }
        return electedValidation;
    };
    return factory;
})();

/**
 * @description This function used to handle validation with the help of tab name.
 * @param tabName tab name
 * @param questionList Question list array.
 */
const _handleValidationByTabName = (tabName, questionList, finalMHValue, defaultSubQues) => {
    let validations = [];
    switch (tabName) {
        case 'Preventative Maintenance':
            validations = _handlePreventativeMaintenance(questionList);
            break;
        case 'Machine Health':
            validations = _handleMachineHealth(questionList, finalMHValue, defaultSubQues);
            break;
        case 'PM Quality Assessment':
            validations = _handlePMQuality(questionList);
            break;
        default:
    }
    return validations;
};

/**
 * @description This function used to filter data for machine health tab
 * @param questionList Question list array.
 */
function _handleMachineHealth(questionList, finalMHValue, defaultSubQues) {
    // const key = questionList.filter(data => !data.parent).map(data => data.answer).join('');
    if (defaultSubQues) return MHAnswerMap[finalMHValue];
    else return MHNewAnswerMap[finalMHValue];
}

/**
 * @description This function used to filter data for preventative maintenence tab.
 * @param questionList Question list array.
 */
const _handlePreventativeMaintenance = (questionList) => {
    let check = true;
    const validations = [];
    const length = questionList.length;
    let i = 0;
    while (i < length) {
        let localValidation;

        if (questionList[i].sno == 1 && questionList[i].answer == 'Yes') {
            i = 3;
        }
        if (questionList[0].answer !== 'No') {
            if (questionList[i].answer) {
                switch (questionList[i].sno) {
                    case 1:
                        const parentId = questionList[i].sno;
                        const children = questionList.filter(
                            (data) => data.parent && data.parent === parentId
                        );
                        if (children.length > 0) {
                            localValidation = __getValidationFromChildren(children);
                        }
                        break;
                    case 2:
                        localValidation = questionList[i].answer === 'No' && 'Major NC';
                        break;
                    case 3:
                        localValidation =
                            ['Yes', 'NA'].indexOf(questionList[i].answer) > -1
                                ? 'Pass'
                                : 'Major NC';
                        break;
                    default:
                }
                if (localValidation) {
                    validations.push(localValidation);
                }
            } else {
                check = false;
                return check;
            }
        } else {
            const parentId = questionList[i].sno;
            const children = questionList.filter((data) => data.parent && data.parent === parentId);
            if (children.length > 0) {
                localValidation = __getValidationFromChildren(children);
            }
            if (localValidation) {
                validations.push(localValidation);
            }
        }
        i++;
    }
    if (check) {
        return validations;
    }
};

/**
 * @description This function used to get the validation of sub question.
 * @param children Children of a question.
 */
const __getValidationFromChildren = (children) => {
    let childValidation;
    if (
        (children[0].answer === 'Yes' || children[0].answer === 'NA') &&
        (children[1].answer === 'Yes' || children[1].answer === 'NA')
    ) {
        childValidation = 'Major NC';
    } else if (
        (children[0].answer === 'Yes' || children[0].answer === 'NA') &&
        children[1].answer === 'No'
    ) {
        childValidation = 'Major NC';
    } else if (
        children[0].answer === 'No' &&
        (children[1].answer === 'Yes' || children[1].answer === 'NA')
    ) {
        childValidation = 'Major NC';
    } else if (children[0].answer === 'No' && children[1].answer === 'No') {
        childValidation = 'Minor NC';
    }
    return childValidation;
};

/**
 * @description This function used to filter data for PM Quality Assesment tab.
 * @param questionList Question list array.
 */
const _handlePMQuality = (questionList) => {
    let key = 'Pass';
    let check = true;
    if (questionList.length === 1) {
        if (!questionList[0].answer) {
            return false;
        }
    } else if (!(questionList[0].answer && questionList[1].answer && questionList[2].answer)) {
        return false;
    }
    questionList
        .filter((data) => !data.parent)
        .map((data) => {
            if (data.answer == '') {
                check = false;
            } else {
                if (data.answer == 'No') {
                    key = 'Major NC';
                }
            }
        })
        .join('');
    if (check) {
        return key;
    } else {
        return check;
    }
};

export default Module;
