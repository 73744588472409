/**
 * @author Akshay Kumar Singh
 * @file Validator
 * @flow
 */
import SimpleReactValidator from 'simple-react-validator';
import { STRINGS, REGEX } from '../constants/data';

// Validation rules for different field name
const validator = new SimpleReactValidator({
    validators: {
        email: {
            // name the rule
            message: STRINGS.CorrectEmail,
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, REGEX.EMAIL) && params.indexOf(val) === -1;
            },
            messageReplace: (message, params) => {
                message.replace(':values', this.helpers.toSentence(params));
            }, // optional
            required: true // optional
        },
        password: {
            message: STRINGS.CorrectPassword,
            rule: (val, params, validator) => {
                return val.length > 5 && val.length <= 20 && params.indexOf(val) === -1;
            },
            messageReplace: (message, params) => {
                message.replace(':values', this.helpers.toSentence(params));
            }, // optional
            required: true // optional
        },
        auditor: {
            message: STRINGS.FIELDREQUIRED,
            rule: (val, params, validator) => {
                return !!val.length;
            },
            required: true // optional
        },
        reviewer: {
            message: STRINGS.FIELDREQUIRED,
            rule: (val, params, validator) => {
                return !!val;
            },
            required: true // optional
        },
        assetsType: {
            message: STRINGS.FIELDREQUIRED,
            rule: (val, params, validator) => {
                return !!val;
            },
            required: true // optional
        },
        lineType: {
            message: STRINGS.FIELDREQUIRED,
            rule: (val, params, validator) => {
                return !!val;
            },
            required: true // optional
        },
        assets: {
            message: STRINGS.FIELDREQUIRED,
            rule: (val, params, validator) => {
                return !!val;
            },
            required: true // optional
        },
        type: {
            message: STRINGS.FIELDREQUIRED,
            rule: (val, params, validator) => {
                return !!val;
            },
            required: true // optional
        },
        frequency: {
            message: STRINGS.FIELDREQUIRED,
            rule: (val, params, validator) => {
                return !!val;
            },
            required: true // optional
        },
        estimatedDate: {
            message: STRINGS.FIELDREQUIRED,
            rule: (val, params, validator) => {
                return !!val;
            },
            required: true // optional
        },
        role: {
            message: STRINGS.PleaseSelectRole,
            rule: (val, params, validator) => {
                return !!val;
            },
            required: true // optional
        },
        completionPercentage: {
            message: STRINGS.FIELDREQUIRED,
            rule: (val, params, validator) => {
                return !!val;
            },
            required: true // optional
        }
    }
});
export default validator;
