import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import RouteComponent from './routes/routeComponent';
import { store } from './redux/configureStore';

function App() {
    return (
        <ReduxProvider store={store}>
            <RouteComponent />
        </ReduxProvider>
    );
}

export default App;
