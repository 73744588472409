/**
 * @author Akshay Kumar Singh
 * @file DetailsRow Component
 * @flow
 */
import React from 'react';
import { getData } from '../../shared/utils/storage.util';
import { formatDate, checkTaskStatus } from '../../shared/utils/helper.util';
import { AUDIT_STATUS } from '../../shared/constants/data';

/**
 * @description This function show the details row of the task.
 * @param props Props is the data coming from parent component.
 */
const DetailsRow = (props) => {
    return (
        <>
            <tr>
                <td>{props.taskListData.auditor_name}</td>
                <td>{props.taskListData.reviewer_name}</td>
                <td>{props.taskListData.task_name}</td>
                <td>{props.taskListData.line}</td>
                <td>{props.taskListData.asset}</td>
                <td>{props.taskListData.type}</td>
                <td>{formatDate(props.taskListData.estimate_date)}</td>
                <td>{checkTaskStatus(props.taskListData.task_status)}</td>
                {props.taskListData.status !== AUDIT_STATUS['COMPLETED'] && (
                    <td>
                        <a
                            href="!#"
                            onClick={(evt) =>
                                props.handleEdit.editTask(
                                    evt,
                                    props.taskListData,
                                    getData('checkPage'),
                                    2
                                )
                            }>
                            <img
                                src={require('../../assets/images/edit-icon.png')}
                                alt="edit-icon"
                            />
                        </a>
                        {props.taskListData.status !== AUDIT_STATUS['COMPLETED'] ? (
                            <a
                                href="!#"
                                onClick={(evt) =>
                                    props.handleDelete.deleteTask(evt, props.taskListData)
                                }>
                                <img
                                    src={require('../../assets/images/dustbin-icon.png')}
                                    alt="delete-icon"
                                />
                            </a>
                        ) : (
                            ''
                        )}
                    </td>
                )}
            </tr>
        </>
    );
};

export default DetailsRow;
