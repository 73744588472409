const ROUTES = {
    LANDING: '/',
    ACCESSREQUEST: '/accessrequest',
    CHANGEPASSWORD: '/changepassword',
    DASHBOARD: '/dashboard',
    AUDITDETAILS: '/dashboard/:plantName/:auditId',
    AUDITSUMMARY: '/dashboard/:plantName/',
    EDITAUDITTASK: '/dashboard/editTask/:plantName/:auditId/:taskId',
    EDITTASK: '/dashboard/editTask/:plantName/:taskId',
    MYTASK_UPDATETASK: '/dashboard/mytask/updatetask/:plantName/:taskId',
    CREATEDAUDITDETAILS: '/createAudit',
    CREATEDAUDIT: '/createAudit/addTask',
    DRAFTEDAUDIT: '/addTask',
    MYTASK: '/myTask',
    TASKREVIEW: '/myTask/reviewTask',

    /* Auditor Route */
    AUDITOR_MYTASK: '/auditor/mytask',
    AUDITOR_MYTASK_UPDATETASK: '/auditor/mytask/updatetask/:taskId',

    /* Reviewer Route */
    REVIEWER_MYTASK: '/reviewer/mytask',
    REVIEWER_MYTASK_UPDATETASK: '/reviewer/mytask/updatetask/:taskId',

    /** Plant Manager Route */
    PLM_MYTASK: '/plant-manager/mytask',
    PLM_EDITTASK: '/plant-manager/editTask/:auditId/:taskId',
    PLM_MYTASK_UPDATETASK: '/plant-manager/mytask/updatetask/:taskId',

    /* Plant Auditor Route */
    PLA_MYTASK: '/plant-auditor/mytask',
    PLA_MYTASK_UPDATETASK: '/plant-auditor/mytask/updatetask/:taskId',

    /* Plant Reviewer Route */
    PLR_MYTASK: '/plant-reviewer/mytask',
    PLR_MYTASK_UPDATETASK: '/plant-reviewer/mytask/updatetask/:taskId'
};

export default ROUTES;
