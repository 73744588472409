/**
 * @author Vaibhav Singh
 * @file Write Back Modal
 * @flow
 */
import React from 'react';
import Modal from 'react-responsive-modal';
import { getData } from '../utils/storage.util';
import { getWriteBackValue } from '../../redux/actions/common_action';
import CONFIG from '../../configuration';
import AlertModal from './AlertModal';
import LANGUAGECONST from '../constants/language';
import { WriteBackReviewModal } from './WriteBackReview';

class WriteBackModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            crudType: '',
            op_sequence: '',
            description: '',
            long_description: '',
            WriteBackModalReviewStatus: false,
            writeBackData: {}
        };

        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        let { quesData, taskInfo } = this.props;

        let data = {
            audit_id: taskInfo.audit_id,
            task_id: taskInfo.task_id,
            ques_id: quesData.ques_id
        };
        getWriteBackValue(CONFIG.getWBQues, data, 'GET').then((res) => {
            // console.log(res.response.length);
            if (res && res.response.length > 0) {
                this.setState({
                    op_sequence: res.response[0].op_seq,
                    description: res.response[0].description,
                    long_description: res.response[0].long_description,
                    crudType: res.response[0].scenario
                });
            } else {
                AlertModal.showAlert(res.message);
            }
        });
    }

    closeWriteBackReviewModal = () => {
        this.setState({ WriteBackModalReviewStatus: false });
    };

    closeWriteBackModal = () => {
        this.props.onClose();
    };

    onSave(ques_id, task_id, audit_id) {
        let { op_sequence, description, long_description, crudType } = this.state;

        const data = {
            audit_id: audit_id,
            task_id: task_id,
            ques_id: ques_id,
            scenario: crudType,
            op_seq: crudType != 'Not Accessed' ? op_sequence : null,
            description: crudType != 'Not Accessed' ? description : null,
            long_description: crudType != 'Not Accessed' ? long_description : null,
            min: null,
            max: null
        };
        this.setState({ WriteBackModalReviewStatus: true, writeBackData: data });
    }

    setSequence = (event) => {
        this.setState({ crudType: event.target.value });
    };

    handleSearch(event) {
        if (event.target.name == 'op_sequence') this.setState({ op_sequence: event.target.value });
        else if (event.target.name == 'description')
            this.setState({ description: event.target.value });
        else if (event.target.name == 'long_description')
            this.setState({ long_description: event.target.value });
    }

    render() {
        let { quesData, taskInfo } = this.props;
        let {
            WriteBackModalReviewStatus,
            op_sequence,
            description,
            long_description,
            crudType,
            writeBackData
        } = this.state;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];

        return (
            <Modal
                open={this.props.isWriteBackModalOpen}
                onClose={this.props.onClose}
                closeOnOverlayClick={false}
                center>
                <div className="pop-up-bg" id="create-audit-popup">
                    <div className="rqst-accs-popup">
                        <div className="create-audit-outer"></div>
                        <div className="table-outer tabcontent active popup-outer">
                            <div className="question-form-container">
                                <div className="form-group-question">
                                    <div>
                                        <input
                                            type="radio"
                                            value="Edit"
                                            onChange={this.setSequence}
                                            checked={crudType === 'Edit'}
                                        />{' '}
                                        Edit &nbsp;
                                        <input
                                            type="radio"
                                            value="Add"
                                            onChange={this.setSequence}
                                            checked={crudType === 'Add'}
                                        />{' '}
                                        Add &nbsp;
                                        <input
                                            type="radio"
                                            value="Delete"
                                            onChange={this.setSequence}
                                            checked={crudType === 'Delete'}
                                        />{' '}
                                        Delete &nbsp;
                                        <input
                                            type="radio"
                                            value="Not Accessed"
                                            onChange={this.setSequence}
                                            checked={crudType === 'Not Accessed'}
                                        />{' '}
                                        Not Accessed &nbsp;
                                    </div>
                                    <br />
                                    {crudType === 'Edit' ||
                                    crudType === 'Add' ||
                                    crudType === 'Delete' ? (
                                        <>
                                            <label>OP Seq</label>
                                            <input
                                                type="number"
                                                min={1}
                                                value={op_sequence}
                                                className="form-control"
                                                name="op_sequence"
                                                onChange={this.handleSearch}
                                                required
                                            />
                                            <label>Description</label>
                                            <input
                                                type="text"
                                                value={description}
                                                className="form-control"
                                                name="description"
                                                onChange={this.handleSearch}
                                                required
                                            />
                                            <label>Long Description</label>
                                            <input
                                                type="text"
                                                value={long_description}
                                                className="form-control"
                                                name="long_description"
                                                onChange={this.handleSearch}
                                                required
                                            />
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="crt-odi-btn-otr">
                            <button
                                className="btn-prmry"
                                type="button"
                                onClick={() =>
                                    this.onSave(
                                        quesData.ques_id,
                                        taskInfo.task_id,
                                        taskInfo.audit_id
                                    )
                                }>
                                {lang.BUTTONS.OK}
                            </button>
                        </div>
                    </div>
                </div>
                {WriteBackModalReviewStatus && (
                    <WriteBackReviewModal
                        data={writeBackData}
                        isModalOpen={WriteBackModalReviewStatus}
                        cancelAction={this.closeWriteBackModal}
                        showCancel={true}
                        showOk={true}
                        msg="Do you want to save the Review"
                        onClose={this.closeWriteBackReviewModal}
                    />
                )}
            </Modal>
        );
    }
}

export default WriteBackModal;
