/**
 * @author Vaibhav Singh
 * @file MH_Ques Modal
 * @flow
 */
import React from 'react';
import Modal from 'react-responsive-modal';
import { getData } from '../utils/storage.util';
import AlertModal from './AlertModal';
import ValidationHandler from '../utils/decisionMaker.util';
import LANGUAGECONST from '../constants/language';
import AuditWriteBack from '../../components/AuditDetailsComponent/AuditWriteBack';

class MH_QuesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isInspected: 0,
            isApplicable: 0,
            isNotAccessed: null,
            applicableData: []
        };
    }

    componentDidMount() {
        let { isApplicable, isInspected } = this.state;
        if (!!this.props.quesData.isApplicable) {
            this.setState(
                {
                    isApplicable: isApplicable == 0 ? 1 : 0
                },
                () => {
                    let element = document.getElementById('btn-1').classList;
                    element.toggle('active');
                }
            );
        }
    }

    toggleBtn = (id, index) => {
        let { taskInfo } = this.props;
        let { task_status } = taskInfo;
        let { isApplicable, isInspected } = this.state;
        if (task_status != 3) {
            if (id === 'btn-1') {
                this.setState(
                    {
                        isApplicable: isApplicable == 0 ? 1 : 0
                    },
                    () => {
                        this.setIsApplicable(id, index);
                    }
                );
            } else {
                this.setState(
                    {
                        isInspected: isInspected == 0 ? 1 : 0
                    },
                    () => {
                        this.setIsInspected(id, index);
                    }
                );
            }
        }
    };

    onSave(validation) {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { isInspected, isApplicable } = this.state;
        if (!!validation || !!isInspected || !!isApplicable) {
            this.props.onCancel();
        } else {
            AlertModal.showAlert(lang.STRINGS.AllFieldsRequired);
        }
    }

    setIsInspected = (id, index) => {
        let { isInspected } = this.state;
        let element = document.getElementById(id).classList;
        element.toggle('active');
        this.props.isInspected(index, isInspected);
    };

    setIsApplicable = (id, index) => {
        let { isApplicable } = this.state;
        let element = document.getElementById(id).classList;
        element.toggle('active');
        this.props.isApplicable(index, isApplicable);
    };

    setApplicableData = (data) => {
        this.setState({
            applicableData: data
        });
    };

    render() {
        let { index, quesData, taskInfo, onCancel } = this.props;
        let { task_status } = taskInfo ? taskInfo : {};
        let { userData } = getData('loginData');
        let { isApplicable, applicableData, isNotAccessed } = this.state;
        let isDisabled =
            (userData.role_name === 'auditor' && task_status == 2) || task_status == 3
                ? true
                : false;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];

        return (
            <Modal
                open={this.props.isModalOpen}
                onClose={this.props.onCancel}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                center>
                <div className="pop-up-bg" id="create-audit-popup">
                    <div className="rqst-accs-popup">
                        <div className="create-audit-outer"></div>
                        <div className="toggle-block">
                            <div className="toggle-block-1">
                                <span className="toggle-lable">{lang.LABLES.NotApplicable}</span>
                                <div className="toggle-btn-container-1">
                                    <div
                                        className="toggle-btn"
                                        id="btn-1"
                                        onClick={() => this.toggleBtn('btn-1', index)}>
                                        <div className="inner-circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-outer tabcontent active popup-outer">
                            <div className="question-form-container">
                                {isApplicable ? (
                                    <>
                                        <AuditWriteBack
                                            applicableData={applicableData}
                                            setApplicableData={this.setApplicableData}
                                            quesData={quesData}
                                            lang={lang}
                                            taskInfo={taskInfo}
                                            isNotAccessed={isNotAccessed}
                                            onClose={onCancel}
                                            taskType={2}
                                        />
                                    </>
                                ) : (
                                    <div className="form-group-question">
                                        <label>Types of Validations</label>
                                        <select
                                            className="form-control"
                                            id={index}
                                            name="validation"
                                            value={quesData.validation ? quesData.validation : ''}
                                            onChange={this.props.handleChange}
                                            disabled={isDisabled || !!quesData.isApplicable}>
                                            <option value="">{lang.LABLES.SelectOption}</option>
                                            {quesData.mh_script_questions[0].options.map(
                                                (quesData, indexKey) => (
                                                    <option key={indexKey} value={quesData.value}>
                                                        {quesData.label}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                )}
                            </div>
                        </div>

                        {!isApplicable && (
                            <div className="crt-odi-btn-otr">
                                <button
                                    className="btn-prmry"
                                    type="button"
                                    onClick={() => this.onSave(quesData.validation)}>
                                    {lang.BUTTONS.OK}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default MH_QuesModal;
