/**
 * @author Akshay Kumar Singh
 * @file ScannerModule Component
 * @flow
 */
import React, { useState, useRef, useEffect } from 'react';
import Scanner from './Scanner';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import Quagga from '@ericblade/quagga2';

const ScannerModule = ({ startScanner, isModalOpen, onCancel }) => {
    const [show, setShow] = useState(false);
    const [results, setResults] = useState([]);
    const scannerRef = useRef(null);

    useEffect(() => {
        if (isModalOpen) {
            setShow(true);
        }
    });
    /**
     * @description This function closes barcode scanner modal and stops quagga service.
     */
    const setScanner = () => {
        setShow(false);
        onCancel();
        Quagga.stop();
    };

    return (
        <div>
            <Modal
                open={isModalOpen}
                onClose={setScanner}
                closeOnEsc={false}
                closeOnOverlayClick={false}>
                <div className="create-audit-outer scaner-modal">
                    <div className="top-cntnt right">
                        <a href="javascript:void(0)" onClick={setScanner}>
                            <img
                                src={require('../../assets/images/cross-icon.png')}
                                alt="cross-icon"
                            />
                        </a>
                    </div>
                </div>
                {isModalOpen ? (
                    <div ref={scannerRef} style={{ position: 'relative', maxWidth: '641px' }}>
                        <canvas
                            className="drawingBuffer"
                            style={{
                                width: '637px',
                                position: 'absolute',
                                justifyContent: 'center',
                                bottom: '0px',
                                height: '100%',
                                border: '3px solid green'
                            }}
                            width="637"
                            height="480"
                        />
                        {show ? (
                            <Scanner
                                scannerRef={scannerRef}
                                setScanner={() => setScanner()}
                                onDetected={(result) => setResults([result])}
                                startScanner={startScanner}
                            />
                        ) : null}
                    </div>
                ) : null}
            </Modal>
        </div>
    );
};

Scanner.propTypes = {
    startScanner: PropTypes.func.isRequired
};

export default ScannerModule;
