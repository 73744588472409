import { LABLES } from '../constants/data';
import React, { useState, Component } from 'react';
import Modal from 'react-responsive-modal';
import Axios from 'axios';
import CONFIG from '../../configuration';
import { getData } from '../utils/storage.util';
import { spinnerService } from '../services/spinner.service';
import LANGUAGECONSTANT from '../constants/language';
import { logoutUnauthorizedUser } from '../utils/auth.util';
import AlertModal from './AlertModal';
import AuditForm from '../../components/Common/AuditForm';

class AuditWriteBackModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quesObj: {
                op_sequence: '',
                short_desc: '',
                long_desc: '',
                isQuant: '',
                department: '',
                min: '',
                max: ''
            }
        };
    }

    onCancel = () => {
        this.props.setApplicable(null);
        this.props.setShowForm(false);
    };

    restrictToNumber = (event) => {
        let { value } = event.target;
        let numTest = /^-?\d*\.?\d{0,3}$/;
        if (numTest.test(value)) {
            this.handleChange(event);
        }
    };

    /**
     * @description This function submits data of manually added question in machine health.
     */
    handleProceed = async (e) => {
        e.preventDefault();
        let language = this.props?.langData?.language || 'en';
        let { audit_id, task_id } = this.props.taskInfo;
        let { ques_id, min, max } = this.props.quesData;

        let data = {
            audit_id: audit_id,
            task_id: task_id,
            ques_id: ques_id,
            scenario: this.props.applicable,
            op_seq: e.target.op_sequence.value,
            description: e.target.short_desc.value,
            long_description: e.target.long_desc.value,
            min: min == '' || !min || !!e.target.min ? '' : e.target.min.value,
            max: max == '' || !max || !!e.target.max ? '' : e.target.max.value,
            area: e.target.department.value,
            isLocal: this.props.quesData?.isLocal
        };

        spinnerService.show(
            'Oval',
            LANGUAGECONSTANT[this.props?.langData?.langType || 'ENG'].STRINGS.UploadingImage
        );
        const config = {
            headers: {
                authorization: `Bearer ${getData('token')}`,
                language: language
            }
        };
        let response = await Axios.post(CONFIG.addWBQues, data, config);
        spinnerService.hide('Oval');
        this.props.setShowForm(false);
        if (response && response.data && response.data.responseCode == 200) {
            AlertModal.showAlert(response.data.message);
            this.props.getWbQuesApi();
        } else if (response && response.data && response.data.responseCode == 401) {
            logoutUnauthorizedUser(this, response.data.message);
        } else {
            AlertModal.showAlert(response.data.message);
        }
    };

    render() {
        let { showForm, lang, quesData, applicable, taskInfo } = this.props;
        return (
            <>
                <Modal open={showForm} onClose={this.onCancel} center>
                    <AuditForm
                        lang={lang}
                        applicable={applicable}
                        data={quesData}
                        handleProceed={this.handleProceed}
                        onCancel={this.onCancel}
                        taskInfo={taskInfo}
                    />
                </Modal>
            </>
        );
    }
}

export default AuditWriteBackModal;
