/**
 * @author Rehan Rizvi
 * @file PRReviewerAns Component
 * @flow
 */
import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import {
    getPostDataWithType,
    getPostDataWithAction,
    downloadFile
} from '../../../../redux/actions/common_action';
import CONFIG from '../../../../configuration';
import AlertModal from '../../../../shared/modals/AlertModal';
import { getData } from '../../../../shared/utils/storage.util';
import { STRINGS } from '../../../../shared/constants/data';
import { spinnerService } from '../../../../shared/services/spinner.service';
import { CallApi } from '../../../../shared/services/api.service';
import ImagePrevew from '../../../../shared/modals/ImagePrevew';
import ReasonModal from '../../../../shared/modals/ReasonModal';
import PreviewModal from '../../../../shared/modals/PRPreviewModal';
import IdleTimerComponent from '../../../Common/IdleTimeCheck/IdleTimerComponent';
import {
    getRoleBasedRoute,
    showObservation,
    checkFileType,
    checkVideoType,
    getAuditId
} from '../../../../shared/utils/helper.util';
import QuantifiableModal from '../../../../shared/modals/QuantifiableModal';
import WriteBackModal from '../../../../shared/modals/WriteBackModal';
import { logoutUnauthorizedUser } from '../../../../shared/utils/auth.util';
import Thumbnail from '../../../Common/Thumbnail/Thumbnail';
import ImageVideoSelectionModal from '../../../../shared/modals/ImageVideoSelectionModal';
import LANGUAGECONST from '../../../../shared/constants/language';
import { languageData } from '../../../../redux/actions/languageAction';

class PRReviewerAnsComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskInfo: {},
            QuesList: [],
            heading: [],
            submitObj: {},
            isSubmitClicked: false,
            imageArr: [],
            showPreviewModel: false,
            confirmation: false,
            isUploadFile: false,
            showImageModel: false,
            imgObj: null,
            questionData: {},
            selectedType: null,
            currentTabName: null,
            checkDataChange: false,
            index: null,
            uploadObj: null,
            oracleData: {},
            remainData: {},
            openReasonModal: false,
            isQuantifiableModalOpen: false,
            isWriteBackModalOpen: false,
            options: ['Pass', 'Major NC', 'Minor NC'],
            quesIndex: null
        };
    }

    componentWillReceiveProps = (nextProps) => {
        let { QuesList, taskInfo } = nextProps;
        this.getHeading(taskInfo || {}, QuesList || []);
    };

    // componentDidMount = () => {
    //   this.interval = setInterval(()=>{
    //     this.submitAns(false, true);
    //   },15000);
    // }

    // componentWillUnmount = () => {
    //   clearInterval(this.interval);
    // }

    /**
     * @description This function sets value of tabs according task questions.
     * @param taskInfo It has info regarding task
     * @param QuesList It consists list of all questions regarding that task
     */
    getHeading = (taskInfo, QuesList) => {
        let { currentTabName } = this.state;
        let allHeading = QuesList.map((quesObj) => quesObj['long_heading']);
        let heading = allHeading.filter(
            (filterHeading, index) => allHeading.indexOf(filterHeading) === index
        );
        this.setState({
            heading,
            taskInfo,
            QuesList,
            currentTabName: currentTabName ? currentTabName : heading[0]
        });
    };

    /**
     * @description This function set values of item cards to data object.
     */
    handleChange = (event) => {
        let { QuesList, currentTabName } = this.state;
        let { id, name, value } = event.target;
        if (currentTabName === 'PRELIMINARY METRICS' && name === 'validation') {
            if (value === 'NA') {
                QuesList[id]['isApplicable'] = 1;
            } else {
                QuesList[id]['isApplicable'] = 0;
            }
        }
        QuesList[id][name] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    /**
     * @description This function restrict alphabets and special characters and only allows numbers upto two decimal places.
     */
    restrictToNumber = (event) => {
        let { value } = event.target;
        let numRegex = /^\d*\.?\d{0,2}$/;
        if (numRegex.test(value)) {
            this.handleChange(event);
        }
    };

    /**
     * @description This function set character limit on fields
     */
    handleChangeWithLength = (event) => {
        let { value } = event.target;
        if (value.length <= 400) {
            this.handleChange(event);
        }
    };

    /**
     * @description This function restrict alphabets and special characters and only
     * allows positive numbers upto two decimal places for selected questions.
     */
    restrictToPositiveNumberQuesBassed = (event, data) => {
        let { value } = event.target;
        let numRegex = /^\d*\.?\d{0,2}$/;
        let checkQues =
            data.pr_id == 1 ||
            data.long_ques.trim() == 'Actual Cycle Count Completion %' ||
            data.pr_id == 4 ||
            data.long_ques.trim() == 'Cycle Count Adjustments' ||
            data.pr_id == 5 ||
            data.long_ques.trim() == 'VLM Inventory Accuracy' ||
            data.pr_id == 6 ||
            data.long_ques.trim() == 'PO Lines' ||
            data.pr_id == 7 ||
            data.long_ques.trim() == 'Attendance (%)'
                ? true
                : false;
        let cond =
            data.pr_id == 1 ||
            data.long_ques.trim() == 'Actual Cycle Count Completion %' ||
            data.pr_id == 5 ||
            data.long_ques.trim() == 'VLM Inventory Accuracy' ||
            data.pr_id == 7 ||
            data.long_ques.trim() == 'Attendance (%)' ||
            data.pr_id == 2 ||
            data.long_ques.trim() == 'Expected Cycle Count Completion %' ||
            data.pr_id == 3 ||
            data.long_ques.trim() == 'Cycle Count Completion %'
                ? numRegex.test(value) && value <= 100
                : numRegex.test(value);
        if (checkQues && cond) {
            this.handleChange(event);
        } else if (!checkQues && cond) {
            this.handleChange(event);
        } else return;
    };

    /**
     * @description This function handles value of parameter that has radio button
     */
    handleRadio = (id, name, value) => {
        let { QuesList } = this.state;
        QuesList[id][name] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    /**
     * @description This function validates the values of data object on submit
     * @param dataArr It has data objects to be validated
     */
    validateData = (dataArr) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        this.setState({
            isSubmitClicked: true
        });
        let error = null;
        let { taskInfo, checkDataChange } = this.state;
        // if (!checkDataChange) {
        for (let i = 0; i < dataArr.length; i++) {
            let {
                long_heading,
                long_ques,
                pr_id,
                value,
                validation,
                auditor_comments,
                reviewer_comments,
                observation,
                isInspected,
                isApplicable,
                url
            } = dataArr[i];
            // if (((long_heading === "PRELIMINARY METRICS" && (pr_id === 5 || long_ques.trim() == "VLM Inventory Accuracy") && taskInfo.vlm != "No") || (pr_id === 1 || pr_id === 2 || pr_id === 4 || pr_id === 6 || pr_id === 7)) && !value) {
            //   return error = { long_heading, errorMsg: `Please fill out the value  for “${long_ques}”` };
            // }
            if (!isApplicable) {
                if (long_heading === 'PARTS ROOM SAFETY & SECURITY') {
                    if (!validation) {
                        return (error = {
                            long_heading,
                            errorMsg: `${lang.STRINGS.VALUEREQ} “${long_ques}”`
                        });
                    }
                    if (validation != 'Pass' && url.length == 0) {
                        return (error = `${lang.STRINGS.IMAGEREQ} “${long_ques}”`);
                    }
                    if (validation != 'Pass' && !observation) {
                        return (error = {
                            long_heading,
                            errorMsg: `${lang.STRINGS.OBSERVATIONREQ} “${long_ques}”`
                        });
                    }
                    if (validation != 'Pass' && (!auditor_comments || !reviewer_comments)) {
                        return (error = {
                            long_heading,
                            errorMsg: `${lang.STRINGS.IMPACTRECOMMENDATIONREQ} “${long_ques}”`
                        });
                    }
                }
            } else {
                if (url.length == 0) {
                    return (error = `${lang.STRINGS.IMAGEREQ} “${long_ques}”`);
                }
                if (!observation) {
                    return (error = {
                        long_heading,
                        errorMsg: `${lang.STRINGS.OBSERVATIONREQ} “${long_ques}”`
                    });
                }
            }
            if (
                long_heading === 'PRELIMINARY METRICS' &&
                (pr_id === 1 ||
                    pr_id === 2 ||
                    pr_id === 3 ||
                    pr_id === 4 ||
                    (pr_id === 5 && taskInfo.vlm != 'No') ||
                    pr_id === 6 ||
                    pr_id === 7)
            ) {
                if (pr_id === 1 || pr_id === 2 || pr_id === 3) {
                    if (!value && pr_id !== 3)
                        return (error = {
                            long_heading,
                            errorMsg: `${lang.STRINGS.VALUEREQ} “${long_ques}”`
                        });
                    if (!!value && !auditor_comments && pr_id === 3) {
                        return (error = {
                            long_heading,
                            errorMsg: `${lang.STRINGS.IMPACTREQ} “${long_ques}”`
                        });
                    }
                    if (!!value && !reviewer_comments && pr_id === 3) {
                        return (error = {
                            long_heading,
                            errorMsg: `${lang.STRINGS.RECOMMENDATIONREQ} “${long_ques}”`
                        });
                    }
                } else if (
                    pr_id === 4 ||
                    (pr_id === 5 && taskInfo.vlm != 'No') ||
                    pr_id === 6 ||
                    pr_id === 7
                ) {
                    if (!!validation) {
                        if (validation != 'Pass' && isApplicable === 0) {
                            if (!value)
                                return (error = {
                                    long_heading,
                                    errorMsg: `${lang.STRINGS.VALUEREQ} “${long_ques}”`
                                });
                            if (!!value && !auditor_comments) {
                                return (error = {
                                    long_heading,
                                    errorMsg: `${lang.STRINGS.IMPACTREQ} “${long_ques}”`
                                });
                            }
                            if (!!value && !reviewer_comments) {
                                return (error = {
                                    long_heading,
                                    errorMsg: `${lang.STRINGS.RECOMMENDATIONREQ} “${long_ques}”`
                                });
                            }
                        }
                    } else {
                        return (error = {
                            long_heading,
                            errorMsg: `${lang.STRINGS.VALIDATIONREQ} “${long_ques}”`
                        });
                    }
                }
            }
            if (long_heading === 'ORACLE SYSTEM AUDIT') {
                let {
                    item_number_correct,
                    item_locator_correct,
                    item_description_correct,
                    bin_labeled_correct,
                    actual_quantity
                } = dataArr[i];
                let obj = {
                    item_number_correct,
                    item_locator_correct,
                    item_description_correct,
                    bin_labeled_correct,
                    actual_quantity
                };
                for (let key in obj) {
                    if (!obj[key]) {
                        let reqVar =
                            key == 'item_number_correct'
                                ? 'Item Number Correct'
                                : key == 'item_locator_correct'
                                ? 'Item Locator Correct'
                                : key == 'item_description_correct'
                                ? 'Item Description Correct'
                                : key == 'bin_labeled_correct'
                                ? 'Bin Labeled Correct'
                                : key == 'actual_quantity'
                                ? 'Actual Quantity'
                                : '';
                        return (error = {
                            long_heading,
                            errorMsg: `${reqVar} ${lang.STRINGS.MANDATORYFIELDPR} ${dataArr[i].item_number}.`
                        });
                    }
                }
            }
        }
        // } else {
        //   return error = 'Please save before submit.';
        // }
        return error;
    };

    /**
     * @description This function filters data according to tab name
     * @param dataArr It has data of all tabs
     * @param tabName It has name of tab to filter their data
     */
    getDataArrBassedOnTab = (dataArr, tabName) => {
        let data = dataArr.filter((obj) => obj.long_heading === tabName);
        return data;
    };

    /**
     * @description This function formats data to select only the required keys for data object
     * @param dataArr It has data to be formatted
     */
    formatDataKeyForOracle = (dataArr) => {
        let data = dataArr.map((obj) => {
            let {
                ques_id,
                item_number,
                item_number_correct,
                item_locator,
                item_locator_correct,
                actual_quantity,
                item_description,
                item_description_correct,
                oracle_quantity,
                bin_labeled_correct,
                lot_number,
                manually_tab
            } = obj;
            let objKeys = {
                ques_id,
                item_number,
                item_locator,
                item_description,
                lot_number,
                oracle_quantity,
                item_number_correct: item_number_correct ? item_number_correct : null,
                item_locator_correct: item_locator_correct ? item_locator_correct : null,
                actual_quantity: actual_quantity ? actual_quantity : null,
                item_description_correct: item_description_correct
                    ? item_description_correct
                    : null,
                bin_labeled_correct: bin_labeled_correct ? bin_labeled_correct : null,
                manually_tab: manually_tab ? manually_tab : 0
            };
            return objKeys;
        });
        return data;
    };

    /**
     * @description This function calls on save and submit data. On save it show preview of
     * data and on submit that task is being assigned to the reviewer. It also get called after being
     * idle for 5 minutes and saves the data only without preview.
     * @param isSubmit It has boolean value to check if the function is called to save or submit the data
     * @param isIdle It has boolean value to check if function is call due to idle situation for 5 min or not
     */
    submitAns = async (isSubmit, isIdle = false) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { userData } = getData('loginData');
        let { taskInfo, QuesList, currentTabName, confirmation } = this.state;
        let { task_id, task_status, reviewer_flag, audit_id } = taskInfo;
        let { role_name } = userData;
        let error = isSubmit
            ? this.validateData(QuesList)
            : this.setState({ checkDataChange: false });
        if (error) {
            AlertModal.showAlert(error.errorMsg ? error.errorMsg : error);
            this.setState({
                currentTabName: error.long_heading ? error.long_heading : currentTabName
            });
        } else {
            let oracleQuesArr = this.getDataArrBassedOnTab(QuesList, 'ORACLE SYSTEM AUDIT');
            let remainQuesArr = this.getDataArrBassedOnTab(QuesList, 'PRELIMINARY METRICS').concat(
                this.getDataArrBassedOnTab(QuesList, 'BIN & SHELF AUDIT'),
                this.getDataArrBassedOnTab(QuesList, 'PARTS ROOM SAFETY & SECURITY')
            );
            let data = {
                task_id,
                status: isSubmit ? task_status + 1 : task_status,
                isAuditor: false
            };
            let addTaskData = {
                task_id,
                task_status: isSubmit ? task_status + 1 : task_status,
                audit_id,
                asset_type_id: 2,
                reviewer_flag: reviewer_flag ? reviewer_flag : 0,
                ar_reviewer_flag: role_name == 'plant manager' && task_status == 2 ? 1 : 0,
                isEmail: isSubmit ? true : false,
                isTaskCompleted: 0
            };
            let oracleData = { ...data, ques: this.formatDataKeyForOracle(oracleQuesArr) };
            let remainData = { ...addTaskData, ques: remainQuesArr };
            if (!isSubmit) {
                let route = isSubmit ? getRoleBasedRoute() : 'SAVE';
                await this.props.submitAns(
                    CONFIG.addPInventryQues,
                    oracleData,
                    !isIdle ? (isSubmit ? route : 'SAVE') : 'idle',
                    lang.STRINGS.SubmittingData,
                    this,
                    language
                );
                await this.props.submitAns(
                    CONFIG.addTaskQues,
                    remainData,
                    isSubmit ? route : 'SAVE',
                    lang.STRINGS.SubmittingData,
                    this,
                    language
                );
                this.props.getQuestionData();
                if (!isIdle) {
                    this.showHidePreviewModel();
                }
            } else {
                this.setState({ oracleData, remainData }, () => {
                    if (!confirmation) {
                        AlertModal.showAlert(lang.STRINGS.AcceptDeclaration);
                    } else {
                        this.onSubmit();
                    }
                });
            }
        }
    };

    /**
     * @description This function saves data of all the tabs which includes all questions and item cards.
     */
    onSubmit = async () => {
        const { oracleData, remainData } = this.state;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let route = getRoleBasedRoute();
        await this.props.submitAns(
            CONFIG.addPInventryQues,
            oracleData,
            route,
            lang.STRINGS.SubmittingData,
            this,
            language
        );
        await this.props.submitAns(
            CONFIG.addTaskQues,
            remainData,
            route,
            lang.STRINGS.SubmittingData,
            this,
            language
        );
    };

    getSelectBox = (index, data, typeId) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { options, taskInfo } = this.state;
        let { task_status } = taskInfo;
        return (
            <React.Fragment>
                <div className="data-upload-col">
                    <a
                        href="!#"
                        onClick={(evt) => {
                            if (data.isApplicable == 1) {
                                this.openWriteBackModal(evt, data, index, typeId);
                            } else {
                                this.openQuantifiableModal(evt, data, index, typeId);
                            }
                        }}
                        className={
                            !data.isApplicable
                                ? !!data.validation
                                    ? data.validation != 'Pass' &&
                                      (!data.auditor_comments ||
                                          !data.observation ||
                                          (task_status == 2 && !data.reviewer_comments) ||
                                          !data.url ||
                                          data.url.length == 0)
                                        ? ' srv-validation-message'
                                        : ' srv-validation-correct-message'
                                    : ''
                                : !!data.isApplicable
                                ? data.url.length != 0 && !!data.observation
                                    ? ' srv-validation-correct-message'
                                    : ' srv-validation-message'
                                : ''
                        }>
                        {lang.STRINGS.ClickToAnswer}
                    </a>
                    <br />
                    <span></span>
                </div>
            </React.Fragment>
            // <select className="form-control" id={index} name="validation"
            //   value={data.validation ? data.validation : ""} onChange={this.handleChange} disabled={task_status == 3}>
            //   <option disabled value="">Select Option</option>
            //   {options?.map((data, indexKey) => (
            //     <option key={indexKey} value={data}>{data}</option>
            //   ))}
            // </select>
        );
    };

    /**
     * @description This function handle images data.
     * @param data It consists data of image file.
     */
    handleFile = (event, data, type) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        this.handleUploadModalClose();
        let { id, name, files } = event.target;
        let { taskInfo } = this.state;
        let { task_id } = taskInfo;
        let { url } = data;
        if (
            files.length &&
            ((type === 'image' && !checkFileType(files[0].type)) ||
                (type === 'video' && !checkVideoType(files[0].type)))
        ) {
            return AlertModal.showAlert(lang.STRINGS.UnsupportedFileType);
        }
        if ((url && url.length + files.length <= 4) || (!url && files.length <= 4)) {
            let formData = new FormData();
            formData.append('task_id', task_id);
            formData.append('ques_id', id);
            formData.append('asset_type_id', getAuditId(taskInfo.task_name));
            for (let file of files) {
                formData.append('file', file);
            }
            this.uploadImage(name, formData);
        } else {
            AlertModal.showAlert(lang.STRINGS.MaxImages);
        }
    };

    /**
     * @description This function uploads the image.
     * @param data It consists data of image file.
     */
    uploadImage = async (index, data) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { QuesList } = this.state;
        spinnerService.show('Oval', lang.STRINGS.UploadingImage);
        try {
            const config = {
                headers: { authorization: `Bearer ${getData('token')}`, language: language }
            };
            let response = await Axios.post(CONFIG.uploadQuesImage, data, config);
            spinnerService.hide('Oval');
            if (response && response.data && response.data.responseCode == 200) {
                QuesList[index]['url'] = QuesList[index]['url']
                    ? QuesList[index]['url'].concat(response.data.response?.url)
                    : response.data.response?.url;
                this.setState({ QuesList });
            } else if (response && response.data && response.data.responseCode == 401) {
                logoutUnauthorizedUser(this, response.data.message);
            } else {
                AlertModal.showAlert(response.data.message);
            }
        } catch (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        }
    };

    openUploadModal = (ev, data, index) => {
        ev.preventDefault();
        this.setState({
            isUploadFile: true,
            uploadObj: { data: data, index: index }
        });
    };

    handleUploadModalClose = () => {
        this.setState({
            isUploadFile: false
        });
    };

    openQuantifiableModal = (evt, selectedQues, index, selectedType) => {
        evt.preventDefault();
        this.setState({
            isQuantifiableModalOpen: true,
            questionData: selectedQues,
            index: index,
            selectedType
        });
    };

    closeQuantifiableModal = () => {
        this.setState({ isQuantifiableModalOpen: false });
    };

    openWriteBackModal = (evt, selectedQues, index, selectedType) => {
        evt.preventDefault();
        this.setState({
            isWriteBackModalOpen: true,
            questionData: selectedQues,
            index: index,
            selectedType
        });
    };

    closeWriteBackModal = () => {
        this.setState({ isWriteBackModalOpen: false });
    };

    /**
     * @description This function open or closes the preview modal.
     */
    showHidePreviewModel = () => {
        let { showPreviewModel } = this.state;
        this.setState({ showPreviewModel: !showPreviewModel });
    };

    /**
     * @description This function shows image preview.
     * @param imgObj It consists data of image file
     */
    showHideImageModel = (quesIndex, imgObj, imgIndex) => {
        let { showImageModel } = this.state;
        this.setState({
            showImageModel: !showImageModel,
            quesIndex,
            imgObj,
            imgIndex
        });
    };

    /**
     * @description This function updates the current tab value.
     * @param currentTabName It has value of currently selected tab
     */
    tabChange = (currentTabName) => {
        this.setState({ currentTabName });
    };

    /**
     * @description This function updates the current tab value.
     * @param event It has selected event value.
     */
    tabChangeName = (event) => {
        this.setState({ currentTabName: event.target.value });
    };

    /**
     * @description This function verifies that all the filled details saved or not and confirms
     * with the reviewer that he/she is sure to assign task back to auditor.
     */
    checkAssignBack = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { checkDataChange } = this.state;
        if (checkDataChange) {
            AlertModal.showAlert(lang.STRINGS.SaveBeforeAssign);
        } else {
            AlertModal.showAlert(
                lang.STRINGS.ConfirmReassign,
                'assignBack',
                'toggleReasonModal',
                this
            );
        }
    };

    /**
     * @description This function opens modal to enter reason for assigning task back to auditor.
     */
    toggleReasonModal = () => {
        let { openReasonModal } = this.state;
        this.setState({
            openReasonModal: !openReasonModal
        });
    };

    /**
     * @description This function updates the tasks status of the particular task to be
     * In-Progress after task is assigned back to the auditor by reviewer and mails the reason
     * to the auditor.
     */
    assignTaskBack = (reason) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        this.toggleReasonModal();
        let { taskInfo } = this.state;
        let { task_id } = taskInfo;
        const dataObj = {
            task_id: task_id,
            reason
        };
        spinnerService.show('Oval', lang.STRINGS.AssigningBack);
        CallApi('POST', CONFIG.assignBack, {}, dataObj, '', 'assignBack', this, language);
    };

    /**
     * @description assignback API callback function
     * @param response This is the response structure received from API
     */
    assignBack = (response) => {
        AlertModal.showAlert(response.message);
        if (response.success) {
            setTimeout(() => {
                AlertModal.hideAlert();
                this.props.history.goBack();
            }, 2000);
        }
    };

    /**
     * @description This function handles the task's confirmation for declaration.
     */
    handleCheckBox = () => {
        let { confirmation } = this.state;
        this.setState({
            confirmation: !confirmation
        });
    };

    isInspected = (index, value) => {
        let { QuesList } = this.state;
        QuesList[index]['isInspected'] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    isApplicable = (index, value) => {
        let { QuesList } = this.state;
        QuesList[index]['isApplicable'] = value;
        this.setState({ QuesList, checkDataChange: true });
    };

    getCycleCount = (QuesList, data, index) => {
        if (
            (data.pr_id === 3 || data.long_ques.trim() == 'Cycle Count Completion %') &&
            !!QuesList[0].value &&
            !!QuesList[1].value
        ) {
            if (Number(QuesList[0].value) >= Number(QuesList[1].value)) {
                data.validation = 'Pass';
                data.value = '0';
                return 'Pass';
            } else {
                data.validation = 'Fail';
                data.value = QuesList[0].value;
                return 'Fail';
            }
        }
    };

    downloadExcel = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        let { taskInfo } = this.state;
        let { task_id } = taskInfo;

        const startDate = new Date(taskInfo.start_date);
        const endDate = new Date(taskInfo.end_date);
        const finalStartDate =
            (startDate.getMonth() < 10
                ? '0' + (startDate.getMonth() + 1)
                : (startDate.getMonth() + 1).toString()) +
            (startDate.getDate() < 10
                ? '0' + startDate.getDate()
                : startDate.getDate().toString()) +
            startDate.getFullYear().toString();
        const finalEndDate =
            (endDate.getMonth() < 10
                ? '0' + (endDate.getMonth() + 1)
                : (endDate.getMonth() + 1).toString()) +
            (endDate.getDate() < 10 ? '0' + endDate.getDate() : endDate.getDate().toString()) +
            endDate.getFullYear().toString();
        const fileName = `${taskInfo.plant_code}-PR-${finalStartDate}-${finalEndDate}`;
        downloadFile(
            `${CONFIG.downloadPRExcel
                .replace('{task_id}', task_id)
                .replace('{fileName}', fileName)}`,
            `${fileName}.xlsx`,
            lang.STRINGS.DOWNLOADINGPREXCEL,
            true,
            language
        );
    };

    render() {
        let {
            QuesList,
            taskInfo,
            showPreviewModel,
            currentTabName,
            heading,
            showImageModel,
            selectedType,
            isUploadFile,
            uploadObj,
            imgObj,
            openReasonModal,
            confirmation,
            isQuantifiableModalOpen,
            isWriteBackModalOpen,
            questionData,
            index,
            isSubmitClicked
        } = this.state;
        let { task_status, parts_min_count, vlm } = taskInfo ? taskInfo : {};
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <div className="work-order-wrap">
                {/* <IdleTimerComponent onSave={() => this.submitAns(false, true)} /> */}
                <div className="filter-container">
                    <div className="barcode-excel">
                        <button className="btn-blue" onClick={this.downloadExcel}>
                            {lang.BUTTONS.DOWNLOADEXCEL}
                        </button>
                    </div>
                </div>
                <div className="headiong-outer">
                    <h4>{lang.STRINGS.AuditTasksQuestions}</h4>
                </div>
                <div
                    className={
                        currentTabName !== 'ORACLE SYSTEM AUDIT' ? 'work-order-tabs-outer' : ''
                    }>
                    <div className="work-order-tabs">
                        {heading.map((data, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`btn-wrk-ordr  tablinks
              ${currentTabName == data ? 'active' : ''}`}
                                onClick={() => this.tabChange(data)}>
                                {data.toLowerCase()}
                            </button>
                        ))}
                    </div>
                    <select className="tab-select" onClick={(e) => this.tabChangeName(e)}>
                        {heading.map((data, index) => (
                            <option key={index} value={data}>
                                {data.toLowerCase()}
                            </option>
                        ))}
                    </select>
                    <div className="main-cntnt-wrap " id="existing-audits-summary-wrap">
                        <div className="container">
                            <ol className="breadcrumb"></ol>
                        </div>
                        <div className="existing-audits-summary-outer">
                            {currentTabName === 'ORACLE SYSTEM AUDIT' ? (
                                <div className="container">
                                    <div className="form-row task-row">
                                        {QuesList.length > 0 &&
                                            QuesList.map((data, index) => {
                                                if (data.long_heading === currentTabName) {
                                                    return (
                                                        <div className="column-four" key={index}>
                                                            {/* <div className={`summary-boxes ${(!!data.item_number_correct && !!data.item_locator_correct &&
                              !!data.item_description_correct && !!data.bin_labeled_correct && !!data.actual_quantity) ?
                              // ((data.manually_tab == 1) ? 'maunual-colour-code' : 'completed-info') : 'missing-info'}`}>
                              'completed-info' : 'missing-info'}`}> */}
                                                            <div className="summary-boxes">
                                                                <ul>
                                                                    <li>
                                                                        {lang.LABLES.ITEMNUMBER} :{' '}
                                                                        <strong>
                                                                            {data.item_number
                                                                                ? data.item_number
                                                                                : 'NA'}
                                                                        </strong>
                                                                    </li>
                                                                    <li>
                                                                        {lang.LABLES.LOTNUMBER} :{' '}
                                                                        <strong>
                                                                            {data.lot_number
                                                                                ? data.lot_number
                                                                                : 'NA'}
                                                                        </strong>
                                                                    </li>
                                                                    <li>
                                                                        {lang.LABLES.LOCATOR} :{' '}
                                                                        <strong>
                                                                            {data.item_locator
                                                                                ? data.item_locator
                                                                                : 'NA'}
                                                                        </strong>
                                                                    </li>
                                                                    <li>
                                                                        {
                                                                            lang.LABLES
                                                                                .ITEMDESCRIPTION
                                                                        }{' '}
                                                                        :{' '}
                                                                        <strong>
                                                                            {data.item_description
                                                                                ? data.item_description
                                                                                : 'NA'}
                                                                        </strong>
                                                                    </li>
                                                                    <li>
                                                                        {lang.LABLES.ORACLEQUANTITY}{' '}
                                                                        :{' '}
                                                                        <strong>
                                                                            {data.oracle_quantity
                                                                                ? data.oracle_quantity
                                                                                : 'NA'}
                                                                        </strong>
                                                                    </li>
                                                                </ul>
                                                                <div className="data-upload-col">
                                                                    {data.url &&
                                                                        data.url.map(
                                                                            (imgObj, imgIndex) => (
                                                                                <img
                                                                                    key={imgIndex}
                                                                                    src={
                                                                                        imgObj.img_path
                                                                                    }
                                                                                    alt="Images Icons"
                                                                                    className="upload-images"
                                                                                />
                                                                            )
                                                                        )}
                                                                </div>
                                                                <div className="form-group radio-group">
                                                                    <span>
                                                                        {
                                                                            lang.LABLES
                                                                                .ITEMNUMBERCORRECT
                                                                        }
                                                                    </span>
                                                                    <div className="data-upload-col">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="Y"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_number_correct ==
                                                                                'Y'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_number_correct',
                                                                                    'Y'
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                task_status == 3
                                                                            }
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '10px'
                                                                            }}>
                                                                            {lang.LABLES.Yes}
                                                                        </span>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="N"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_number_correct ==
                                                                                'N'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_number_correct',
                                                                                    'N'
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                task_status == 3
                                                                            }
                                                                        />
                                                                        {lang.LABLES.No}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group radio-group">
                                                                    <span>
                                                                        {
                                                                            lang.LABLES
                                                                                .ITEMLOCATORCORRECT
                                                                        }
                                                                    </span>
                                                                    <div className="data-upload-col">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="Y"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_locator_correct ===
                                                                                'Y'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_locator_correct',
                                                                                    'Y'
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                task_status == 3
                                                                            }
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '10px'
                                                                            }}>
                                                                            {lang.LABLES.Yes}
                                                                        </span>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="N"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_locator_correct ===
                                                                                'N'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_locator_correct',
                                                                                    'N'
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                task_status == 3
                                                                            }
                                                                        />
                                                                        {lang.LABLES.No}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group radio-group">
                                                                    <span>
                                                                        {
                                                                            lang.LABLES
                                                                                .ITEMDESCRIPTIONCORRECT
                                                                        }
                                                                    </span>
                                                                    <div className="data-upload-col">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="Y"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_description_correct ==
                                                                                'Y'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_description_correct',
                                                                                    'Y'
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                task_status == 3
                                                                            }
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '10px'
                                                                            }}>
                                                                            {lang.LABLES.Yes}
                                                                        </span>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="N"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.item_description_correct ==
                                                                                'N'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'item_description_correct',
                                                                                    'N'
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                task_status == 3
                                                                            }
                                                                        />
                                                                        {lang.LABLES.No}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group radio-group">
                                                                    <span>
                                                                        {
                                                                            lang.LABLES
                                                                                .BINLABELEDCORRECT
                                                                        }
                                                                    </span>
                                                                    <div className="data-upload-col">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="Y"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.bin_labeled_correct ==
                                                                                'Y'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'bin_labeled_correct',
                                                                                    'Y'
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                task_status == 3
                                                                            }
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                marginRight: '10px'
                                                                            }}>
                                                                            {lang.LABLES.Yes}
                                                                        </span>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            value="N"
                                                                            style={{
                                                                                marginRight: '3px'
                                                                            }}
                                                                            checked={
                                                                                data.bin_labeled_correct ==
                                                                                'N'
                                                                            }
                                                                            onChange={() =>
                                                                                this.handleRadio(
                                                                                    index,
                                                                                    'bin_labeled_correct',
                                                                                    'N'
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                task_status == 3
                                                                            }
                                                                        />
                                                                        {lang.LABLES.No}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <span>
                                                                        {lang.LABLES.ACTUALQUANTITY}
                                                                    </span>
                                                                    <div className="data-upload-col">
                                                                        <input
                                                                            type="text"
                                                                            id={index}
                                                                            className="form-control"
                                                                            name="actual_quantity"
                                                                            value={
                                                                                data.actual_quantity
                                                                                    ? data.actual_quantity
                                                                                    : ''
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .restrictToNumber
                                                                            }
                                                                            disabled={
                                                                                task_status == 3
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}
                                    </div>
                                </div>
                            ) : (
                                <div className="table-outer tabcontent active">
                                    {QuesList.map((data, index) => {
                                        if (data.long_heading === currentTabName) {
                                            let showComments =
                                                (currentTabName === 'PRELIMINARY METRICS' &&
                                                    (data.pr_id === 1 ||
                                                        data.long_ques.trim() ==
                                                            'Actual Cycle Count Completion %')) ||
                                                data.pr_id === 2 ||
                                                data.long_ques.trim() ==
                                                    'Expected Cycle Count Completion %' ||
                                                (currentTabName === 'BIN & SHELF AUDIT' &&
                                                    (data.pr_id === 8 ||
                                                        data.long_ques.trim() ==
                                                            'Total Number of On Hand Inventory Items' ||
                                                        data.pr_id === 9 ||
                                                        data.long_ques.trim() ==
                                                            'Minimum Number of System Items to Audit'))
                                                    ? false
                                                    : true;
                                            if (
                                                currentTabName === 'PRELIMINARY METRICS' &&
                                                (data.pr_id === 5 ||
                                                    data.long_ques.trim() ==
                                                        'VLM Inventory Accuracy') &&
                                                vlm === 'No'
                                            ) {
                                                data.validation = 'Pass';
                                                data.value = 100;
                                            }
                                            return (
                                                // <div className={`data-upload-row ${currentTabName === "PARTS ROOM SAFETY & SECURITY" ? (!data.reviewer_remark ? (!data.isApplicable && !data.isInspected ?
                                                //   (!!data.validation ? (data.validation != 'Pass' && (!data.auditor_comments || !data.reviewer_comments ||
                                                //     !data.observation || !data.url || data.url.length == 0) ? 'missing-info' : 'completed-info') : 'missing-info') : 'completed-info') : 'reviewer-remark') :
                                                //   (currentTabName === "PRELIMINARY METRICS" ? ((data.pr_id === 5 || data.pr_id === 1 || data.pr_id === 2 || data.pr_id === 4 || data.pr_id === 6 || data.pr_id === 7 || data.long_ques.trim() == "VLM Inventory Accuracy") ?
                                                //     (((data.pr_id === 5 && taskInfo.vlm == "Yes") || ((data.pr_id === 1 || data.pr_id === 2 || data.pr_id === 4 || data.pr_id === 6 || data.pr_id === 7))) ? ((((data.pr_id === 1 || data.pr_id === 2) && !!data.value && !!data.auditor_comments && !!data.reviewer_comments) || ((data.pr_id !== 1 || data.pr_id !== 2) && !!data.validation && !!data.value && !!data.auditor_comments && !!data.reviewer_comments) || (data.validation == "Pass" || data.isApplicable === 1)) ?
                                                //       'completed-info' : 'missing-info') : 'completed-info') : 'completed-info') : (currentTabName === "BIN & SHELF AUDIT" ?
                                                //         'completed-info' : 'missing-info'))}`} key={index}>
                                                <div className="data-upload-row" key={index}>
                                                    <div className="data-upload-col">
                                                        {data.long_ques}
                                                    </div>
                                                    {currentTabName === 'PRELIMINARY METRICS' ? (
                                                        data.pr_id == 4 ||
                                                        data.pr_id == 5 ||
                                                        data.pr_id == 6 ||
                                                        data.pr_id == 7 ? (
                                                            <div className="data-upload-col">
                                                                <select
                                                                    className={`form-control ${
                                                                        !data.validation &&
                                                                        !!isSubmitClicked
                                                                            ? 'blank-field'
                                                                            : ''
                                                                    }`}
                                                                    id={index}
                                                                    name="validation"
                                                                    value={
                                                                        data.validation
                                                                            ? data.validation
                                                                            : ''
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    disabled={
                                                                        ((data.pr_id === 5 ||
                                                                            data.long_ques.trim() ==
                                                                                'VLM Inventory Accuracy') &&
                                                                            vlm === 'No') ||
                                                                        task_status === 3
                                                                    }>
                                                                    <option
                                                                        selected
                                                                        hidden
                                                                        value="">
                                                                        {lang.LABLES.SelectOption}
                                                                    </option>
                                                                    <option value="Pass">
                                                                        {lang.LABLES.Pass}
                                                                    </option>
                                                                    <option value="Major NC">
                                                                        {lang.LABLES.MajorNC}
                                                                    </option>
                                                                    <option value="Minor NC">
                                                                        {lang.LABLES.MinorNC}
                                                                    </option>
                                                                    <option value="NA">
                                                                        {lang.LABLES.NotApplicable}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        ) : (
                                                            <div className="data-upload-col"></div>
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                    {currentTabName !=
                                                        'PARTS ROOM SAFETY & SECURITY' && (
                                                        <div className="data-upload-col">
                                                            {data.pr_id === 9 ||
                                                            data.long_ques.trim() ==
                                                                'Minimum Number of System Items to Audit' ? (
                                                                data.value
                                                            ) : data.pr_id !== 3 ||
                                                              data.long_ques.trim() !=
                                                                  'Cycle Count Completion %' ? (
                                                                <input
                                                                    type="text"
                                                                    className={`form-control ${
                                                                        (((data.pr_id === 5 ||
                                                                            data.long_ques.trim() ==
                                                                                'VLM Inventory Accuracy') &&
                                                                            vlm === 'Yes') ||
                                                                            ((data.pr_id === 1 ||
                                                                                data.pr_id === 2 ||
                                                                                data.pr_id === 4 ||
                                                                                data.pr_id === 6 ||
                                                                                data.pr_id === 7) &&
                                                                                (!data.validation ||
                                                                                    (data.validation !=
                                                                                        'Pass' &&
                                                                                        data.isApplicable ===
                                                                                            0)))) &&
                                                                        !data.value &&
                                                                        !!isSubmitClicked
                                                                            ? 'blank-field'
                                                                            : ''
                                                                    }`}
                                                                    style={{ maxWidth: '200px' }}
                                                                    name="value"
                                                                    value={
                                                                        data.value
                                                                            ? data.value
                                                                            : currentTabName ==
                                                                              'BIN & SHELF AUDIT'
                                                                            ? 0
                                                                            : ''
                                                                    }
                                                                    id={index}
                                                                    onChange={(event) =>
                                                                        this.restrictToPositiveNumberQuesBassed(
                                                                            event,
                                                                            data
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        currentTabName ==
                                                                            'BIN & SHELF AUDIT' ||
                                                                        task_status == 3 ||
                                                                        !!data.isApplicable ||
                                                                        ((data.pr_id === 5 ||
                                                                            data.long_ques.trim() ==
                                                                                'VLM Inventory Accuracy') &&
                                                                            vlm === 'No')
                                                                    }
                                                                />
                                                            ) : (
                                                                <strong>
                                                                    {this.getCycleCount(
                                                                        QuesList,
                                                                        data,
                                                                        index
                                                                    )}
                                                                </strong>
                                                            )}
                                                            {currentTabName !=
                                                                'PRELIMINARY METRICS' && (
                                                                <span
                                                                    style={{
                                                                        marginLeft: '20px',
                                                                        fontWeight: '700'
                                                                    }}>
                                                                    {data.validation}
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                    {currentTabName ===
                                                        'PARTS ROOM SAFETY & SECURITY' && (
                                                        <div className="data-upload-col">
                                                            {this.getSelectBox(index, data, 3)}
                                                        </div>
                                                    )}
                                                    {
                                                        currentTabName ===
                                                            'PARTS ROOM SAFETY & SECURITY' && (
                                                            <div className="data-upload-col">
                                                                {
                                                                    <div className="upload-file-control">
                                                                        <input
                                                                            type="file"
                                                                            id={data.ques_id}
                                                                            name={index}
                                                                        />
                                                                        <label
                                                                            className={`form-control ${
                                                                                !data.isApplicable &&
                                                                                task_status == 2 &&
                                                                                !!data.validation &&
                                                                                data.validation !=
                                                                                    'Pass' &&
                                                                                !data.url.length
                                                                                    ? 'upload-blank-field'
                                                                                    : !!isSubmitClicked &&
                                                                                      !!data.isApplicable &&
                                                                                      !data.url
                                                                                          .length
                                                                                    ? 'upload-blank-field'
                                                                                    : ''
                                                                            }`}
                                                                            htmlFor={data.ques_id}
                                                                            onClick={(event) =>
                                                                                this.openUploadModal(
                                                                                    event,
                                                                                    data,
                                                                                    index
                                                                                )
                                                                            }>
                                                                            {lang.LABLES.UploadFile}
                                                                        </label>
                                                                    </div>
                                                                }
                                                                <div className="group-upload-images margin-bottom-10">
                                                                    {data.url &&
                                                                        data.url.map(
                                                                            (imgObj, imgIndex) =>
                                                                                imgObj.img_path &&
                                                                                (!imgObj.isVideo ? (
                                                                                    <img
                                                                                        key={
                                                                                            imgIndex
                                                                                        }
                                                                                        src={
                                                                                            imgObj.img_path
                                                                                        }
                                                                                        alt="Images Icons"
                                                                                        className="upload-images"
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                task_status !=
                                                                                                3
                                                                                            ) {
                                                                                                this.showHideImageModel(
                                                                                                    index,
                                                                                                    imgObj,
                                                                                                    imgIndex
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <div
                                                                                        className="upload-images"
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                task_status !=
                                                                                                3
                                                                                            ) {
                                                                                                this.showHideImageModel(
                                                                                                    index,
                                                                                                    imgObj,
                                                                                                    imgIndex
                                                                                                );
                                                                                            }
                                                                                        }}>
                                                                                        <Thumbnail
                                                                                            key={
                                                                                                imgIndex
                                                                                            }
                                                                                            videoUrl={
                                                                                                imgObj.img_path
                                                                                            }
                                                                                            width={
                                                                                                38
                                                                                            }
                                                                                            height={
                                                                                                38
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ))
                                                                        )}
                                                                </div>
                                                            </div>
                                                        )
                                                        // <div className="data-upload-col">
                                                        //   {data.url && data.url.map((imgObj, imgIndex) => (
                                                        //     <img key={imgIndex} src={imgObj.img_path} alt="Images Icons" className="upload-images" onClick={() => { this.showHideImageModel(imgObj) }} />
                                                        //   ))}
                                                        // </div>
                                                    }
                                                    {showObservation(currentTabName) && (
                                                        <div className="data-upload-col">
                                                            <textarea
                                                                className={`form-control ${
                                                                    !data.isApplicable &&
                                                                    !!data.validation &&
                                                                    !!data.validation &&
                                                                    data.validation !== 'Pass' &&
                                                                    !data.observation
                                                                        ? 'blank-field'
                                                                        : !!isSubmitClicked &&
                                                                          !!data.isApplicable &&
                                                                          !data.observation
                                                                        ? 'blank-field'
                                                                        : ''
                                                                }`}
                                                                value={
                                                                    data.observation
                                                                        ? data.observation
                                                                        : ''
                                                                }
                                                                placeholder={
                                                                    lang.LABLES.Observation
                                                                }
                                                                id={index}
                                                                name="observation"
                                                                onChange={
                                                                    this.handleChangeWithLength
                                                                }
                                                                disabled={
                                                                    task_status == 3
                                                                }></textarea>
                                                        </div>
                                                    )}
                                                    {showComments ? (
                                                        <div className="data-upload-col">
                                                            <textarea
                                                                className={`form-control ${
                                                                    ((showObservation(
                                                                        currentTabName
                                                                    ) &&
                                                                        !data.isApplicable &&
                                                                        !!data.validation &&
                                                                        !!data.validation &&
                                                                        data.validation !==
                                                                            'Pass') ||
                                                                        (!!isSubmitClicked &&
                                                                            ((!!data.value &&
                                                                                data.value !=
                                                                                    '0') ||
                                                                                !data.isApplicable ||
                                                                                (data.validation !=
                                                                                    'Pass' &&
                                                                                    data.validation !=
                                                                                        'NA')) &&
                                                                            (data.pr_id === 3 ||
                                                                                data.pr_id === 4 ||
                                                                                (data.pr_id === 5 &&
                                                                                    taskInfo.vlm !=
                                                                                        'No') ||
                                                                                data.pr_id === 6 ||
                                                                                data.pr_id === 7) &&
                                                                            data.validation !=
                                                                                'Pass' &&
                                                                            data.validation !=
                                                                                'NA')) &&
                                                                    !data.auditor_comments
                                                                        ? 'blank-field'
                                                                        : ''
                                                                }`}
                                                                value={
                                                                    data.auditor_comments
                                                                        ? data.auditor_comments
                                                                        : ''
                                                                }
                                                                id={index}
                                                                name="auditor_comments"
                                                                onChange={
                                                                    this.handleChangeWithLength
                                                                }
                                                                disabled={
                                                                    task_status == 3 ||
                                                                    (showObservation(
                                                                        currentTabName
                                                                    ) &&
                                                                        !!data.isApplicable) ||
                                                                    (currentTabName ===
                                                                        'PRELIMINARY METRICS' &&
                                                                        !!data.isApplicable)
                                                                }
                                                                placeholder={
                                                                    lang.LABLES.Impact
                                                                }></textarea>
                                                        </div>
                                                    ) : (
                                                        <div className="data-upload-col"></div>
                                                    )}
                                                    {showComments ? (
                                                        <div className="data-upload-col">
                                                            <textarea
                                                                className={`form-control ${
                                                                    ((showObservation(
                                                                        currentTabName
                                                                    ) &&
                                                                        !data.isApplicable &&
                                                                        !!data.validation &&
                                                                        !!data.validation &&
                                                                        data.validation !==
                                                                            'Pass') ||
                                                                        (!!isSubmitClicked &&
                                                                            ((!!data.value &&
                                                                                data.value !=
                                                                                    '0') ||
                                                                                !data.isApplicable ||
                                                                                (data.validation !=
                                                                                    'Pass' &&
                                                                                    data.validation !=
                                                                                        'NA')) &&
                                                                            (data.pr_id === 3 ||
                                                                                data.pr_id === 4 ||
                                                                                (data.pr_id === 5 &&
                                                                                    taskInfo.vlm !=
                                                                                        'No') ||
                                                                                data.pr_id === 6 ||
                                                                                data.pr_id === 7) &&
                                                                            data.validation !=
                                                                                'Pass' &&
                                                                            data.validation !=
                                                                                'NA')) &&
                                                                    !data.reviewer_comments
                                                                        ? 'blank-field'
                                                                        : ''
                                                                }`}
                                                                value={
                                                                    data.reviewer_comments
                                                                        ? data.reviewer_comments
                                                                        : ''
                                                                }
                                                                id={index}
                                                                name="reviewer_comments"
                                                                onChange={
                                                                    this.handleChangeWithLength
                                                                }
                                                                disabled={
                                                                    task_status == 3 ||
                                                                    (showObservation(
                                                                        currentTabName
                                                                    ) &&
                                                                        !!data.isApplicable) ||
                                                                    (currentTabName ===
                                                                        'PRELIMINARY METRICS' &&
                                                                        !!data.isApplicable)
                                                                }
                                                                placeholder={
                                                                    lang.LABLES.Recommendation
                                                                }></textarea>
                                                        </div>
                                                    ) : (
                                                        <div className="data-upload-col"></div>
                                                    )}
                                                    {currentTabName ===
                                                        'PARTS ROOM SAFETY & SECURITY' && (
                                                        <div className="data-upload-col">
                                                            <textarea
                                                                className="form-control"
                                                                value={
                                                                    data.reviewer_remark
                                                                        ? data.reviewer_remark
                                                                        : ''
                                                                }
                                                                id={index}
                                                                name="reviewer_remark"
                                                                onChange={
                                                                    this.handleChangeWithLength
                                                                }
                                                                disabled={
                                                                    task_status == 1 ||
                                                                    task_status == 3
                                                                }
                                                                placeholder={
                                                                    lang.LABLES.ReviewerComment
                                                                }></textarea>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <input
                        type="checkbox"
                        name="confirmation"
                        value={confirmation}
                        onChange={this.handleCheckBox}
                    />
                    <label htmlFor="confirmation"> {lang.STRINGS.CONFIRMATIONMESSAGE}</label>
                </div>
                {QuesList.length > 0 && task_status != 3 && (
                    <div className="submition-outer">
                        <button className="btn-prmry" onClick={this.checkAssignBack}>
                            {lang.BUTTONS.AssignBack}
                        </button>
                        <button
                            className="btn-prmry"
                            onClick={() => {
                                this.submitAns(false);
                            }}>
                            {lang.BUTTONS.SavePreview}
                        </button>
                        <button className="btn-prmry" onClick={() => this.submitAns(true)}>
                            {lang.BUTTONS.Submit}
                        </button>
                    </div>
                )}
                {showImageModel && (
                    <ImagePrevew
                        isModalOpen={showImageModel}
                        imgObj={imgObj}
                        showDeleteButton={true}
                        onCancel={this.showHideImageModel}
                        langData={this.props?.langData}
                    />
                )}
                {showPreviewModel && (
                    <PreviewModal
                        isModalOpen={showPreviewModel}
                        data={QuesList}
                        taskInfo={taskInfo}
                        onClose={this.showHidePreviewModel}
                        langData={this.props?.langData}
                    />
                )}
                {openReasonModal && (
                    <ReasonModal
                        isModalOpen={openReasonModal}
                        assignBack={this.assignTaskBack}
                        onCancel={this.toggleReasonModal}
                        langData={this.props?.langData}
                    />
                )}
                {isQuantifiableModalOpen && (
                    <QuantifiableModal
                        isQuantifiableModalOpen={isQuantifiableModalOpen}
                        quesData={questionData}
                        index={index}
                        restrictNumber={this.restrictToNumber}
                        onClose={this.closeQuantifiableModal}
                        isApplicable={this.isApplicable}
                        handleChange={this.handleChange}
                        isInspected={this.isInspected}
                        selectedType={selectedType}
                        taskInfo={taskInfo}
                        langData={this.props?.langData}
                    />
                )}
                {isWriteBackModalOpen && (
                    <WriteBackModal
                        isWriteBackModalOpen={isWriteBackModalOpen}
                        quesData={questionData}
                        index={index}
                        restrictNumber={this.restrictToNumber}
                        onClose={this.closeWriteBackModal}
                        isApplicable={this.isApplicable}
                        handleChange={this.handleChange}
                        isInspected={this.isInspected}
                        selectedType={selectedType}
                        taskInfo={taskInfo}
                        langData={this.props?.langData}
                    />
                )}
                {isUploadFile && (
                    <ImageVideoSelectionModal
                        isModalOpen={isUploadFile}
                        onCancel={this.handleUploadModalClose}
                        handleFile={this.handleFile}
                        index={uploadObj.index}
                        data={uploadObj.data}
                        isSubmitClicked={isSubmitClicked}
                        langData={this.props?.langData}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        getWoData: (url, data, type, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithType(url, data, type, loaderMsg, obj, language)),
        uploadImage: (url, data, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithAction(url, data, null, null, loaderMsg, obj, language)),
        submitAns: (url, data, path, loaderMsg, obj, language = 'en') =>
            dispatch(getPostDataWithAction(url, data, path, null, loaderMsg, obj, language))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PRReviewerAnsComp);
