/**
 * @author Akshay Kumar Singh
 * @file ChangePassword Component
 * @flow
 */
import React, { Component } from 'react';
import { spinnerService } from '../../shared/services/spinner.service';
import * as CONSTANT from '../../shared/constants/data';
import { getData } from '../../shared/utils/storage.util';
import AlertModal from '../../shared/modals/AlertModal';
import CONFIG from '../../configuration';
import { CallApi } from '../../shared/services/api.service';
import { getRoleBasedRoute } from '../../shared/utils/helper.util';
import { connect } from 'react-redux';
import LANGUAGECONST from '../../shared/constants/language';

class changePasswordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: getData('loginData').userData.email,
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        };
    }

    /**
     * @description This function handle the change in input and sets the value in state.
     * @returns Changes in state values.
     */
    handleChange = (event) => {
        let { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    /**
     * @description This function used to change the password by calling the api.
     * @param event Event use to default prevent.
     */
    handleSubmit = (event) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        event.preventDefault();
        let string1 = this.state.newPassword;
        let string2 = this.state.confirmPassword;
        if (this.state.currentPassword && this.state.newPassword && this.state.confirmPassword) {
            if (!string1.localeCompare(string2)) {
                const changePasswordObj = {
                    current_password: this.state.currentPassword,
                    new_password: this.state.newPassword,
                    confirm_password: this.state.confirmPassword,
                    user_id: getData('loginData').userData.user_id
                };
                spinnerService.show('Oval', lang.STRINGS.CHANGINGPASSWORD);
                CallApi(
                    'POST',
                    CONFIG.changePassword,
                    {},
                    changePasswordObj,
                    '',
                    'changePassword',
                    this,
                    language
                );
            } else {
                AlertModal.showAlert(lang.STRINGS.NEWOLDPASSWORDNOTMATCH);
            }
        } else {
            AlertModal.showAlert(lang.STRINGS.ENTERALLFIELDSCORRECTLY);
        }
    };

    /**
     * @description This function handle the response of change password api.
     * @param response Response structure received from the change password api.
     */
    changePassword = (response) => {
        if (response.success) {
            AlertModal.showAlert(response.message);
            setTimeout(() => {
                AlertModal.hideAlert();
                this.toDashBoard();
            }, 2000);
        }
    };

    /**
     * @description This function used to redirect route basis of user role.
     */
    toDashBoard = () => {
        let route = getRoleBasedRoute();
        this.props.history.push(route);
    };

    render() {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <div className="login-background">
                <div className="login-main request-access-main">
                    <a href="!#" onClick={() => this.toDashBoard()} className="login-logo">
                        <img
                            src={require('../../assets/images/niagara-logo-color-2-x.png')}
                            alt="Login Logo"
                        />
                    </a>
                    <div className="login-form-container">
                        <form autoComplete="off" onSubmit={this.handleSubmit}>
                            <div className="login-column">
                                <div className="form-group ">
                                    <label className="login-icon email-icon">
                                        {lang.LABLES.Email}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        placeholder={lang.LABLES.Email}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="login-column">
                                <div className="form_group">
                                    <label className="login-icon">
                                        {lang.LABLES.CurrentPassword}
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="currentPassword"
                                        value={this.state.currentPassword}
                                        onChange={this.handleChange}
                                        placeholder={lang.LABLES.CurrentPassword}
                                    />
                                </div>
                            </div>
                            <div className="login-column">
                                <div className="form-group">
                                    <label className="login-icon">{lang.LABLES.NewPassword}</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="newPassword"
                                        value={this.state.newPassword}
                                        onChange={this.handleChange}
                                        placeholder={lang.LABLES.NewPassword}
                                    />
                                </div>
                            </div>
                            <div className="login-column">
                                <div className="form-group">
                                    <label className="login-icon">
                                        {lang.LABLES.ConfirmPassword}
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        onChange={this.handleChange}
                                        placeholder={lang.LABLES.ConfirmPassword}
                                    />
                                </div>
                            </div>
                            <div className="login-btn">
                                <button
                                    type="submit"
                                    name="Login"
                                    className="btn-prmry"
                                    value="Login">
                                    {lang.BUTTONS.Submit}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const data = state.data;
    return data;
};

export default connect(mapStateToProps)(changePasswordComponent);
