/**
 * @author Akshay Kumar Singh
 * @file API Service
 * @flow
 */
import Axios from 'axios';

import AlertModal from '../modals/AlertModal';
import { getData } from '../utils/storage.util';
import { logoutUnauthorizedUser } from '../utils/auth.util';
import { spinnerService } from './spinner.service';

/**
 * @description This functional component fetch data from API's and send response back to the calling function in their callbacks
 */
export const CallApi = async (
    method = 'GET',
    endpoint,
    customHeaders = {},
    payload,
    role,
    returnMethod,
    obj,
    language = 'en'
) => {
    let headers = {
        'Content-Type': 'application/json',
        authorization: `Bearer ${getData('token')}`,
        language: language === 'ENG' ? 'en' : language === 'ESP' ? 'es' : 'en'
    };
    let request;
    if (method === 'GET') {
        request = {
            params: payload
        };
    } else {
        request = payload;
    }
    return await Axios({
        method: method,
        url: endpoint,
        data: request,
        params: method === 'GET' ? request.params : {},
        headers: headers
    })
        .then(function (response) {
            spinnerService.hide('Oval');
            if (response.data.responseCode !== 401) {
                if (response.data.responseCode === 200) {
                    if (returnMethod === '') {
                        return response.data;
                    }
                    eval('obj.' + returnMethod + '(response.data)');
                } else if (
                    !(endpoint.includes('userTaskList') || endpoint.includes('userAuditListing'))
                ) {
                    AlertModal.showAlert(response.data.message);
                    return response.data;
                }
            } else {
                logoutUnauthorizedUser(obj, response.data.message);
            }
        })
        .catch(function (error) {
            spinnerService.hide('Oval');
            AlertModal.showAlert(error.message);
        });
};
