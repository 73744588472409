/**
 * @author Vaibhav Singh
 * @file Cost Evaluate Modal
 * @flow
 */
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import { getPostCostEvaluateValue } from '../../redux/actions/common_action';
import CONFIG from '../../configuration';
import SimpleReactValidator from 'simple-react-validator';
import LANGUAGECONST from '../constants/language';

/*
 * declare validation rules
 * */
const validationRules = {
    valid: 'required'
};

const costEvalChoices = [
    {
        name: 'Downtime',
        states: [
            { name: 'Line', cities: ['None'] },
            { name: 'Injection', cities: ['None'] },
            { name: 'Utilities', cities: ['None'] },
            { name: 'LGVs', cities: ['None'] },
            { name: 'Auxiliary', cities: ['None'] },
            { name: 'Facilities', cities: ['None'] }
        ]
    },
    {
        name: 'Safety',
        states: [
            { name: 'Machine', cities: ['None'] },
            { name: 'Personnel', cities: ['Near Miss', 'Time Lost', 'Work Comp.'] }
        ]
    },
    {
        name: 'Quality',
        states: [
            { name: 'Bottle defect', cities: ['None'] },
            { name: 'Sanitation', cities: ['None'] },
            { name: 'Preform defect', cities: ['None'] },
            { name: 'Customer Injury', cities: ['None'] }
        ]
    },
    {
        name: 'Throughput',
        states: [
            {
                name: 'Production limitation',
                cities: ['Injection cavities blocked', 'Mold blocked off']
            }
        ]
    },
    {
        name: 'Lifecycle',
        states: [
            {
                name: 'Get best estimate available in Niagara for lifecycle baseline',
                cities: ['Check warranty reports for reference']
            }
        ]
    },
    {
        name: 'Liquid Leaks',
        states: [
            {
                name: 'Water',
                cities: ['None']
            },
            {
                name: 'Glycol',
                cities: ['None']
            },
            {
                name: 'Oil',
                cities: ['None']
            },
            {
                name: 'Grease',
                cities: ['None']
            },
            {
                name: 'Resin',
                cities: ['None']
            }
        ]
    }
];

class CostEvaluateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            audit: costEvalChoices,
            sub1: [],
            sub2: [],
            selectedAudit: 'Choose Audit',
            selectedSub1: 'Choose Sub1',
            selectedSub2: 'Choose Sub2',
            valid: '',
            regexp: /^[0-9\b]+$/,
            unit: '',
            fields: []
        };
        this.validator = new SimpleReactValidator();
        this.changeAudit = this.changeAudit.bind(this);
        this.changeSub1 = this.changeSub1.bind(this);
    }

    componentDidMount() {
        const { fields } = this.state;
        let data = {
            task_id: this.props.taskInfo.task_id,
            ques_id: this.props.parentQuestionId
        };
        getPostCostEvaluateValue(CONFIG.getCostAnswers, data, 'GET').then((res) => {
            if (res) {
                const resData = res.response.map((item) => {
                    const subCat1 = this.state.audit.filter(
                        (cat1) => cat1.name == item.first_filter
                    );
                    const subCat2 = subCat1[0].states.filter(
                        (cat2) => cat2.name == item.second_filter
                    );
                    let newObj = {
                        audit: item.first_filter,
                        sub1: item.second_filter,
                        sub2: item.third_filter,
                        valid: item.validation,
                        sub1List: subCat1[0].states,
                        sub2List: subCat2[0].cities
                    };
                    return newObj;
                });
                this.setState({
                    fields: resData
                });
                this.setState({
                    selectedAudit: res.response[0].first_filter,
                    selectedSub1: res.response[0].second_filter,
                    selectedSub2: res.response[0].third_filter,
                    valid: res.response[0].validation,
                    unit: res.response[0].unit,
                    audit: costEvalChoices
                });
                this.setState({
                    sub1:
                        this.state.audit.find(
                            (cntry) => cntry.name === this.state.selectedAudit
                        ) !== undefined
                            ? this.state.audit.find(
                                  (cntry) => cntry.name === this.state.selectedAudit
                              ).states
                            : []
                });
                // console.log("we",this.state.selectedAudit);
                if (this.state.selectedAudit !== 'Choose Audit') {
                    const stats = this.state.audit.find(
                        (cntry) => cntry.name === this.state.selectedAudit
                    ).states;
                    this.setState({
                        sub2:
                            stats.find((stat) => stat.name === this.state.selectedSub1) !==
                            undefined
                                ? stats.find((stat) => stat.name === this.state.selectedSub1).cities
                                : []
                    });
                }
            } else {
                this.setState({
                    audit: costEvalChoices
                });
            }
        });
    }

    changeAudit(event, index) {
        const { fields, audit } = this.state;
        let auditArray = fields.slice();
        auditArray[index]['audit'] = event.target.value;
        const auditValue = audit.filter((item) => item.name == event.target.value);
        auditArray[index]['sub1List'] = auditValue[0].states;
        this.setState({ fields: auditArray });
    }

    changeSub1(event, index) {
        const { fields } = this.state;
        let sub1Array = fields.slice();
        sub1Array[index]['sub1'] = event.target.value;
        const sub1Value = fields[index].sub1List.filter((item) => item.name == event.target.value);
        sub1Array[index]['sub2List'] = sub1Value[0].cities;
        this.setState({ fields: sub1Array });
    }

    changeSub2 = (event, index) => {
        const { fields, audit } = this.state;
        let sub2Array = fields.slice();
        sub2Array[index]['sub2'] = event.target.value;
        this.setState({ fields: sub2Array });
    };

    handleInputChange = (event, index) => {
        const { fields, validate } = this.state;
        let value = fields.slice();
        value[index]['valid'] = event.target.value;
        const isValid = this.state.regexp.test(value[index]['valid']);

        if (value[index]['valid'] === '' || isValid) {
            this.setState({ fields: value });
        }
    };

    costEvaluate = (index) => {
        const { onCancel } = this.props;
        if (this.validator.allValid()) {
            var data = {
                audit_id: this.props.audit_id,
                task_id: this.props.taskInfo.task_id,
                ques_id: this.props.parentQuestionId,
                data: this.state.fields
            };
            getPostCostEvaluateValue(CONFIG.addCostAnswers, data, 'POST').then((response) => {
                // console.log("123",response)
                if (response.responseCode == '200') {
                    this.setState(() => {
                        onCancel();
                    });
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    handleAddNewField = () => {
        const { fields } = this.state;
        const objField = {
            index: fields.length,
            audit: '',
            sub1: '',
            sub1List: [],
            sub2: '',
            sub2List: [],
            valid: ''
        };
        this.setState({ fields: [...fields, objField] });
    };

    handleRemoveFields = () => {
        const { fields } = this.state;
        const lastIndex = fields.length - 1;
        if (fields.length >= 0) {
            const newFields = fields.slice(0, lastIndex);
            this.setState({
                fields: newFields,
                lastIndex: lastIndex
            });
        }
    };

    render() {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        const { fields } = this.state;

        return (
            <>
                <Modal open={this.props.isModalOpen} onClose={this.props.onCancel} center>
                    <div className="pop-up-bg" id="create-audit-popup">
                        <div className="rqst-accs-popup">
                            <div className="create-audit-outer">
                                <div className="top-cntnt">
                                    <h4> Cost Evaluate </h4>
                                    <a href="" onClick={this.props.onCancel}>
                                        <img
                                            src={require('../../assets/images/cross-icon.png')}
                                            alt="cross-icon"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div
                                className="form-cntnt"
                                style={{ overflowY: 'auto', maxHeight: '55vh' }}>
                                <form action="">
                                    {fields?.length > 0
                                        ? fields.map((item, index) => {
                                              return (
                                                  <div key={index}>
                                                      <form action="">
                                                          <div className="form-row">
                                                              <div className="column-three">
                                                                  <div className="form-group">
                                                                      <label htmlFor="">
                                                                          Audit Findings
                                                                      </label>
                                                                      <select
                                                                          name="audit"
                                                                          className="form-control"
                                                                          value={item.audit}
                                                                          onChange={(event) =>
                                                                              this.changeAudit(
                                                                                  event,
                                                                                  index
                                                                              )
                                                                          }>
                                                                          <option>
                                                                              Choose Audit
                                                                          </option>
                                                                          {this.state.audit.map(
                                                                              (e, key) => {
                                                                                  return (
                                                                                      <option
                                                                                          key={key}>
                                                                                          {e.name}
                                                                                      </option>
                                                                                  );
                                                                              }
                                                                          )}
                                                                      </select>
                                                                      {/* {this.validator.message('facility', this.state.facility, validationRules.facility)} */}
                                                                  </div>
                                                              </div>
                                                              <div className="column-three">
                                                                  <div className="form-group">
                                                                      <label htmlFor="">
                                                                          Sub-Category1
                                                                      </label>
                                                                      <select
                                                                          name="sub1"
                                                                          className="form-control"
                                                                          value={item.sub1}
                                                                          onChange={(event) =>
                                                                              this.changeSub1(
                                                                                  event,
                                                                                  index
                                                                              )
                                                                          }>
                                                                          <option>
                                                                              Choose Sub1
                                                                          </option>
                                                                          {this.state.fields
                                                                              .length &&
                                                                              this.state.fields[
                                                                                  index
                                                                              ]?.sub1List.map(
                                                                                  (e, key) => {
                                                                                      return (
                                                                                          <option
                                                                                              key={
                                                                                                  key
                                                                                              }>
                                                                                              {
                                                                                                  e.name
                                                                                              }
                                                                                          </option>
                                                                                      );
                                                                                  }
                                                                              )}
                                                                      </select>
                                                                  </div>
                                                              </div>
                                                              <div className="column-three">
                                                                  <div className="form-group">
                                                                      <label htmlFor="">
                                                                          Sub-Category2
                                                                      </label>
                                                                      <select
                                                                          name="sub2"
                                                                          className="form-control"
                                                                          value={item.sub2}
                                                                          onChange={(event) =>
                                                                              this.changeSub2(
                                                                                  event,
                                                                                  index
                                                                              )
                                                                          }>
                                                                          <option>
                                                                              Choose Sub2
                                                                          </option>
                                                                          {this.state.fields
                                                                              .length &&
                                                                              this.state.fields[
                                                                                  index
                                                                              ]?.sub2List.map(
                                                                                  (e, key) => {
                                                                                      return (
                                                                                          <option
                                                                                              key={
                                                                                                  key
                                                                                              }>
                                                                                              {e}
                                                                                          </option>
                                                                                      );
                                                                                  }
                                                                              )}
                                                                      </select>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <div className="form-row">
                                                              <div className="column-three">
                                                                  <div className="form-group ">
                                                                      <label htmlFor="">
                                                                          Value in {this.state.unit}
                                                                      </label>
                                                                      <input
                                                                          type="text"
                                                                          value={item.valid}
                                                                          name="valid"
                                                                          className="form-control"
                                                                          onChange={(event) =>
                                                                              this.handleInputChange(
                                                                                  event,
                                                                                  index
                                                                              )
                                                                          }
                                                                      />
                                                                      {this.validator.message(
                                                                          'valid',
                                                                          this.state.fields[index]
                                                                              ?.valid,
                                                                          validationRules.valid
                                                                      )}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </form>
                                                  </div>
                                              );
                                          })
                                        : ''}
                                </form>
                            </div>
                            <div className="form-button">
                                <a>
                                    <figure id="">
                                        <img
                                            src={require('../../assets/images/plus-button-icon.png')}
                                            alt="add-icon"
                                            id=""
                                            onClick={this.handleAddNewField}
                                        />{' '}
                                        &nbsp;
                                        {this.state.fields.length > 1 ? (
                                            <img
                                                src={require('../../assets/images/dustbin-icon@2x.png')}
                                                alt="cross-icon"
                                                id=""
                                                onClick={this.handleRemoveFields}
                                            />
                                        ) : (
                                            ''
                                        )}{' '}
                                        &nbsp;
                                    </figure>
                                </a>
                            </div>
                            <div className="crt-odi-btn-otr">
                                <button
                                    className="btn-prmry"
                                    type="button"
                                    onClick={this.costEvaluate}>
                                    Cost Evaluate
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default CostEvaluateModal;
