import actionTypes from '../../shared/constants/actionTypes';

export default function Reducer(state = {}, action) {
    switch (action.type) {
        case actionTypes.LOGINDATA:
            return { ...state, data: action.data };

        case actionTypes.PLANTS_LIST:
            return { ...state, data: action.data };

        case actionTypes.TASKDATA:
            return { ...state, data: action.data };

        case actionTypes.LANGUAGEDATA:
            return { ...state, langData: action.data };

        default:
            return state;
    }
}
