/**
 * @author Akshay Kumar Singh
 * @file Preview Modal
 * @flow
 */
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import { getData } from '../utils/storage.util';
import { formatDate } from '../utils/helper.util';
import LANGUAGECONST from '../constants/language';

/**
 * @description This component shows preview for auditor and reviewer of the line equipment & injection, facility, and utility.
 */
class PreviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemObj: {}
        };
    }

    render() {
        const { isModalOpen, previewData, onCancel, taskInfo } = this.props;
        const userRole = getData('loginData').userData.role_name;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <>
                <Modal open={isModalOpen} onClose={onCancel} center>
                    <div className="table-outer tabcontent active preview">
                        <div className="table-responsive">
                            <table className="gen-audt-mch-sts">
                                <thead>
                                    <tr>
                                        <th width="25%">{lang.LABLES.Description}</th>
                                        <th width="16%">
                                            {taskInfo.task_status == 1
                                                ? lang.LABLES.AuditorsInputs
                                                : lang.LABLES.Validation}
                                        </th>
                                        <th width="10%">{lang.LABLES.Image}</th>
                                        <th width="15%">{lang.LABLES.Observation}</th>
                                        <th width="16%">{lang.LABLES.Impact}</th>
                                        <th width="18%">{lang.LABLES.Recommendation}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {previewData.map((item) => {
                                        return !(
                                            item.long_heading === 'General Utilities' &&
                                            taskInfo.task_status == 1 &&
                                            item.auditor_flag == 3
                                        ) ? (
                                            <tr key={item.ques_id}>
                                                <td>
                                                    {item.long_ques}
                                                    {item.long_heading == 'General Audit' ? (
                                                        <div>
                                                            <br />
                                                            {lang.LABLES.From}:{' '}
                                                            {formatDate(item.start_date)} <br />{' '}
                                                            {lang.LABLES.To}:{' '}
                                                            {formatDate(item.end_date)}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                                {item.long_heading !== 'General Audit' &&
                                                taskInfo.task_status != 2 ? (
                                                    <td>
                                                        {item.validation &&
                                                            'Questionnaire answered'}
                                                    </td>
                                                ) : (
                                                    <td>{item.validation}</td>
                                                )}
                                                <td>
                                                    {item.url
                                                        ? item.url.map((imgData, imgIndex) => (
                                                              <img
                                                                  src={imgData.img_path}
                                                                  alt="Images Icons"
                                                                  key={imgIndex}
                                                                  className="upload-images"
                                                              />
                                                          ))
                                                        : null}
                                                </td>
                                                <td
                                                    style={{
                                                        wordWrap: 'break-word',
                                                        maxWidth: '50px'
                                                    }}>
                                                    {item.observation}
                                                </td>
                                                <td
                                                    style={{
                                                        wordWrap: 'break-word',
                                                        maxWidth: '50px'
                                                    }}>
                                                    {item.auditor_comments}
                                                </td>
                                                <td
                                                    style={{
                                                        wordWrap: 'break-word',
                                                        maxWidth: '50px'
                                                    }}>
                                                    {item.reviewer_comments}
                                                </td>
                                            </tr>
                                        ) : (
                                            ''
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br />
                    <button className="btn-prmry btn-rqst-accs" onClick={onCancel}>
                        {lang.BUTTONS.OK}
                    </button>
                </Modal>
            </>
        );
    }
}

export default PreviewModal;
