/**
 * @author Rehan Rizvi
 * @file Partsroom Ques/Ans Component
 * @flow
 */
import React from 'react';
import PRAuditorAnsComp from './PRAuditorAnsComp';
import PRReviewerAnsComp from './PRReviewerAnsComp';
import { getData } from '../../../../shared/utils/storage.util';

/**
 * @description This functional component pass task related data in its auditor and reviewer components
 */
const PRQuesAnsComponent = (props) => {
    let { userData } = getData('loginData');
    let { taskInfo, QuesList, countObj } = props;
    let { task_status } = taskInfo ? taskInfo : {};
    return task_status == 1 ||
        userData.role_name === 'auditor' ||
        userData.role_name === 'plant auditor' ? (
        <PRAuditorAnsComp
            taskInfo={taskInfo}
            QuesList={QuesList}
            countObj={countObj}
            getQuestionData={props.getQuestionData}
            langData={props.langData}
            history={props.history}
        />
    ) : (
        <PRReviewerAnsComp
            taskInfo={taskInfo}
            QuesList={QuesList}
            getQuestionData={props.getQuestionData}
            langData={props.langData}
            history={props.history}
        />
    );
};

export default PRQuesAnsComponent;
