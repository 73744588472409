import { LABLES } from '../../shared/constants/data';
import React, { useState, useEffect } from 'react';
import AuditWriteBackModal from '../../shared/modals/AuditWriteBackModal';
import DeleteAuditWriteBack from './DeleteAuditWriteBack';
import Axios from 'axios';
import { getData } from '../../shared/utils/storage.util';
import CONFIG from '../../configuration';
import { spinnerService } from '../../shared/services/spinner.service';
import LANGUAGECONSTANT from '../../shared/constants/language';
import { logoutUnauthorizedUser } from '../../shared/utils/auth.util';
import AuditWriteBackInfo from './AuditWriteBackInfo';
import AlertModal from '../../shared/modals/AlertModal';

const AuditWriteBack = ({
    quesData,
    lang,
    taskInfo,
    onClose,
    setApplicableData,
    applicableData,
    taskType
}) => {
    const [showForm, setShowForm] = useState(false);
    const [applicable, setApplicable] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [isDisable, setIsDisable] = useState(true);

    const [options, setOptions] = useState([
        {
            label: LABLES.QuestionEdit,
            id: 'option1',
            name: 'applicable',
            value: 'EDIT',
            type: 'radio'
        },
        // {
        //   label: LABLES.QuestionAddAppend,
        //   id: "option2",
        //   name: "applicable",
        //   value: "ADD",
        //   type: "radio",
        // },
        {
            label: LABLES.QuestionDelete,
            id: 'option3',
            name: 'applicable',
            value: 'DELETE',
            type: 'radio'
        },
        {
            label: LABLES.QuestionNotAccessed,
            id: 'option4',
            name: 'applicable',
            value: 'NOTACCESSED',
            type: 'radio'
        }
    ]);

    useEffect(() => {
        getWbQuesApi();
    }, []);

    const getWbQuesApi = async () => {
        let language = lang?.langData?.language || 'en';
        const config = {
            headers: {
                authorization: `Bearer ${getData('token')}`,
                language: language
            }
        };
        let data = {
            audit_id: taskInfo?.audit_id,
            task_id: taskInfo?.task_id,
            ques_id: quesData?.ques_id
        };
        spinnerService.show(
            'Oval',
            LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].STRINGS.UploadingImage
        );
        let response = await Axios.post(CONFIG.getWBQues, data, config);
        spinnerService.hide('Oval');
        if (response && response.data && response.data.responseCode == 200) {
            setApplicableData(response?.data?.response);
            if (response?.data?.response.length) {
                setIsDisable(false);
            }
        } else if (response && response.data && response.data.responseCode == 401) {
            logoutUnauthorizedUser(this, response.data.message);
        } else {
        }
    };

    /**
     *
     * @param {*} event this function is for approve and reject question from reviewer!
     */

    const handleApproveReject = (event, isApproved) => {
        event.preventDefault();
        switch (isApproved) {
            case true:
                postApproveReject(isApproved);
                break;
            case false:
                postApproveReject(isApproved);
                break;
            default:
                AlertModal.showAlert('Something Went Wrong !');
        }
    };

    /**
     *
     * @param {*} event this function is for hit api of approve and reject!
     */

    const postApproveReject = async (isApproved) => {
        let language = this.props?.langData?.language || 'en';
        const data = {
            audit_id: taskInfo?.audit_id,
            task_id: taskInfo?.task_id,
            ques_id: quesData?.ques_id,
            isApproved: isApproved
        };
        const config = {
            headers: {
                authorization: `Bearer ${getData('token')}`,
                language: language
            }
        };
        let response = await Axios.post(CONFIG.wbQuesIsApproved, data, config);
        if (response && response.data && response.data.responseCode == 200) {
            AlertModal.showAlert(response.data.message);
        } else if (response && response.data && response.data.responseCode == 401) {
            logoutUnauthorizedUser(this, response.data.message);
        } else {
            AlertModal.showAlert(response.data.message);
        }
    };

    const handleForm = (e) => {
        switch (e.target.value) {
            case 'EDIT': {
                setApplicable(e.target.value);
                setShowForm(true);
                break;
            }
            // case "ADD": {
            //   setApplicable(e.target.value);
            //   setShowForm(true);
            //   break;
            // }
            case 'DELETE': {
                setApplicable(e.target.value);
                setShowDeleteConfirm(true);
                break;
            }
            case 'NOTACCESSED': {
                setApplicable(e.target.value);
                setIsDisable(false);
                break;
            }
            default: {
                break;
            }
        }
    };

    /**
     * @description This function handles the close button alert modal.
     */
    const handleCloseModal = () => {
        AlertModal.showAlert(LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES.CLOSEALERT);
    };

    const showAuditorQuesInfo = () => {
        if (applicableData.length) {
            return (
                <AuditWriteBackInfo
                    lang={lang}
                    quesData={quesData}
                    taskType={taskType}
                    applicableData={applicableData}
                />
            );
        } else {
            return (
                <>
                    <label>{LABLES.AuditorNewPage}</label>
                    <ol>
                        {options.map((item) => {
                            return (
                                <li>
                                    <input
                                        type={item.type}
                                        name={item.name}
                                        id={item.id}
                                        value={item.value}
                                        onChange={(e) => handleForm(e)}
                                        checked={applicable == item.value}
                                    />
                                    <span> {item.label} </span>
                                </li>
                            );
                        })}
                    </ol>
                </>
            );
        }
    };

    const showAuditorQuesButtons = () => {
        if (applicableData.length > 0 && taskInfo.task_status == 1) {
            return (
                <button
                    className="btn-prmry"
                    type="button"
                    onClick={() => {
                        setApplicable('EDIT');
                        setShowForm(true);
                    }}>
                    Edit
                </button>
            );
        } else if (applicableData.length > 0 && taskInfo.task_status == 2) {
            if (
                taskInfo?.task_name == 'Line Equipment and Injection' ||
                'Facilities' ||
                'Utilities'
            ) {
                return (
                    <>
                        <button
                            className="btn-prmry"
                            type="button"
                            onClick={() => {
                                setApplicable('EDIT');
                                setShowForm(true);
                            }}>
                            Edit
                        </button>
                        &nbsp;&nbsp;
                        <button
                            className="btn-prmry"
                            type="button"
                            onClick={(event) => {
                                handleApproveReject(event, true);
                            }}>
                            Approve
                        </button>
                        &nbsp;&nbsp;
                        <button
                            className="btn-prmry"
                            type="button"
                            onClick={(event) => {
                                handleApproveReject(event, false);
                            }}>
                            Reject
                        </button>
                    </>
                );
            }
        }
    };

    return (
        <>
            {showForm && (
                <AuditWriteBackModal
                    showForm={showForm}
                    setShowForm={setShowForm}
                    setApplicable={setApplicable}
                    applicable={applicable}
                    lang={lang}
                    quesData={quesData}
                    taskInfo={taskInfo}
                    getWbQuesApi={getWbQuesApi}
                />
            )}

            <DeleteAuditWriteBack
                showDeleteConfirm={showDeleteConfirm}
                setShowDeleteConfirm={setShowDeleteConfirm}
                setApplicable={setApplicable}
                lang={lang}
                quesData={quesData}
                taskInfo={taskInfo}
                applicable={applicable}
                setShowForm={setShowForm}
                getWbQuesApi={getWbQuesApi}
            />
            {/* showing auditor ques options */}
            {showAuditorQuesInfo()}
            {/* ================================================================= */}
            <br />
            <br />
            <br />
            <br />
            <div className="crt-odi-btn-otr">
                {isDisable ? (
                    <button className="btn-prmry" type="button" onClick={handleCloseModal}>
                        Close
                    </button>
                ) : (
                    <button className="btn-prmry" type="button" onClick={() => onClose()}>
                        Close
                    </button>
                )}
                &nbsp;&nbsp;
                {showAuditorQuesButtons()}
            </div>
        </>
    );
};

export default AuditWriteBack;
