/**
 * @author Rehan Rizvi
 * @file Write Back Review Modal
 * @flow
 */
import React from 'react';
import Modal from 'react-responsive-modal';
import { getWriteBackValue } from '../../redux/actions/common_action';
import CONFIG from '../../configuration';
import AlertModal from './AlertModal';

/**
 * This functional component opens the confirmational modal.
 */
export const WriteBackReviewModal = ({
    isModalOpen,
    onClose,
    showCancel,
    cancelAction,
    msg,
    data,
    showOk
}) => {
    const okAction = () => {
        getWriteBackValue(CONFIG.addWBQues, data, 'POST').then((response) => {
            if (response.responseCode == '200') {
                cancelAction();
            } else {
                AlertModal.showAlert(response.message);
            }
        });
    };

    return (
        <Modal open={isModalOpen} onClose={onClose} center>
            <div className="rqst-accs-popup">
                <div className="popup-cntnt popuptext">
                    <p>{msg}</p>
                    <br />
                </div>
                <div className="popup-multi-btn">
                    {showCancel && (
                        <button
                            className="btn-prmry btn-cancel btn-rqst-accs"
                            onClick={cancelAction}>
                            Reject
                        </button>
                    )}
                    {showOk && (
                        <button className="btn-prmry btn-rqst-accs" onClick={okAction}>
                            Approve
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};
