import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';

import rootReducer from './reducers';
import { createBrowserHistory } from 'history';

/*creating history object */
export const history = createBrowserHistory();

const createStoreMiddleware = applyMiddleware(ReduxThunk)(createStore);
export const store = createStoreMiddleware(rootReducer(history));
