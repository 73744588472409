/**
 * @author Akshay Kumar Singh
 * @file Crypto Util
 * @flow
 */
import * as CryptoJS from 'crypto-js';

const specialCharters = {
    '+': 'xMl3Jk',
    '/': 'Por21Ld',
    '=': 'Ml32'
};

/**
 * @description This function used to encrypt the data.
 * @param date Data that will be encrypt.
 */
export const encryptData = (data) => {
    const key1 = 'NiaGra!A@A!NiaGra!A@A!NiaGra!A@A';
    const key2 = '0110110000111010';
    var key = CryptoJS.enc.Utf8.parse(key1);
    var iv = CryptoJS.enc.Utf8.parse(key2);
    var encrypted = CryptoJS.AES.encrypt(data, key, {
        keySize: 32,
        iv: iv,
        mode: CryptoJS.mode.CBC
    }).toString();
    return removeSpecialCharacters(encrypted);
};

/**
 * @description This function used to decrypt the data.
 * @param encryptedData Encrypted data that will be decrypt.
 */
export const decryptData = (encryptedData) => {
    var key = CryptoJS.enc.Utf8.parse('NiaGra!A@A!NiaGra!A@A!NiaGra!A@A');
    var iv = CryptoJS.enc.Utf8.parse('0110110000111010');
    var decrypted = CryptoJS.AES.decrypt(putBackSpecialCharacters(encryptedData), key, {
        keySize: 32,
        iv: iv,
        mode: CryptoJS.mode.CBC
    }).toString(CryptoJS.enc.Utf8);
    return decrypted;
};

/**
 * @description remove special characters from string ( + ,/ , = )
 */
const removeSpecialCharacters = (data) => {
    return data
        .replace(/[+]/g, specialCharters['+'])
        .replace(/[/]/g, specialCharters['/'])
        .replace(/[=]/g, specialCharters['=']);
};

/**
 * @description put back special characters in cipher text
 */
const putBackSpecialCharacters = (data) => {
    return data
        .replace(/xMl3Jk/g, '+')
        .replace(/Por21Ld/g, '/')
        .replace(/Ml32/g, '=');
};
