import JSZip from 'jszip';
import { CallApi } from '../../shared/services/api.service';
import CONFIG from '../../configuration';
import { saveAs } from 'file-saver';
import Axios from 'axios';

/**
 * @description This is used to download all images in a single zip file.
 * @param auditName name of audit (to be used to name the final zip file)
 * @param images array of objects that include the image name and uri
 */
export const downloadAuditImages = async (auditName, images) => {
    const zip = new JSZip();
    const download = (image) => {
        //download single file as blob and add it to zip archive
        return Axios.get(image.uri, { responseType: 'blob' }).then((resp) => {
            zip.file(
                image.name + image.uri.substring(image.uri.lastIndexOf('.'), image.uri.length),
                resp.data
            );
        });
    };

    //call this function to download all files as ZIP archive
    const downloadAll = async (images) => {
        const arrOfFiles = images.map((image) => download(image)); //create array of promises
        Promise.all(arrOfFiles)
            .then(() => {
                //when all promises resolved - save zip file
                zip.generateAsync({ type: 'blob' }).then(function (blob) {
                    saveAs(blob, auditName + '_images.zip');
                });
            })
            .catch((err) => {
                console.log(err);
            });
        return 'asdf';
    };

    return await downloadAll(images);
};

/**
 * @description This is used to grab all images/videos for an audit.
 * @param auditId id of the audit
 * @param language language preference of the user
 */
export const getAuditImages = async (auditId, language) => {
    const handleRes = (res) => {
        if (res.success) {
            return { success: true, data: res.response.images, message: res.message };
        }
        return { success: false, data: [], message: res.message };
    };

    return handleRes(
        await CallApi('GET', CONFIG.getAuditImages, {}, { auditId }, '', '', this, language)
    );
};

/**
 * @description This is used to grab all images/videos for an audit and then download them as a zip file.
 * @param auditId id of the audit
 * @param language language preference of the user
 * @param auditName name of selected audit
 */
export const getAndDownloadAuditImages = async (auditId, language, auditName) => {
    const getImages = await getAuditImages(auditId, language);
    if (!getImages.success) {
        return getImages;
    }

    downloadAuditImages(
        auditName,
        getImages.data.map((image) => {
            return {
                name: image.substring(image.lastIndexOf('.com'), image.lastIndexOf('.')),
                uri: image
            };
        })
    );

    return getImages;
};
