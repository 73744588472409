import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import ROUTES from '../shared/constants/routes';

import { isAuthenticated } from '../shared/utils/auth.util';

export const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <div className={'App'}>
                    <Component {...props} />
                </div>
            ) : (
                <Redirect to={{ pathname: ROUTES.LANDING, state: { from: props.location } }} />
            )
        }
    />
);

export const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            !isAuthenticated() ? (
                <div className={'App login-background'}>
                    <Component {...props} />
                </div>
            ) : (
                <Redirect to={ROUTES.DASHBOARD} />
            )
        }
    />
);
