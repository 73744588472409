/**
 * @author Rehan Rizvi
 * @file ImagePreview Modal
 * @flow
 */
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import LANGUAGECONST from '../constants/language';

/**
 * @description This functional component shows the image preview with delete and ok button.
 */
class ImagePrevew extends Component {
    render() {
        const { isModalOpen, showDeleteButton, imgObj, onCancel, deleteImage } = this.props;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <Modal open={isModalOpen} onClose={onCancel} center>
                <div className="img-preview">
                    {imgObj && imgObj.img_path ? (
                        !!imgObj.isVideo ? (
                            <video width="420" height="440" controls autoPlay>
                                <source src={imgObj.img_path} type="video/mp4" />
                            </video>
                        ) : (
                            <img src={imgObj.img_path} alt="Images Icons" />
                        )
                    ) : null}
                </div>
                <div className="btn-container">
                    <button className="btn-prmry btn-rqst-accs" onClick={onCancel}>
                        {lang.BUTTONS.OK}
                    </button>
                    {showDeleteButton && (
                        <button className="btn-prmry btn-rqst-accs" onClick={deleteImage}>
                            {lang.BUTTONS.Delete}
                        </button>
                    )}
                </div>
            </Modal>
        );
    }
}

export default ImagePrevew;
