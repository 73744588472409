import React from 'react';
// import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import LANGUAGECONST from '../constants/language';

function ImageVideoSelectionModal(props) {
    let lang = LANGUAGECONST[props?.langData?.langType || 'ENG'];
    return (
        <Modal
            open={props.isModalOpen}
            onClose={props.onCancel}
            center
            classNames={{ modal: 'upload-media-modal', closeIcon: 'cross-icon' }}>
            <div className="modal-header">
                <label>{lang.LABLES.UPLOADMEDIA}</label>
            </div>
            <div className="upload-file-control upload-section">
                <div className="image-section">
                    <input
                        type="file"
                        id={props.data.ques_id + 'image'}
                        data-id={props.data.ques_id}
                        name={props.index}
                        accept="image/*"
                        onChange={(event) => props.handleFile(event, props.data, 'image')}
                    />
                    <label
                        className={`form-control ${
                            !props.data.isApplicable &&
                            !!props.isSubmitClicked &&
                            !props.data.url.length
                                ? 'upload-blank-field'
                                : !!props.data.isApplicable &&
                                  !!props.isSubmitClicked &&
                                  props.data.url.length == 0
                                ? 'upload-blank-field'
                                : ''
                        }`}
                        htmlFor={props.data.ques_id + 'image'}>
                        {lang.LABLES.UploadImage}
                    </label>
                </div>
                <div className="video-section">
                    <input
                        type="file"
                        id={props.data.ques_id + 'video'}
                        data-id={props.data.ques_id}
                        name={props.index}
                        accept="video/*"
                        onChange={(event) => props.handleFile(event, props.data, 'video')}
                    />
                    <label
                        className={`form-control ${
                            !props.data.isApplicable &&
                            !!props.isSubmitClicked &&
                            !props.data.url.length
                                ? 'upload-blank-field'
                                : !!props.data.isApplicable &&
                                  !!props.isSubmitClicked &&
                                  props.data.url.length == 0
                                ? 'upload-blank-field'
                                : ''
                        }`}
                        htmlFor={props.data.ques_id + 'video'}>
                        {lang.LABLES.UPLOADVIDEO}
                    </label>
                </div>
            </div>
        </Modal>
    );
}

export default ImageVideoSelectionModal;
