/**
 * @author Akshay Kumar Singh
 * @file routes
 * @flow
 */
import ROUTES from '../shared/constants/routes';
import LoginComponent from '../components/LoginComponent';
import AddTaskComponent from '../components/AddTaskComponent';
import DashboardComponent from '../components/DashboardComponent';
import AuditDetailsComponent from '../components/AuditDetailsComponent';
import AuditSummaryComponent from '../components/AuditSummaryComponent';
import ChangePasswordComponent from '../components/ChangePasswordComponent';
import UpdateTaskComp from '../components/UpdateTask/UpdateTask';
import EditAuditTaskComp from '../components/Common/EditAuditTask';
import MyTaskComp from '../components/MyTask/MyTaskComp';
import MyTaskCompForPlant from '../components/MyTask/MyTaskCompForPlant';

/*
 * define routes
 * */
const routes = [
    {
        path: ROUTES.LANDING,
        exact: true,
        isPrivateRoute: false,
        component: LoginComponent
    },
    {
        path: ROUTES.CHANGEPASSWORD,
        exact: true,
        isPrivateRoute: true,
        component: ChangePasswordComponent
    },
    {
        path: ROUTES.DASHBOARD,
        exact: true,
        isPrivateRoute: true,
        component: DashboardComponent
    },
    {
        path: ROUTES.AUDITDETAILS,
        exact: true,
        isPrivateRoute: true,
        component: AuditDetailsComponent
    },
    {
        path: ROUTES.AUDITSUMMARY,
        exact: true,
        isPrivateRoute: true,
        component: AuditSummaryComponent
    },
    {
        path: ROUTES.MYTASK,
        exact: true,
        isPrivateRoute: true,
        component: AuditSummaryComponent
    },
    {
        path: ROUTES.TASKREVIEW,
        exact: true,
        isPrivateRoute: true,
        component: AddTaskComponent
    },
    {
        path: ROUTES.EDITAUDITTASK,
        exact: true,
        isPrivateRoute: true,
        component: AddTaskComponent
    },
    {
        path: ROUTES.DRAFTEDAUDIT,
        exact: true,
        isPrivateRoute: true,
        component: AddTaskComponent
    },
    {
        path: ROUTES.CREATEDAUDIT,
        exact: true,
        isPrivateRoute: true,
        component: AddTaskComponent
    },
    {
        path: ROUTES.CREATEDAUDITDETAILS,
        exact: true,
        isPrivateRoute: true,
        component: AuditDetailsComponent
    },
    {
        path: ROUTES.EDITTASK,
        exact: true,
        isPrivateRoute: true,
        component: AddTaskComponent
    },
    {
        path: ROUTES.MYTASK_UPDATETASK,
        exact: true,
        isPrivateRoute: true,
        component: UpdateTaskComp
    },

    /* Auditor Route */
    {
        path: ROUTES.AUDITOR_MYTASK,
        exact: true,
        isPrivateRoute: true,
        component: AuditSummaryComponent
    },
    {
        path: ROUTES.AUDITOR_MYTASK_UPDATETASK,
        exact: true,
        isPrivateRoute: true,
        component: UpdateTaskComp
    },

    /** Reviewer Route */
    {
        path: ROUTES.REVIEWER_MYTASK,
        exact: true,
        isPrivateRoute: true,
        component: AuditSummaryComponent
    },
    {
        path: ROUTES.REVIEWER_MYTASK_UPDATETASK,
        exact: true,
        isPrivateRoute: true,
        component: UpdateTaskComp
    },

    /** Plant Manager Route */
    {
        path: ROUTES.PLM_MYTASK,
        exact: true,
        isPrivateRoute: true,
        component: MyTaskComp
    },
    {
        path: ROUTES.PLM_MYTASK_UPDATETASK,
        exact: true,
        isPrivateRoute: true,
        component: UpdateTaskComp
    },
    {
        path: ROUTES.PLM_EDITTASK,
        exact: true,
        isPrivateRoute: true,
        component: EditAuditTaskComp
    },

    /** Plant Auditor Route */
    {
        path: ROUTES.PLA_MYTASK,
        exact: true,
        isPrivateRoute: true,
        component: MyTaskCompForPlant
    },
    {
        path: ROUTES.PLA_MYTASK_UPDATETASK,
        exact: true,
        isPrivateRoute: true,
        component: UpdateTaskComp
    },

    /** Plant Reviewer Route */
    {
        path: ROUTES.PLR_MYTASK,
        exact: true,
        isPrivateRoute: true,
        component: MyTaskCompForPlant
    },
    {
        path: ROUTES.PLR_MYTASK_UPDATETASK,
        exact: true,
        isPrivateRoute: true,
        component: UpdateTaskComp
    }
];

export default routes;
