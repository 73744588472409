/**
 * @author Akshay Kumar Singh
 * @file Reason Modal
 * @flow
 */
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import AlertModal from './AlertModal';
import LANGUAGECONST from '../constants/language';

class ReasonModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ''
        };
    }

    /**
     * @description This function set values of variables.
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    /**
     * @description This function submit the reason to assign task to auditor from reviewer
     */
    handleProceed = () => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        const { assignBack } = this.props;
        const { message } = this.state;
        if (!!message) {
            assignBack(message);
        } else {
            AlertModal.showAlert(lang.STRINGS.REASONMESSAGETOPROCEED);
        }
    };

    render() {
        const { isModalOpen, assignBack, onCancel } = this.props;
        const { message } = this.state;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <Modal open={isModalOpen} onClose={onCancel} center>
                <div className="popup-cntnt popuptext">
                    <p>
                        <b>{lang.STRINGS.ReasonMessage}</b>
                    </p>
                    <br />
                    <textarea
                        rows="8"
                        cols="60"
                        style={{ resize: 'none' }}
                        name="message"
                        onChange={this.handleChange}
                        placeholder={`${lang.STRINGS.ENTERHERE}...`}></textarea>
                </div>
                <div className="btn-container">
                    <button className="btn-prmry btn-rqst-accs" onClick={onCancel}>
                        {lang.BUTTONS.Cancel}
                    </button>
                    <button
                        className="btn-prmry btn-rqst-accs"
                        onClick={() => this.handleProceed()}>
                        {lang.BUTTONS.Proceed}
                    </button>
                </div>
            </Modal>
        );
    }
}

export default ReasonModal;
