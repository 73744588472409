import { fromJS } from 'immutable';

import {
    USERS_LIST,
    TASK_DATA_ARR,
    WO_QUES_DATA,
    PR_HEADING_DATA,
    PR_DATA
} from '../actions/common_action_types';

const INITIAL_STATE = fromJS({
    usersList: [],
    taskDataArr: [],
    woQuesData: [],
    prHeadingData: [],
    prData: []
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USERS_LIST: {
            return state.set('usersList', action.payload.result);
        }

        case TASK_DATA_ARR: {
            return state.set('taskDataArr', action.payload.result);
        }

        case WO_QUES_DATA: {
            return state.set('woQuesData', action.payload);
        }

        case PR_HEADING_DATA: {
            return state.set('prHeadingData', action.payload);
        }

        case PR_DATA: {
            return state.set('prData', action.payload);
        }
    }
    return state;
}
