import React, { useState, useEffect } from 'react';
import LANGUAGECONSTANT from '../../shared/constants/language';

const AuditWriteBackInfo = ({ quesData, lang, taskType, applicableData }) => {
    if (taskType == 2) {
        return (
            <>
                <div className="column-left">
                    <div className="row">
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .OPSEQUENCE
                                    }
                                </b>
                                : {quesData?.operation_numb}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .DEPARTMENT
                                    }
                                </b>
                                : {quesData.long_heading}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .SHORTDESCRIPTION
                                    }
                                </b>
                                : {quesData?.short_ques}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .LONGDESCRIPTION
                                    }
                                </b>
                                : {quesData?.long_ques}
                            </span>
                        </div>
                    </div>
                </div>
                <br />
                <div className="column-right">
                    <div className="row">
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .OPSEQUENCE
                                    }
                                </b>
                                : {applicableData[0]?.op_seq}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .DEPARTMENT
                                    }
                                </b>
                                : {applicableData[0]?.area}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .SHORTDESCRIPTION
                                    }
                                </b>
                                : {applicableData[0]?.description}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>
                                    {
                                        LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                            .LONGDESCRIPTION
                                    }
                                </b>
                                : {applicableData[0]?.long_description}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="row">
                    <div>
                        <span>
                            <b>
                                {
                                    LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                        .SCENARIO
                                }
                            </b>
                            : {applicableData[0]?.scenario}
                        </span>
                    </div>
                    <div>
                        <span>
                            <b>
                                {
                                    LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                        .OPSEQUENCE
                                }
                            </b>
                            : {applicableData[0]?.op_seq}
                        </span>
                    </div>
                    <div>
                        <span>
                            <b>
                                {
                                    LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                        .DEPARTMENT
                                }
                            </b>
                            : {applicableData[0]?.area}
                        </span>
                    </div>
                    <div>
                        <span>
                            <b>
                                {
                                    LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                        .SHORTDESCRIPTION
                                }
                            </b>
                            : {applicableData[0]?.description}
                        </span>
                    </div>
                    <div>
                        <span>
                            <b>
                                {
                                    LANGUAGECONSTANT[lang?.langData?.langType || 'ENG'].LABLES
                                        .LONGDESCRIPTION
                                }
                            </b>
                            : {applicableData[0]?.long_description}
                        </span>
                    </div>
                </div>
            </>
        );
    }
};

export default AuditWriteBackInfo;
