import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import AlertModal from '../shared/modals/AlertModal';
import { SpinnerComponent as Spinner } from '../shared/spinner/spinnerComponent';
import { ProtectedRoute, PublicRoute } from '../routes/customRoutes';
import routes from './index';
import { history } from '../redux/configureStore';
import { Redirect } from 'react-router';
import { getRoleBasedRoute } from '../shared/utils/helper.util';

class RouteComponent extends Component {
    constructor(props) {
        super(props);
        this.allRoutesInfo = [];
    }

    // prepare routes
    getRoutes = (route, i) => {
        const { isPrivateRoute, ...restRoute } = route;
        if (route.isPrivateRoute) {
            return <ProtectedRoute key={i} {...restRoute} />;
        } else {
            return <PublicRoute key={i} {...restRoute} />;
        }
    };

    // Default route
    defaultRoute = () => {
        let route = getRoleBasedRoute();
        return <Redirect to={route} />;
    };

    render() {
        return (
            <ConnectedRouter history={history}>
                <AlertModal />
                <Spinner name="Oval" />
                <div className="customContainer">
                    <Switch>
                        {/*iterating routes */}
                        {routes.map((route, i) => this.getRoutes(route, i))}
                        {this.defaultRoute()}
                    </Switch>
                </div>
            </ConnectedRouter>
        );
    }
}

export default RouteComponent;
