import React, { useEffect, useState } from 'react';

const AuditForm = ({ handleProceed, data, applicable, onCancel, lang, taskInfo }) => {
    const handleOpSeq = () => {
        if (taskInfo?.task_name == 'Utilities') {
            if (data?.isLocal) {
                return data?.utility_ques_id;
            } else {
                return data?.operation_numb;
            }
        } else if (taskInfo?.task_name == 'Facilities') {
            if (data?.isLocal) {
                return data?.facility_ques_id;
            } else {
                return data?.operation_numb;
            }
        } else if (taskInfo?.task_name == 'Line Equipment and Injection') {
            if (data?.isLocal) {
                return data?.pm_mh_ques_id;
            } else {
                return data?.operation_numb;
            }
        }
    };

    return (
        <form onSubmit={(e) => handleProceed(e)}>
            <div className="popup-cntnt popuptext">
                <center>
                    <b>{lang.STRINGS.ADDQUESTIONMANDATORYFIELD}</b>
                </center>
                <br />

                <div className="data-card-row">
                    <label>{lang.LABLES.OPSEQUENCE}</label>
                    <input
                        className="form-control"
                        type="text"
                        name="op_sequence"
                        defaultValue={handleOpSeq()}
                        // disabled
                        // onChange={this.handleChange}
                    />
                </div>
                <div className="data-card-row">
                    <label>{lang.LABLES.AREA}</label>
                    <input
                        className="form-control"
                        type="text"
                        name="department"
                        defaultValue={applicable == 'EDIT' ? data?.long_heading : null}
                        // onChange={this.handleChange}
                    />
                </div>
                <div className="data-card-row">
                    <label>{lang.LABLES.SHORTDESCRIPTION}</label>
                    <input
                        className="form-control"
                        type="text"
                        name="short_desc"
                        defaultValue={applicable == 'EDIT' ? data?.short_ques : null}
                        // onChange={this.handleChange}
                    />
                </div>
                <div className="data-card-row">
                    <label>{lang.LABLES.LONGDESCRIPTION}</label>
                    <input
                        className="form-control"
                        type="text"
                        name="long_desc"
                        defaultValue={applicable == 'EDIT' ? data?.long_ques : null}
                        // onChange={this.handleChange}
                    />
                </div>
                <div>
                    {data && data?.isQuant === 'true' && (
                        <>
                            <div className="data-card-row">
                                <label>{lang.LABLES.MIN}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="min"
                                    defaultValue={applicable == 'EDIT' ? data?.min : null}
                                    disabled={data?.isQuant == 'false'}
                                />
                            </div>
                            <div className="data-card-row">
                                <label>{lang.LABLES.MAX}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="max"
                                    defaultValue={applicable == 'EDIT' ? data?.max : null}
                                    disabled={data?.isQuant == 'false'}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="btn-container">
                <button
                    className="btn-prmry btn-rqst-accs"
                    onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                    }}>
                    {lang.BUTTONS.Cancel}
                </button>

                <button className="btn-prmry btn-rqst-accs" type={'submit'}>
                    {lang.BUTTONS.Proceed}
                </button>
            </div>
        </form>
    );
};

export default AuditForm;
