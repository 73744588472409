/**
 * @author Akshay Kumar Singh
 * @file Storage Util
 * @flow
 */
import * as cryptoUtils from './crypto.util';

/**
 * @description This is used to store data in localStorage
 * @param key key name on which data will be store
 * @param value value that will be store
 */
export const setData = (key, value) => {
    let userData = localStorage.getItem('userData');
    if (userData) {
        userData = cryptoUtils.decryptData(userData);
        userData = JSON.parse(userData);
        userData[key] = value;
    } else {
        userData = { [key]: value };
    }
    userData = JSON.stringify(userData);
    userData = cryptoUtils.encryptData(userData);
    localStorage.setItem('userData', userData);
};

/**
 * @description This is used to get data in localStorage
 * @param key key name on which data will be retrieve
 */
export const getData = (key) => {
    let userData = localStorage.getItem('userData');
    if (userData) {
        userData = JSON.parse(cryptoUtils.decryptData(userData));
        if (userData[key]) {
            return userData[key];
        }
    }
};

/**
 * @description This is used to delete data from localStorage based to key.
 * @param key key name on which data will be remove
 */
export const removeData = (key) => {
    let userData = localStorage.getItem('userData');
    if (userData) {
        userData = JSON.parse(cryptoUtils.decryptData(userData));
        if (userData[key]) {
            delete userData[key];
            userData = cryptoUtils.encryptData(JSON.stringify(userData));
            localStorage.setItem('userData', userData);
        }
    }
};
